import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  clearSuccessListReceivables,
  getAutomatedCollections,
  getBankAccounts,
  getRtpDetails,
  getUser,
  hideLoader,
  markFailureReceivable,
  markSuccessReceivable,
  removeFailureReceivable,
  showLoader,
  useAppDispatch,
} from "@APP/redux";
import { SCREEN_PATHS } from "@APP/navigation";
import {
  CustomerReceivablesDetailsTable,
  FooterActionsButtons,
  Page,
  ScreenHeader,
  ScreenHeaderSubtitle,
} from "@APP/components";
import { createRTP } from "@APP/services/RTP";
import {
  convertReceivablesToDeliveryData,
  getRTPFailureReasonMessage,
  STATUS_SETTLED,
} from "@APP/utils";
import { Receivable } from "@APP/types";

const NonCreatedRTPsList = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const user = useSelector(getUser);
  const bankAccounts = useSelector(getBankAccounts);
  const rtpDetails = useSelector(getRtpDetails);
  const { receivableFailure, receivablesSuccess } = useSelector(getAutomatedCollections);

  const paymentAccount = bankAccounts?.find(
    (bank) => bank.account.identification === rtpDetails.deliveryDetails?.account,
  );

  useEffect(() => {
    if (receivablesSuccess.length) dispatch(clearSuccessListReceivables());
  }, []);

  const handleContinue = async () => {
    dispatch(showLoader());

    const createdRTPs = await Promise.allSettled(
      (receivableFailure as Receivable[]).map(
        async (receivable) => await createRTP(rtpDetails, user, receivable, paymentAccount),
      ),
    );

    for (const [index, rtp] of createdRTPs.entries()) {
      if (rtp.status === STATUS_SETTLED.FULFILLED) {
        dispatch(markSuccessReceivable(receivableFailure[index]));
        dispatch(removeFailureReceivable(receivableFailure[index].entityDetails.externalId));
      }

      if (rtp.status === STATUS_SETTLED.REJECTED) {
        const invalidFields = rtp.reason?.response?.data?.invalidFields;

        if (invalidFields) {
          const failureReason = getRTPFailureReasonMessage(invalidFields);
          dispatch(markFailureReceivable({ ...receivableFailure[index], failureReason }));
        } else {
          dispatch(
            markFailureReceivable({ ...receivableFailure[index], failureReason: undefined }),
          );
        }
      }
    }

    dispatch(hideLoader());

    if (receivableFailure.length) {
      history.push(SCREEN_PATHS.PAYMENT_REQUESTS_FAILURE);
    } else {
      history.push({
        pathname: `${SCREEN_PATHS.RECEIVABLES_LIST}/success`,
        search: `?date=${rtpDetails.deliveryDetails?.deliveryDate}`,
      });
    }
  };

  const handleBackNavigation = () => history.push(SCREEN_PATHS.PAYMENT_REQUESTS_FAILURE);

  const handleClickOnCustomerName = (receivableId: string) => () => {
    history.push(`${SCREEN_PATHS.FAILED_PAYMENT_REQUESTS_LIST}/${receivableId}`);
  };

  return (
    <Page title="Create Payment Request">
      <ScreenHeader title="Payment Request" />
      <ScreenHeaderSubtitle subtitle="We were unable to create or send the following requests:" />
      <CustomerReceivablesDetailsTable
        data={convertReceivablesToDeliveryData({
          deliveryEmails: rtpDetails.deliveryDetails.deliveryEmails,
          receivables: receivableFailure,
        })}
        handleClickOnCustomerName={handleClickOnCustomerName}
        emailTitle="Customer Email"
      />
      <FooterActionsButtons
        backButtonText="Back to Result Page"
        handleBackButton={handleBackNavigation}
        continueButtonText="Send"
        handleContinue={handleContinue}
      />
    </Page>
  );
};

export default NonCreatedRTPsList;
