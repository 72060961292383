import { createActionCreator } from "deox";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { AppState } from "@APP/redux";
import { AuthState } from "@APP/redux/reducers/auth";
import { API, AppLocalStorage } from "@APP/services";
import { ErpId, Provider } from "@APP/constants";
import {
  fetchClearingLedger,
  fetchLinkedERPLedgers,
  NO_CARD_PAYMENT_APPLICATION_EXIST_ERROR_CODE,
  NO_LINKED_BANK_ACCOUNTS_EXIST_ERROR_CODE,
  NO_USER_SUBSCRIPTION_EXIST_ERROR_CODE,
} from "@APP/services/api";
import {
  CardPaymentApp,
  CardPaymentApplicationStatus,
  ClearingLedger,
  Ledger,
  ReconsentData,
  SubscriptionFeatureTypes,
  UserSubscription,
} from "@APP/types";
import CONFIG from "@APP/config";

export const setAuthLoading = createActionCreator(
  "@AUTH/SET_LOADING",
  (resolve) => (isLoading: boolean) => resolve(isLoading),
);
export const setAuthenticated = createActionCreator(
  "@AUTH/SET_AUTHENTICATED",
  (resolve) => (isAuthenticated: boolean) => resolve(isAuthenticated),
);
export const setUserData = createActionCreator(
  "@AUTH/SET_USER_DATA",
  (resolve) => (userData: AuthState["user"]) => resolve(userData),
);

export const setBankLedgers = createActionCreator(
  "@AUTH/SET_BANK_LEDGERS",
  (resolve) => (bankLedger: Ledger[]) => resolve(bankLedger),
);
export const setClearingLedger = createActionCreator(
  "@AUTH/SET_CLEARING_LEDGER",
  (resolve) => (clearingLedger: ClearingLedger | null) => resolve(clearingLedger),
);
export const setUserSubscription = createActionCreator(
  "@AUTH/SET_USER_SUBSCRIPTION",
  (resolve) => (subscription: UserSubscription | null) => resolve(subscription),
);

export const setReconsentBanks = createActionCreator(
  "@AUTH/SET_RECONSENT_BANKS",
  (resolve) => (data: ReconsentData[]) => resolve(data),
);

export const setCardPaymentApp = createActionCreator(
  "@AUTH/SET_CARD_PAYMENT_USER_DETAILS",
  (resolve) => (data: CardPaymentApp) => resolve(data),
);

export const login =
  (email: string, password: string) => async (dispatch: ThunkDispatch<AppState, void, Action>) => {
    dispatch({ type: "@AUTH/LOGIN" });
    dispatch(setAuthLoading(true));

    try {
      const { token } = await API.login(email, password);
      AppLocalStorage.setItem("auth.token", token);
      dispatch(setAuthenticated(true));
      dispatch(setAuthLoading(false));
    } catch (err) {
      dispatch(setAuthLoading(false));
      throw err;
    }
  };

export const fetchUserData = () => async (dispatch: ThunkDispatch<AppState, void, Action>) => {
  dispatch({ type: "@AUTH/FETCH_USER_DATA" });
  dispatch(setAuthLoading(true));

  try {
    const userInfo = await API.getUserInfo();
    const { currentErp } = await API.getCurrentERP();
    let bankAccounts = null;
    let subscription = null;
    let errorFromBankAccountRequest = null;
    let cardPaymentApp = null;

    try {
      bankAccounts = await dispatch(fetchBankAccounts());
    } catch (error) {
      const errorCode = error?.response?.data?.errorCode;

      if (![NO_LINKED_BANK_ACCOUNTS_EXIST_ERROR_CODE].includes(errorCode)) {
        errorFromBankAccountRequest = error;
      }
    }

    /**
     * If subscription feature available,
     * make request for active plan
     */
    if (CONFIG.FEATURES.SUBSCRIPTIONS.TYPE !== SubscriptionFeatureTypes.None && userInfo.org) {
      subscription = await dispatch(fetchUserSubscription());
    }

    if (currentErp !== ErpId.INTERNAL && userInfo.org) {
      const { data } = await fetchLinkedERPLedgers(currentErp);

      if (data) {
        dispatch(setBankLedgers(data));
      }
    }

    if (Provider.isMaverick) {
      cardPaymentApp = await dispatch(fetchCardPaymentAppDetails());

      if (
        currentErp !== ErpId.INTERNAL &&
        cardPaymentApp?.status === CardPaymentApplicationStatus.Approved
      ) {
        try {
          const data = await fetchClearingLedger(currentErp);

          dispatch(setClearingLedger(data));
        } catch (e) {
          dispatch(setClearingLedger(null));
        }
      }
    }

    const userData = {
      ...userInfo,
      bankAccounts,
      erp: currentErp === ErpId.NO_ERP ? null : currentErp,
      subscription,
      cardPaymentApp,
    };

    dispatch(setUserData(userData as AuthState["user"]));
    dispatch(setAuthenticated(true));
    dispatch(setAuthLoading(false));

    if (errorFromBankAccountRequest) throw errorFromBankAccountRequest;

    return userData;
  } catch (error) {
    throw error;
  }
};

export const fetchCardPaymentAppDetails =
  () => async (dispatch: ThunkDispatch<AppState, void, Action>) => {
    try {
      const cardPaymentDetails = await API.getMaverickApp();

      dispatch(setCardPaymentApp(cardPaymentDetails));

      return cardPaymentDetails;
    } catch (error) {
      const errorCode = error.response?.data?.errorCode;

      if (errorCode === NO_CARD_PAYMENT_APPLICATION_EXIST_ERROR_CODE) return null;

      throw error;
    }
  };

export const fetchUserSubscription =
  () => async (dispatch: ThunkDispatch<AppState, void, Action>) => {
    try {
      const userSubscription = await API.getUserSubscription();
      const plans = await API.getSubscriptionsBillingPlans();

      let usersPlan = plans.find((plan) => plan.id === userSubscription?.planId);

      if (!usersPlan) {
        // Take the first plan available for the user
        usersPlan = plans.find((plan) => plan.available);
      }

      const subscription: UserSubscription = {
        ...userSubscription,
        plan: usersPlan,
        isPlanFree: usersPlan?.price.amount === "0.00",
      };

      dispatch(setUserSubscription(subscription));

      return subscription;
    } catch (err) {
      const errorCode = err.response?.data?.errorCode;

      if (errorCode === NO_USER_SUBSCRIPTION_EXIST_ERROR_CODE) {
        return null;
      }

      throw err;
    }
  };

export const fetchBankAccounts = () => async () => {
  try {
    if (Provider.isMoneyhub) {
      const bankAccounts = (await API.getAllMoneyhubBanksAccounts()).data;

      if (bankAccounts) {
        const bankAccountsExtended = await Promise.all(
          bankAccounts
            .filter((account) => !!account?.account?.identification)
            .map(async (account) => {
              const bankId = account.bankId.toLowerCase();
              const [
                {
                  data: [balance],
                },
                bankInfo,
              ] = await Promise.all([
                API.getMoneyhubBankAccountBalance(bankId, account.accountId),
                API.getCustodian(bankId),
              ]);

              return { ...account, balance, bankInfo };
            }),
        );

        return bankAccountsExtended;
      }
    }

    if (Provider.isMX) {
      const bankAccounts = (await API.getMxBankAccounts()).data;

      if (bankAccounts) {
        const bankAccountsExtended = await Promise.all(
          bankAccounts
            .filter((account) => !!account?.account?.identification)
            .map(async (account) => {
              const [
                {
                  data: [balance],
                },
                bankInfo,
              ] = await Promise.all([
                API.getMxBankAccountBalance(account.accountId),
                API.getMxInstitution(account.servicer.identification),
              ]);

              return {
                ...account,
                balance,
                bankInfo,
              };
            }),
        );

        return bankAccountsExtended;
      }
    }

    return null;
  } catch (error) {
    throw error;
  }
};

export const logout = createActionCreator("@AUTH/LOGOUT", (resolve) => () => {
  AppLocalStorage.removeItem("auth.token");
  AppLocalStorage.removeItem("auth.tokenToPayInvoices");
  AppLocalStorage.removeItem("persist:root");
  return resolve();
});
