import { Box, CardContent, CardHeader, Divider, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardHeader: {
    textAlign: "center",
  },
  cardContent: {
    flexGrow: 1,
  },
  paragraph: {
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

type Props = {
  bankName: string;
  bankProvider: string;
  isExpired?: boolean;
};

const ConsentContent = ({ bankName, bankProvider = "Moneyhub", isExpired = false }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <CardHeader
        title={
          <Typography className={classes.cardHeader}>
            <AccountBalanceIcon fontSize="large" color="primary" />
            <Box data-testid="access-account-information-title">
              <b>CONSENT TO ACCESS ACCOUNT INFORMATION</b>
            </Box>
          </Typography>
        }
      />
      <Divider />
      <CardContent className={classes.cardContent} data-testid="access-account-information-content">
        {isExpired && (
          <Box pt={2} pb={4} textAlign="center">
            <Typography variant="h4">
              <b>Your current consent has expired!</b>
            </Typography>
          </Box>
        )}
        <Typography className={classes.paragraph}>
          <b>{t("ProductName")}</b> is requesting access to data from your{" "}
          <b>{bankName} Open Banking </b>
          account. To enable this{" "}
          <span style={{ textTransform: "capitalize" }}>{bankProvider}</span> needs to access the{" "}
          following information:
          <br />
          <br /> - Your account balance <br /> - Your transactions from the last 12 months
        </Typography>
        <Typography className={classes.paragraph}>
          <span style={{ textTransform: "capitalize" }}>{bankProvider}</span> will access this
          information for the next 90 days.
          <br />
        </Typography>
        <Typography className={classes.paragraph}>
          {!isExpired && (
            <>
              If you approve we will redirect you to <b>{bankName} Open Banking</b> where you can{" "}
              log in and choose which accounts to share.
            </>
          )}
        </Typography>
        <Typography className={classes.paragraph}>
          <span style={{ textTransform: "capitalize" }}>{bankProvider}</span>'s Privacy Policy
          explains how those parties will access, process and retain your personal data for the
          provision of the account information service.
        </Typography>
        <Typography className={classes.paragraph}>
          By clicking 'Approve' you consent to: <br /> - The provision of the account information{" "}
          service on an ongoing basis <br /> - The{" "}
          <span style={{ textTransform: "capitalize" }}>{bankProvider}</span> Terms of Use.
          <br />
          Before continuing, please read and save these terms.
        </Typography>
      </CardContent>
    </>
  );
};

export default ConsentContent;
