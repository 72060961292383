import { useCallback } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Card, CardActions, CardHeader, Divider } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { BankAccountsTiled, DisplayedBankData, NoLinkedBankAccountsContent } from "@APP/components";
import { getBankAccounts } from "@APP/redux";
import { SCREEN_PATHS } from "@APP/navigation";
import { Provider, TabsName } from "@APP/constants";

const useStyles = makeStyles((theme) => ({
  cardActionsContainer: {
    justifyContent: "center",
  },
  cardActionButton: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const BankAccounts = () => {
  const bankAccounts = useSelector(getBankAccounts);
  const classes = useStyles();

  const renderBankAccountsList = useCallback(() => {
    if (!bankAccounts?.length) {
      return <NoLinkedBankAccountsContent />;
    }

    const bankAccountsCommonFormat = bankAccounts?.map(
      ({
        account: { identification: accountNumber, name: accountName },
        bankInfo,
        balance: { amount: balance },
      }) =>
        ({
          accountNumber,
          accountName,
          balance,
          bankInfo,
        } as DisplayedBankData),
    );

    return (
      <BankAccountsTiled
        bankAccounts={bankAccountsCommonFormat}
        showLinkedLedgerContent={!Provider.isMX}
        showLinkedLedgerAlert={!Provider.isMX}
      />
    );
  }, [bankAccounts]);

  return (
    <Card elevation={4}>
      <CardHeader
        subheader="Linked bank accounts - with the last known balance."
        subheaderTypographyProps={{ "data-testid": "card-header-linked-bank-subtitle" }}
        title="Bank Accounts"
        titleTypographyProps={{ "data-testid": "card-header-linked-bank-title", component: "h4" }}
        data-testid="bank-accounts-card-header"
      />
      <Divider />
      {renderBankAccountsList()}
      <Divider />
      <CardActions className={classes.cardActionsContainer}>
        <Button
          className={classes.cardActionButton}
          color="primary"
          variant="contained"
          component={RouterLink}
          data-testid="link-bank-accounts-button"
          to={`${SCREEN_PATHS.SETTINGS}?tab=${TabsName.BANK_ACCOUNTS}`}>
          Link additional Bank Accounts
        </Button>
      </CardActions>
    </Card>
  );
};

export default BankAccounts;
