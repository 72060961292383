import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Divider, Grid, useMediaQuery, useTheme } from "@mui/material";
import { addDays, endOfDay, format, isBefore, isSameDay } from "date-fns";
import { useFormik } from "formik";
import { NumberFormatValues } from "react-number-format/types/types";
import * as Yup from "yup";

import { Page, ScreenHeader, ScreenHeaderSubtitle } from "@APP/components";
import { useCashflowForecasting } from "@APP/hooks";
import {
  getForecastData,
  getForecastEndDate,
  getForecastId,
  getForecastLoading,
  getForecastManualBalanceMode,
  hideLoader,
  setDefaultForecastState,
  setForecastChartData,
  setForecastEndDate,
  showLoader,
  useAppDispatch,
} from "@APP/redux";
import { API } from "@APP/services";
import {
  CashflowForecastingPaymentType,
  CustomForecastRequest,
  ForecastFilterOp,
  ForecastInvoice,
  ForecastManualTransaction,
} from "@APP/types";
import { API_DATE_FORMAT } from "@APP/constants";
import CONFIG from "@APP/config";

import ForecastAccounts from "./ForecastAccounts";
import { ForecastChart } from "./ForecastChart";
import ForecastPeriod from "./ForecastPeriod";
import ForecastSection from "./ForecastSection";
import OtherPayments from "./OtherPayments";
import ForecastErrorSection from "./ForecastErrorSection";
import { InvoiceTable, OtherPaymentsTable, UnpaidInvoiceContent } from "./ForecastTables";

export const getBalanceInputValidationSchema = (totalAccountBalanceText: string) =>
  Yup.object().shape({
    totalAccountBalance: Yup.string().required(`Please enter your ${totalAccountBalanceText}.`),
  });

const receivablePayableTableTitles = [
  { title: "Due date", testId: "due-date" },
  { title: "Customer", testId: "customer" },
  { title: "Amount", testId: "amount" },
];

const otherPaymentsTableTitles = [
  { title: "Payment date", testId: "payment-date" },
  { title: "Amount", testId: "amount" },
  { title: "Payment type", testId: "payment-type" },
];

export type ForecastInvoiceWithSelected = ForecastInvoice & { selected: boolean };
export type ForecastManualTransactionWithSelected = ForecastManualTransaction & {
  selected: boolean;
};

const CashflowForecastView = () => {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("lg"));
  const dispatch = useAppDispatch();
  const { generateCashflowForecast } = useCashflowForecasting();
  const { t } = useTranslation();

  const forecastId = useSelector(getForecastId);
  const forecastData = useSelector(getForecastData);
  const forecastLoading = useSelector(getForecastLoading);
  const forecastEndDate = useSelector(getForecastEndDate);
  const manualBalanceMode = useSelector(getForecastManualBalanceMode);

  const [receivables, setReceivables] = useState<ForecastInvoiceWithSelected[] | null>(null);
  const [payables, setPayables] = useState<ForecastInvoiceWithSelected[] | null>(null);
  const [manualTxs, setManualTxs] = useState<ForecastManualTransactionWithSelected[] | null>(null);

  const initialAccountBalance = useMemo(
    () =>
      forecastData?.balances?.totalBalance?.amount
        ? Number(forecastData?.balances?.totalBalance?.amount).toFixed(2).toString()
        : "",
    [forecastData],
  );

  useEffect(() => {
    if (forecastLoading) {
      dispatch(showLoader());
    } else {
      dispatch(hideLoader());
    }

    return () => {
      dispatch(hideLoader());
    };
  }, [forecastLoading]);

  useEffect(() => {
    (async () => {
      try {
        const nextSevenDaysDate = addDays(new Date(), 6);
        dispatch(setForecastEndDate(endOfDay(nextSevenDaysDate)));

        const { data } = await generateCashflowForecast(nextSevenDaysDate);
        setReceivables(
          data?.unpaidInvoices.items.map((item) => ({
            ...item,
            selected: true,
          })) ?? null,
        );
        setPayables(
          data?.unpaidBills.items.map((item) => ({
            ...item,
            selected: true,
          })) ?? null,
        );
      } catch (e) {}
    })();
  }, []);

  const createRefineCashflowBody = ({
    manualTxs,
    payables,
    receivables,
  }: {
    manualTxs: ForecastManualTransactionWithSelected[] | null;
    payables?: ForecastInvoice[] | ForecastInvoiceWithSelected[] | null;
    receivables?: ForecastInvoice[] | ForecastInvoiceWithSelected[] | null;
  }): CustomForecastRequest => {
    return {
      manualBalance: manualBalanceMode
        ? {
            currency: CONFIG.INPUTS.SUPPORTED_CURRENCIES[0],
            amount: values.totalAccountBalance,
          }
        : undefined,
      manualTxs: manualTxs
        ?.filter((item) => item.selected)
        .map((item) => ({
          ...item,
          date: format(new Date(item.date), API_DATE_FORMAT),
        })),
      payablesFilter: payables
        ? {
            op: ForecastFilterOp.INCLUDE,
            ids: payables
              .filter((item) => (item as ForecastInvoiceWithSelected).selected)
              .map((item) => item.id),
          }
        : undefined,
      receivablesFilter: receivables
        ? {
            op: ForecastFilterOp.INCLUDE,
            ids: receivables
              ?.filter((item) => (item as ForecastInvoiceWithSelected).selected)
              .map((item) => item.id),
          }
        : undefined,
    };
  };

  const refineCashflowForecast = async (
    payload: CustomForecastRequest,
    forecastIdArgument?: string,
  ) => {
    dispatch(showLoader());

    try {
      const { forecast } = await API.fetchCustomCashflowForecast({
        forecastId: forecastIdArgument ?? forecastId,
        payload,
      });

      dispatch(setForecastChartData(forecast));
      dispatch(hideLoader());

      return Promise.resolve();
    } catch (error) {
      dispatch(hideLoader());

      return Promise.reject();
    }
  };

  const fetchForecast = async () => {
    await refineCashflowForecast(
      createRefineCashflowBody({
        payables,
        receivables,
        manualTxs,
      }),
    );
  };

  useEffect(() => {
    return () => {
      dispatch(setDefaultForecastState());
    };
  }, []);

  const { handleSubmit, handleBlur, setFieldValue, values, errors, touched } = useFormik({
    validationSchema: getBalanceInputValidationSchema(t("CashFlowForecast.TotalAccountBalance")),
    initialValues: {
      totalAccountBalance: initialAccountBalance,
    },
    onSubmit: fetchForecast,
  });

  useEffect(() => {
    if (!manualBalanceMode && initialAccountBalance)
      setFieldValue("totalAccountBalance", initialAccountBalance);
  }, [manualBalanceMode]);

  useEffect(() => {
    if (initialAccountBalance && !values.totalAccountBalance) {
      setFieldValue("totalAccountBalance", initialAccountBalance);
    }
  }, [initialAccountBalance]);

  const invoiceTypeSetterHelper = async (
    type: CashflowForecastingPaymentType,
    callBack: (
      item: ForecastInvoiceWithSelected | ForecastManualTransactionWithSelected,
    ) => ForecastInvoiceWithSelected | ForecastManualTransactionWithSelected,
  ) => {
    switch (type) {
      case CashflowForecastingPaymentType.Payables:
        const newPayables = payables!.map(callBack) as ForecastInvoiceWithSelected[];

        return refineCashflowForecast(
          createRefineCashflowBody({
            payables: newPayables,
            receivables,
            manualTxs,
          }),
        ).then(() => setPayables(newPayables));
      case CashflowForecastingPaymentType.Receivables:
        const newReceivables = receivables!.map(callBack) as ForecastInvoiceWithSelected[];

        return refineCashflowForecast(
          createRefineCashflowBody({
            payables,
            receivables: newReceivables,
            manualTxs,
          }),
        ).then(() => setReceivables(newReceivables));
      default:
        const newManualTxs = manualTxs!.map(callBack) as ForecastManualTransactionWithSelected[];

        return refineCashflowForecast(
          createRefineCashflowBody({
            payables,
            receivables,
            manualTxs: newManualTxs,
          }),
        ).then(() => setManualTxs(newManualTxs));
    }
  };

  const handleSelectAllReceivables = async (selectedStatus: boolean) => {
    await invoiceTypeSetterHelper(CashflowForecastingPaymentType.Receivables, (item) => ({
      ...item,
      selected: !selectedStatus,
    }));
  };

  const handleSelectAllPayables = async (selectedStatus: boolean) => {
    await invoiceTypeSetterHelper(CashflowForecastingPaymentType.Payables, (item) => ({
      ...item,
      selected: !selectedStatus,
    }));
  };

  const handleSelectAllManualTx = async (selectedStatus: boolean) => {
    await invoiceTypeSetterHelper(CashflowForecastingPaymentType.ManualTx, (item) => ({
      ...item,
      selected: isBefore(
        forecastEndDate,
        new Date((item as ForecastManualTransactionWithSelected).date),
      )
        ? false
        : !selectedStatus,
    }));
  };

  const handleSelectPayable = async (selectedItem: ForecastInvoiceWithSelected) => {
    await invoiceTypeSetterHelper(CashflowForecastingPaymentType.Payables, (item) =>
      item === selectedItem ? { ...item, selected: !item.selected } : item,
    );
  };

  const handleSelectReceivable = async (selectedItem: ForecastInvoiceWithSelected) => {
    await invoiceTypeSetterHelper(CashflowForecastingPaymentType.Receivables, (item) =>
      item === selectedItem ? { ...item, selected: !item.selected } : item,
    );
  };

  const handleSelectManualTx = async (selectedItem: ForecastManualTransactionWithSelected) => {
    await invoiceTypeSetterHelper(CashflowForecastingPaymentType.ManualTx, (item) =>
      item === selectedItem ? { ...item, selected: !item.selected } : item,
    );
  };

  const handleForecastPeriodChange = async (value: Date) => {
    if (!isSameDay(value, forecastEndDate)) {
      const { data: forecastData, forecastId } = await generateCashflowForecast(value);
      const unpaidInvoiceItems = forecastData?.unpaidInvoices.items.map((item) => ({
        ...item,
        selected: !!receivables?.find(({ id }) => id === item.id)?.selected,
      }));
      const unpaidBillsItems = forecastData?.unpaidBills.items.map((item) => ({
        ...item,
        selected: !!payables?.find(({ id }) => id === item.id)?.selected,
      }));

      if (manualTxs) {
        const newManualTxs = manualTxs.map((payment) =>
          isBefore(new Date(value), new Date(payment.date))
            ? { ...payment, selected: false }
            : payment,
        );

        await refineCashflowForecast(
          createRefineCashflowBody({
            payables: unpaidBillsItems,
            receivables: unpaidInvoiceItems,
            manualTxs: newManualTxs,
          }),
          forecastId,
        );

        setManualTxs(newManualTxs);
      } else if (manualBalanceMode) {
        await refineCashflowForecast(
          createRefineCashflowBody({
            payables: unpaidBillsItems,
            receivables: unpaidInvoiceItems,
            manualTxs,
          }),
          forecastId,
        );
      }

      if (unpaidInvoiceItems) setReceivables(unpaidInvoiceItems);
      if (unpaidBillsItems) setPayables(unpaidBillsItems);
    }
  };

  const handleBalanceChange = (numberFormatValues: NumberFormatValues) => {
    setFieldValue("totalAccountBalance", numberFormatValues.value);
  };

  const handleAddOtherPayment = async (payment: ForecastManualTransactionWithSelected) => {
    refineCashflowForecast(
      createRefineCashflowBody({
        payables,
        receivables,
        manualTxs: manualTxs ? [...manualTxs, payment] : [payment],
      }),
    ).then(() => setManualTxs((prevData) => (prevData ? [...prevData, payment] : [payment])));
  };

  const getChartErrorMessage = () => {
    if (!forecastData) {
      return t("CashFlowForecast.ChartErrorMessage.NoData");
    }

    /*
      App should display error only for cases:
        - balance length is 0, and manual balance mode is false
        - manual balance mode is true and total account balance isn't existed
        - manual balance mode is true, and total account balance is existed, and forecast data length equals 0.
    */
    if (
      (!forecastData.balances?.items?.length && !manualBalanceMode) ||
      (manualBalanceMode && (!values.totalAccountBalance || !forecastData.forecast?.length))
    ) {
      return t("CashFlowForecast.ChartErrorMessage.NoTotalAccountBalance");
    }

    return undefined;
  };

  if (forecastLoading && !forecastData) {
    return null;
  }

  const renderMainContent = () => {
    if (!forecastLoading && !forecastData)
      return (
        <Grid item xs={12}>
          <ForecastErrorSection onTryAgain={generateCashflowForecast} />
        </Grid>
      );

    return (
      <>
        <Grid item xs={12}>
          <ForecastAccounts
            handleBalanceChange={handleBalanceChange}
            totalAccountBalance={values.totalAccountBalance}
            onTryAgain={generateCashflowForecast}
            handleOnSubmit={handleSubmit}
            handleBlur={handleBlur}
            errors={errors}
            touched={touched}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <ForecastSection
            title="Unpaid Invoices"
            subTitle={t("CashFlowForecast.UnpaidInvoices.Subtitle")}
            titleInfoWidgetText={`This is cash owed to you. The table contains Invoices in ${CONFIG.INPUTS.SUPPORTED_CURRENCIES[0]} only.`}>
            <UnpaidInvoiceContent
              title={`Total Unpaid\nInvoices`}
              amountColor="secondary"
              data={receivables}
            />
            <InvoiceTable
              tableTitles={receivablePayableTableTitles}
              data={receivables}
              tableKey={CashflowForecastingPaymentType.Receivables}
              handleSelectRow={handleSelectReceivable}
              handleSelectAllRows={handleSelectAllReceivables}
              emptyListMessage={t("CashFlowForecast.UnpaidInvoices.EmptyListMessage")}
            />
          </ForecastSection>
        </Grid>
        <Grid item lg={6} xs={12}>
          <ForecastSection
            title="Unpaid Bills to Pay"
            subTitle={t("CashFlowForecast.UnpaidBills.Subtitle")}
            titleInfoWidgetText={`This is cash you owe. The table contains Bills in ${CONFIG.INPUTS.SUPPORTED_CURRENCIES[0]} only.`}>
            <UnpaidInvoiceContent
              title={`Total Unpaid\nBills to Pay`}
              amountColor="error"
              data={payables}
            />
            <InvoiceTable
              tableTitles={receivablePayableTableTitles}
              data={payables}
              tableKey={CashflowForecastingPaymentType.Payables}
              handleSelectRow={handleSelectPayable}
              handleSelectAllRows={handleSelectAllPayables}
              emptyListMessage={t("CashFlowForecast.UnpaidBills.EmptyListMessage")}
            />
          </ForecastSection>
        </Grid>
        <Grid item xs={12}>
          <ForecastSection
            title="Other Payments"
            subTitle={t("CashFlowForecast.OtherPayments.Subtitle")}
            noPadding>
            <Grid container>
              <Grid item lg={6} xs={12}>
                <Box m={2}>
                  <OtherPayments handleAddPayment={handleAddOtherPayment} />
                </Box>
              </Grid>
              {isPhone && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              )}
              <Grid item lg={6} xs={12} style={{ display: "flex", flexDirection: "column" }}>
                <OtherPaymentsTable
                  tableTitles={otherPaymentsTableTitles}
                  tableKey={CashflowForecastingPaymentType.ManualTx}
                  data={manualTxs}
                  handleSelectAllRows={handleSelectAllManualTx}
                  handleSelectRow={handleSelectManualTx}
                  emptyListMessage={t("CashFlowForecast.OtherPayments.EmptyListMessage")}
                />
              </Grid>
            </Grid>
          </ForecastSection>
        </Grid>
        <Grid item xs={12}>
          <ForecastChart
            errorMessage={getChartErrorMessage()}
            onTryAgain={generateCashflowForecast}
          />
        </Grid>
      </>
    );
  };

  return (
    <Page title="Cash Flow Forecast">
      <ScreenHeader title="Cash Flow Forecast" />
      <ScreenHeaderSubtitle subtitle={t("CashFlowForecast.ScreenSubheader")} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ForecastPeriod handleForecastPeriodChange={handleForecastPeriodChange} />
        </Grid>
        {renderMainContent()}
      </Grid>
    </Page>
  );
};

export default CashflowForecastView;
