import { useSelector } from "react-redux";
import { Link } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useHistory } from "react-router-dom";
import { endOfDay, isAfter } from "date-fns";

import { FooterActionsButtons, Page, ResultNotification, ScreenHeader } from "@APP/components";
import { SCREEN_PATHS } from "@APP/navigation";
import { getAutomatedCollections, getDeliveryDetails } from "@APP/redux";
import { Receivable } from "@APP/types";

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: "underline",
    ...theme.typography.h5,
  },
}));

const getContentFailureMessage = (
  receivablesSuccess: Receivable[],
  receivableFailure: Receivable[],
  deliveryDate: Date,
) => {
  const isDeliveryScheduled = isAfter(endOfDay(deliveryDate), endOfDay(new Date()));

  if (receivableFailure.length && receivableFailure[0].id === "receivable-not-created") {
    return "Sorry, your Invoice wasn’t created. Please try again later.";
  } else if (receivableFailure.length && receivableFailure[0].id === "receivable-not-approved") {
    return "Sorry, your Invoice wasn’t approved. Please try again later.";
  } else if (!receivablesSuccess.length && receivableFailure.length === 1) {
    if (isDeliveryScheduled) return "Your payment request wasn't created. Please try again later.";

    return "Your payment request wasn't sent. Please try again later.";
  } else if (!receivablesSuccess.length && receivableFailure.length) {
    if (isDeliveryScheduled)
      return "Your payment requests weren't created. Please try again later.";

    return "Your payment requests weren't sent. Please try again later.";
  } else if (receivablesSuccess.length === 1) {
    const allReceivablesLength = receivablesSuccess.length + receivableFailure.length;

    return isDeliveryScheduled
      ? `Only ${receivablesSuccess.length} of ${allReceivablesLength} payment requests was saved. It will be sent to the customer on ${deliveryDate}.`
      : `Only ${receivablesSuccess.length} of ${allReceivablesLength} payment requests was successfully created.`;
  } else if (receivablesSuccess.length) {
    const allReceivablesLength = receivablesSuccess.length + receivableFailure.length;

    return isDeliveryScheduled
      ? `Only ${receivablesSuccess.length} of ${allReceivablesLength} payment requests were saved. They will be sent to the customers on ${deliveryDate}.`
      : `Only ${receivablesSuccess.length} of ${allReceivablesLength} payment requests were successfully created.`;
  }
};

const DeliveryFailureView = () => {
  const classes = useStyles();
  const history = useHistory();

  const { receivablesSuccess, receivableFailure } = useSelector(getAutomatedCollections);
  const { deliveryDate } = useSelector(getDeliveryDetails);

  const contentMessage = getContentFailureMessage(
    receivablesSuccess,
    receivableFailure,
    deliveryDate,
  );
  const footerMessage =
    receivableFailure.length === 1 ? "View request not created" : "View requests not created";

  const handleNavigateToDashboard = () => history.push(SCREEN_PATHS.DASHBOARD);
  const handleNavigateToCreateRTP = () => history.push(SCREEN_PATHS.RECEIVABLES_LIST);
  const handleNavigateToNonCreatedRTP = () =>
    history.push(SCREEN_PATHS.FAILED_PAYMENT_REQUESTS_LIST);

  return (
    <Page title="Create Payment Request">
      <ScreenHeader title="Payment Requests" />

      <ResultNotification
        type="error"
        footer={
          <Link className={classes.link} component="button" onClick={handleNavigateToNonCreatedRTP}>
            {footerMessage}
          </Link>
        }>
        {contentMessage}
      </ResultNotification>

      <FooterActionsButtons
        backButtonText="Back to Dashboard"
        handleBackButton={handleNavigateToDashboard}
        continueButtonText="Create New Payment Request"
        handleContinue={handleNavigateToCreateRTP}
      />
    </Page>
  );
};

export default DeliveryFailureView;
