import clsx from "clsx";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SubjectIcon from "@mui/icons-material/Subject";
import ReceiptIcon from "@mui/icons-material/Receipt";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import EventIcon from "@mui/icons-material/Event";
import { ClassNameMap } from "@mui/styles/withStyles";
import EmailIcon from "@mui/icons-material/MailOutline";

import {
  BankAccountExtended,
  InstalmentDetailsResponse,
  RTP,
  RTPDeliveryChannel,
} from "@APP/types";
import { formatCurrency, formatDisplayedDate } from "@APP/utils";

type Props = {
  rtp?: RTP;
  instalmentDetails?: InstalmentDetailsResponse;
  bankAccount?: BankAccountExtended;
  classes: ClassNameMap;
};

const Details = ({ rtp, instalmentDetails, bankAccount, classes }: Props) => (
  <Card elevation={12}>
    <CardHeader title="Details" data-testid="details-card-header" />
    <Divider />
    <CardContent>
      <Table>
        <caption className="visuallyHidden">
          {rtp && "Payment request"} {bankAccount && ", bank account"} Details
        </caption>
        <TableBody>
          <TableRow>
            <TableCell className={clsx(classes.tableCell, classes.smallCell)}>
              <Box display="flex" alignItems="center" mr={1} data-testid="description-field-label">
                <SubjectIcon className={classes.infoIcon} color="action" />
                <Typography variant="subtitle2">Description:</Typography>
              </Box>
            </TableCell>
            <TableCell className={classes.tableCell}>
              {rtp &&
                rtp.receivable?.lineItems?.map(({ id, description }) => (
                  <Typography key={id} variant="body2">
                    {description}
                  </Typography>
                ))}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={clsx(classes.tableCell, classes.smallCell)}>
              <Box display="flex" alignItems="center" mr={1} data-testid="total-amount-field-label">
                <ReceiptIcon className={classes.infoIcon} color="action" />
                <Typography variant="subtitle2">Total Amount:</Typography>
              </Box>
            </TableCell>
            <TableCell className={classes.tableCell}>
              <Typography variant="body2">
                {formatCurrency(rtp?.amount.amount, { currency: rtp?.amount.currency })}
              </Typography>
            </TableCell>
          </TableRow>
          {rtp?.standingOrder && instalmentDetails?.totalPaid ? (
            <TableRow>
              <TableCell className={clsx(classes.tableCell, classes.smallCell)}>
                <Box
                  display="flex"
                  alignItems="center"
                  mr={1}
                  data-testid="total-amount-field-label">
                  <ReceiptIcon className={classes.infoIcon} color="action" />
                  <Typography variant="subtitle2">Total paid to date:</Typography>
                </Box>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Typography variant="body2">
                  {formatCurrency(instalmentDetails.totalPaid.amount, {
                    currency: instalmentDetails.totalPaid.currency,
                  })}
                </Typography>
              </TableCell>
            </TableRow>
          ) : null}
          {bankAccount && (
            <TableRow>
              <TableCell className={clsx(classes.tableCell, classes.smallCell)}>
                <Box
                  display="flex"
                  alignItems="center"
                  mr={1}
                  data-testid="total-amount-field-label">
                  <AccountCircleIcon className={classes.infoIcon} color="action" />
                  <Typography variant="subtitle2">Payee Account:</Typography>
                </Box>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Typography variant="body2">
                  {bankAccount.account.identification} / {bankAccount.account.name}
                </Typography>
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell className={clsx(classes.tableCell, classes.smallCell)}>
              <Box display="flex" alignItems="center" mr={1} data-testid="reference-field-label">
                <LocalOfferIcon className={classes.infoIcon} color="action" />
                <Typography variant="subtitle2">Reference:</Typography>
              </Box>
            </TableCell>
            <TableCell className={classes.tableCell}>
              <Typography variant="body2">{rtp?.receivable.reference}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={clsx(classes.tableCell, classes.smallCell)}>
              <Box display="flex" alignItems="center" mr={1} data-testid="date-issued-field-label">
                <EventIcon className={classes.infoIcon} color="action" />
                <Typography variant="subtitle2">Date Issued:</Typography>
              </Box>
            </TableCell>
            <TableCell className={classes.tableCell}>
              <Typography variant="body2">
                {formatDisplayedDate(rtp?.receivable.dateTimeIssued)}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={clsx(classes.tableCell, classes.smallCell)}>
              <Box display="flex" alignItems="center" mr={1} data-testid="due-date-field-label">
                <EventIcon className={classes.infoIcon} color="action" />
                <Typography variant="subtitle2">Due Date:</Typography>
              </Box>
            </TableCell>
            <TableCell className={classes.tableCell}>
              <Typography variant="body2">
                {formatDisplayedDate(rtp?.receivable.dueDateTime)}
              </Typography>
            </TableCell>
          </TableRow>
          {rtp?.deliveryDates?.[0] || rtp?.scheduledDates ? (
            <TableRow>
              <TableCell className={clsx(classes.tableCell, classes.smallCell)}>
                <Box display="flex" alignItems="center" mr={1} data-testid="due-date-field-label">
                  <EventIcon className={classes.infoIcon} color="action" />
                  <Typography variant="subtitle2">Delivery Date:</Typography>
                </Box>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Typography variant="body2">
                  {rtp?.scheduledDates?.[0]
                    ? formatDisplayedDate(rtp?.scheduledDates[0])
                    : rtp?.deliveryDates?.[0]
                    ? formatDisplayedDate(rtp?.deliveryDates?.[0])
                    : "-"}
                </Typography>
              </TableCell>
            </TableRow>
          ) : null}
          {rtp?.channel === RTPDeliveryChannel["Email"] && (
            <TableRow>
              <TableCell className={clsx(classes.tableCell, classes.smallCell)}>
                <Box
                  display="flex"
                  alignItems="center"
                  mr={1}
                  data-testid="date-issued-field-label">
                  <EmailIcon className={classes.infoIcon} color="action" />
                  <Typography variant="subtitle2">Delivery Email:</Typography>
                </Box>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Typography variant="body2">{rtp?.customer.email}</Typography>
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell className={clsx(classes.tableCell, classes.smallCell)}>
              <Box display="flex" alignItems="center" data-testid="scheduled-date-field-label">
                <EventIcon className={classes.infoIcon} color="action" />
                <Typography variant="subtitle2">Due Date Reminder:</Typography>
              </Box>
            </TableCell>
            <TableCell className={classes.tableCell}>
              <Typography variant="body2">{rtp?.reminders?.[0]?.enabled ? "On" : "Off"}</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </CardContent>
  </Card>
);

export default Details;
