import { useCallback } from "react";

import { AppState } from "@APP/redux";
import { Provider } from "@APP/constants";

import { useCheckCardPaymentAppAvailable, useCheckLedgersLinked } from "./checks";

export type FeatureGateways = ReturnType<typeof useFeatureGateway>[keyof ReturnType<
  typeof useFeatureGateway
>];

type CreatePaymentOptions = {
  checkClearingLedger?: boolean;
};

/**
 * Entry point for all features that are needed in checks
 */
const useFeatureGateway = () => {
  const checkCardPaymentAppAvailable = useCheckCardPaymentAppAvailable();
  const checkLedgersLinked = useCheckLedgersLinked();

  const createPayment = useCallback(async (options?: CreatePaymentOptions, state?: AppState) => {
    const checkCardPaymentAppAvailableResult = await checkCardPaymentAppAvailable(state);

    if (!checkCardPaymentAppAvailableResult) return false;

    return await checkLedgersLinked(options?.checkClearingLedger && Provider.isMaverick);
  }, []);

  return { createPayment };
};

export default useFeatureGateway;
