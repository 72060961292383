import { ErrorCode } from "@APP/types";

export const ERROR_CODES_LIST = [
  1000, 1007, 1009, 1016, 1017, 1020, 1021, 1030, 1031, 1032, 1033, 1037, 1101, 1230, 1231, 1232,
  1233, 1234, 1330, 1332, 1333, 1334, 1335, 2347, 2348, 2349, 3000, 3005, 3006, 4005, 4321, 5001,
  5003, 5005, 6006, 6009, 6007, 6017, 6030, 6102, 6103, 6106, 6108, 6109, 6110, 6111, 6301, 6302,
  6912, 6920, 7035, 7036, 8002, 20000, 20100, 63011,
] as const;

export const ERROR_CODE_OBJECTS: ErrorCode[] = [3000, 3006, 5001, 6108, 6109];

export const NOT_UNIQUE_CUSTOMER_NAME_ERROR_CODES: ErrorCode[] = [6110, 6111];

export const RTP_VALIDATION_ERROR_CODES: ErrorCode[] = [8002, 6302];

export const NO_CARD_PAYMENT_APPLICATION_EXIST_ERROR_CODE: ErrorCode = 20000;

export const REQUEST_LIMIT_REACHED_ERROR_CODE: ErrorCode = 6109;

export const NO_LINKED_BANK_ACCOUNTS_EXIST_ERROR_CODE: ErrorCode = 4321;

export const UNLINK_MX_BANK_ACCOUNT_ERROR_CODE: ErrorCode = 4005;

export const BANK_CONSENT_EXPIRED_ERROR_CODE: ErrorCode = 3006;

export const NO_TOKEN_FOUND_FOR_AIS_ERROR_CODE: ErrorCode = 5003;

export const TOKEN_EXPIRED_ERROR_CODE: ErrorCode = 5005;

export const NO_USER_SUBSCRIPTION_EXIST_ERROR_CODE: ErrorCode = 1031;

export const UNPAID_RTPS_EXIST_FOR_SUBSCRIPTION_ERROR_CODE: ErrorCode = 1037;

export const NO_CLEARING_LEDGER_CREATED_ERROR_CODE: ErrorCode = 6912;

export const CLEARING_LEDGER_ALREADY_EXIST_ERROR_CODE: ErrorCode = 6920;

export const INVALID_MAVERICK_SEC_ERROR_CODE: ErrorCode = 20100;

export const NO_PENDING_EMAIL_VERIFICATION_ERROR_CODE: ErrorCode = 2349;
