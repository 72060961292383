import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { Box, Button, Card, Grid, Link, TableCell, TableRow, Typography } from "@mui/material";
import ReceiptIcon from "@mui/icons-material/Receipt";

import {
  ActiveCheckbox,
  FooterActionsButtons,
  Page,
  ResultNotification,
  ScreenHeader,
  ScreenHeaderSubtitle,
  SortableTitle,
  Table,
} from "@APP/components";
import {
  addSelectedReceivable,
  clearFailureListReceivables,
  clearSuccessListReceivables,
  getAutomatedCollections,
  getDashboardApp,
  getUser,
  hideLoader,
  removeSelectedReceivable,
  setDefaultRtpDetailsToReceivables,
  setDefaultInvoiceState,
  setDeliveryEmails,
  showLoader,
  updateTypedContractDetails,
  useAppDispatch,
  setDefaultAutomatedCollectionsState,
  getBankLedgers,
} from "@APP/redux";
import { InvoiceType, Receivable, SortBy, SortingAriaType, SortType } from "@APP/types";
import { SCREEN_PATHS } from "@APP/navigation";
import { ErpId, NUMBER_OF_FIRST_PAGE_IN_TABLE_WITH_PAGINATION } from "@APP/constants";
import { useAlert, useHandleErrorCodes } from "@APP/hooks";
import { API } from "@APP/services";
import {
  formatCurrency,
  handleKeyboardClick,
  formatDisplayedDate,
  formatErrorMessage,
} from "@APP/utils";
import CONFIG from "@APP/config";

import { useStyles } from "./styles";

const ReceivablesListView = () => {
  const classes = useStyles();
  const alert = useAlert();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const handleErrorCodes = useHandleErrorCodes();

  const {
    page: initialPage = 0,
    entries: initialEntries = 10,
    sort_by: initialSortBy = SortBy.dueDate,
    sort_type: initialSortType = SortType.desc,
  } = queryString.parse(history.location.search);

  const [receivables, setReceivables] = useState<Receivable[]>([]);
  const [entries, setEntries] = useState<number>(initialEntries ? Number(initialEntries) : 10);
  const [page, setPage] = useState<number>(
    initialPage ? Number(initialPage) : NUMBER_OF_FIRST_PAGE_IN_TABLE_WITH_PAGINATION,
  );
  const [lastPage, setLastPage] = useState(0);
  const [sortBy, setSortBy] = useState<SortBy>(initialSortBy as SortBy);
  const [sortType, setSortType] = useState<SortType>(initialSortType as SortType);
  const [totalReceivables, setTotalReceivables] = useState(0);
  const [error, setError] = useState(false);

  const user = useSelector(getUser);
  const ERPLedgers = useSelector(getBankLedgers);
  const { isLoading } = useSelector(getDashboardApp);
  const { t } = useTranslation();
  const {
    receivables: selectedReceivables,
    receivablesSuccess,
    receivableFailure,
  } = useSelector(getAutomatedCollections);

  useEffect(() => {
    if (receivablesSuccess.length) dispatch(clearSuccessListReceivables());
    if (receivableFailure.length) dispatch(clearFailureListReceivables());
  }, []);

  useEffect(() => {
    (async () => {
      await fetchReceivables();
      handlePagination(page, entries);
    })();
  }, [page, entries, sortBy, sortType]);

  const fetchReceivables = async () => {
    dispatch(showLoader());
    try {
      const { data: receivablesData, meta } = await API.getInvoices(
        user?.erp as ErpId,
        InvoiceType.Receivables,
        { page, entries },
        { sort_by: sortBy, sort_type: sortType },
      );

      setTotalReceivables(meta?.totalItems || 0);

      if (meta.totalItems) {
        if (meta.totalItems <= entries) {
          setLastPage(1);
        } else {
          const lastPageIndex = Math.ceil(meta.totalItems / entries);
          setLastPage(lastPageIndex);
        }
      }

      setReceivables(receivablesData);
    } catch (error) {
      const errorData = error.response.data;
      const isHandled = handleErrorCodes(errorData.errorCode);

      setReceivables([]);
      setError(true);

      if (isHandled) return;

      alert.open(
        t("Errors.Common.Alerts.AlertTitles.Error"),
        t("Errors.Common.Alerts.Generic.Message"),
      );
    }

    dispatch(hideLoader());
  };

  const createQueryParamsString = (params?: any) => {
    const queryStringResults = queryString.parse(history.location.search);

    // create search query url
    return Object.entries({
      page,
      entries,
      ...queryStringResults,
      sort_by: sortBy,
      sort_type: sortType,
      ...params,
    })
      .filter((query) => {
        // filter out falsy values (except 0)
        return !!query[1] || query[1] === Number(0);
      })
      .reduce((acc, query, index) => {
        const [key, value] = query;

        if (index === 0) acc += `?${key}=${value}`;
        else acc += `&${key}=${value}`;

        return acc;
      }, "");
  };

  const handlePagination = (page: number, entries: number) => {
    const search = createQueryParamsString({ page, entries });
    history.replace({
      pathname: history.location.pathname,
      search,
    });
  };

  const handleSorting = (by: SortBy) => {
    setSortBy(by);
    if (sortBy === by) {
      setSortType(sortType === SortType.asc ? SortType.desc : SortType.asc);
    } else {
      setSortType(SortType.asc);
    }

    const search = createQueryParamsString();
    history.push({
      pathname: history.location.pathname,
      search,
    });
  };

  const showExistingRTPAlert = (existingRTP: Receivable[]) => {
    return alert.open(
      t("Errors.ReceivableList.Alerts.AlreadyRequested.Title"),
      t("Errors.ReceivableList.Alerts.AlreadyRequested.Message"),
      [
        {
          text: "View payment request",
          onClick: () =>
            window.open(`${SCREEN_PATHS.PAYMENT_REQUESTS_LIST}/${existingRTP[0].id}`, "_blank"),
        },
        {
          text: "Select another Invoice",
        },
      ],
    );
  };

  const handleNavigateToDashboard = () => history.push(SCREEN_PATHS.DASHBOARD);

  const selectRtp = (selectedRtp: Receivable) => async () => {
    if (
      !CONFIG.INPUTS.SUPPORTED_CURRENCIES.includes(selectedRtp.totalAmountTaxInclusive.currency)
    ) {
      return alert.open(
        t("Errors.ReceivableList.Alerts.Currency.Title"),
        t("Errors.ReceivableList.Alerts.Currency.Message", {
          SUPPORTED_CURRENCIES: CONFIG.INPUTS.SUPPORTED_CURRENCIES.join(", "),
        }),
        [{ text: "Okay" }],
      );
    }

    if (
      CONFIG.INPUTS.MAXIMUM_TRANSACTION_AMOUNT < Number(selectedRtp.totalAmountTaxInclusive.amount)
    ) {
      return alert.open(
        t("Errors.ReceivableList.Alerts.TransactionLimit.Title"),
        t("Errors.ReceivableList.Alerts.TransactionLimit.Message", {
          MAXIMUM_TRANSACTION_AMOUNT: formatCurrency(CONFIG.INPUTS.MAXIMUM_TRANSACTION_AMOUNT, {
            minimumFractionDigits: 0,
          }),
        }),
        [{ text: "Okay" }],
      );
    }

    const isSelectedReceivable = selectedReceivables.find(
      (rtp) => rtp.entityDetails.externalId === selectedRtp.entityDetails.externalId,
    );

    if (isSelectedReceivable) {
      return dispatch(removeSelectedReceivable(selectedRtp.entityDetails.externalId));
    }

    dispatch(showLoader());
    const { data: existingRTP } = await API.getReceivable(
      user?.erp as ErpId,
      selectedRtp.entityDetails.externalId,
    );
    dispatch(hideLoader());

    if (existingRTP.length > 0) return showExistingRTPAlert(existingRTP);

    dispatch(addSelectedReceivable(selectedRtp));
  };

  const getIsCheckedColumn = (externalId: string) => {
    return !!selectedReceivables.find((rtp) => rtp.entityDetails.externalId === externalId);
  };

  const handleContinue = async () => {
    if (!ERPLedgers) {
      return;
    }

    try {
      dispatch(showLoader());
      // An additional individual request is required to complete the missing contact info.
      // It's related to the external AP API request limit on the BE side.
      const receivablesWithCompletedInfo = await Promise.all(
        selectedReceivables.map(
          async (rtp) =>
            await API.getInvoice(
              user?.erp as ErpId,
              InvoiceType.Receivables,
              rtp.entityDetails.externalId,
            ),
        ),
      );

      dispatch(setDefaultInvoiceState());
      dispatch(updateTypedContractDetails(receivablesWithCompletedInfo));
      dispatch(setDefaultRtpDetailsToReceivables());

      for (const rtp of receivablesWithCompletedInfo) {
        dispatch(
          setDeliveryEmails({
            invoiceId: rtp.entityDetails.externalId,
            email: rtp.customerContact?.email ?? "",
          }),
        );
      }

      dispatch(hideLoader());
      history.push(SCREEN_PATHS.PAYMENT_REQUESTS_DELIVERY_DETAILS);
    } catch (e) {
      dispatch(hideLoader());
      alert.open(t("Errors.Common.Alerts.AlertTitles.Error"), formatErrorMessage(e));
    }
  };

  const handleOnEntriesChange = (entries: number) => {
    setPage(NUMBER_OF_FIRST_PAGE_IN_TABLE_WITH_PAGINATION);
    setEntries(entries);
  };

  const handleCreateInvoice = () => {
    dispatch(setDefaultInvoiceState());
    dispatch(setDefaultAutomatedCollectionsState());
    dispatch(setDefaultRtpDetailsToReceivables());
    history.push(
      `${SCREEN_PATHS.RECEIVABLES_SELECT_CUSTOMER}?prevPage=${SCREEN_PATHS.RECEIVABLES_LIST}`,
    );
  };

  const handleViewInvoiceClick = (receivableId: string) => async (e: React.SyntheticEvent) => {
    e.stopPropagation();

    history.push(`${SCREEN_PATHS.RECEIVABLES_LIST}/${receivableId}`);
  };

  const renderHeader = () => (
    <TableRow>
      <TableCell>
        <Typography color="error" className="countOfReceivables">
          {selectedReceivables.length}/{totalReceivables}
        </Typography>
      </TableCell>
      <TableCell data-testid="customer-name-field-label">Customer Name</TableCell>
      <TableCell data-testid="unique-reference-field-label">Unique Reference</TableCell>
      <TableCell
        className={classes.sortHeader}
        onClick={() => handleSorting(SortBy.dueDate)}
        onKeyDown={(event) => handleKeyboardClick(event, () => handleSorting(SortBy.dueDate))}
        aria-sort={sortBy === SortBy.dueDate ? SortingAriaType[sortType] : "none"}
        aria-label={`sort invoices by due date ${sortType}`}
        role="columnheader"
        tabIndex={0}>
        <SortableTitle
          active={sortBy === SortBy.dueDate}
          type={sortType}
          typographyDataTestId="invoice-due-date-field-label">
          Invoice Due Date
        </SortableTitle>
      </TableCell>
      {user?.erp === ErpId.SAGE ? (
        <TableCell data-testid="amount-field-label">Amount</TableCell>
      ) : (
        <TableCell
          className={classes.sortHeader}
          onClick={() => handleSorting(SortBy.amount)}
          onKeyDown={(event) => handleKeyboardClick(event, () => handleSorting(SortBy.amount))}
          aria-label={`sort invoices by amount ${sortType}`}
          aria-sort={sortBy === SortBy.amount ? SortingAriaType[sortType] : "none"}
          role="columnheader"
          tabIndex={0}>
          <SortableTitle active={sortBy === SortBy.amount} type={sortType}>
            Amount
          </SortableTitle>
        </TableCell>
      )}
      <TableCell>
        <Typography className="visuallyHidden">View Invoice Details</Typography>
      </TableCell>
    </TableRow>
  );

  const renderRows = (item: Receivable) => {
    const invoiceToggleSelectAriaLabel = `${
      getIsCheckedColumn(item.entityDetails.externalId) ? "Unselect" : "Select"
    } Invoice ${item.reference} for batch payment request creation`;

    return (
      <TableRow
        key={item.reference}
        className={classes.tableRow}
        onClick={selectRtp(item)}
        onKeyDown={(event) => handleKeyboardClick(event, selectRtp(item))}
        hover
        aria-selected={getIsCheckedColumn(item.entityDetails.externalId)}
        tabIndex={0}
        aria-label={invoiceToggleSelectAriaLabel}
        data-testid={item.reference}>
        <TableCell className={classes.checkingCell}>
          <ActiveCheckbox
            className={classes.checkbox}
            checked={getIsCheckedColumn(item.entityDetails.externalId)}
            name="invoice-toggle-select"
            inputProps={{
              "aria-label": invoiceToggleSelectAriaLabel,
            }}
          />
        </TableCell>
        <TableCell>
          <Box display="flex" alignItems="center">
            <ReceiptIcon className={classes.tableIcon} color="action" />
            <Typography variant="h5">{item.customerContact.name}</Typography>
          </Box>
        </TableCell>
        <TableCell>{item.reference}</TableCell>
        <TableCell>{formatDisplayedDate(item.dueDateTime)}</TableCell>
        <TableCell>
          {formatCurrency(item.totalAmountTaxInclusive.amount, {
            currency: item.totalAmountTaxInclusive.currency,
          })}
        </TableCell>
        <TableCell>
          <Link
            component="button"
            className={classes.viewInvoiceLink}
            onClick={handleViewInvoiceClick(item.entityDetails.externalId)}>
            View Invoice
          </Link>
        </TableCell>
      </TableRow>
    );
  };

  if (isLoading && !receivables.length) return null;

  return (
    <Page title="Create Payment Request">
      <ScreenHeader title="Select an Invoice" />
      <Grid container>
        <Grid item xs={12}>
          <ScreenHeaderSubtitle subtitle="Select those you want to create a payment request for or create a new invoice." />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" textAlign="center" justifyContent="flex-end" mb={2}>
            <Button variant="contained" color="secondary" onClick={handleCreateInvoice}>
              Create Invoice
            </Button>
          </Box>
        </Grid>
      </Grid>
      {!error && receivables.length ? (
        <>
          <Box mb={3}>
            <Card elevation={12}>
              <Table
                data={receivables}
                renderHeader={renderHeader}
                renderRows={renderRows}
                onEntriesChange={handleOnEntriesChange}
                onPageChange={setPage}
                page={page}
                entries={entries}
                lastPage={lastPage}
              />
            </Card>
          </Box>
          <FooterActionsButtons
            backButtonText="Back to Dashboard"
            handleBackButton={handleNavigateToDashboard}
            continueButtonText="Continue"
            disabledContinueButton={selectedReceivables.length < 1}
            handleContinue={handleContinue}
          />
        </>
      ) : (
        <Box mt={2}>
          <ResultNotification type={error ? "error" : "info"}>
            {error
              ? t("Errors.ReceivableList.Messages.LoadInvoices")
              : t("Errors.ReceivableList.Messages.NoUnpaid")}
          </ResultNotification>
        </Box>
      )}
    </Page>
  );
};

export default ReceivablesListView;
