import { useState } from "react";
import { useHistory } from "react-router-dom";
import { TFunction, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { CountryCode } from "libphonenumber-js/types";
import { getCountryCallingCode } from "libphonenumber-js";

import {
  CustomerDetailsForm,
  FooterActionsButtons,
  Page,
  ScreenHeader,
  ScreenHeaderSubtitle,
} from "@APP/components";
import CONFIG from "@APP/config";
import { SCREEN_PATHS } from "@APP/navigation";
import { EMAIL_REGEX, Provider } from "@APP/constants";
import { getPhoneNumberValidationSchema } from "@APP/utils";
import {
  getInvoice,
  setAddressInformation,
  setDeliveryEmails,
  setPayerEmail,
  setPayerName,
  setPayerPhone,
  useAppDispatch,
} from "@APP/redux";

export const validationSchema = (countyCode: CountryCode, t: TFunction) => {
  return Yup.object().shape({
    name: Yup.string().required(t("Errors.CustomerCreation.Validation.NameRequired")),
    email: Yup.string()
      .email(t("Errors.Common.Validation.InvalidEmail"))
      .matches(EMAIL_REGEX, t("Errors.Common.Validation.InvalidEmail"))
      .max(255),
    phone: getPhoneNumberValidationSchema(countyCode, t, false).fields.phone,
    address: Yup.string().max(500, t("Errors.CustomerCreation.Validation.AddressLength")),
    addressLine2: Yup.string().max(500, t("Errors.CustomerCreation.Validation.AddressLength")),
    city: Yup.string().max(255, t("Errors.CustomerCreation.Validation.CityLength")),
    zipCode: Yup.string()
      .max(10, t("Errors.CustomerCreation.Validation.ZipCodeLength"))
      .matches(/^[0-9-]+$/, "This field may contain only numbers and hyphen."),
  });
};

const CreateCustomer = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [countryCode, setCountryCode] = useState<CountryCode>(
    CONFIG.INPUTS.DEFAULT_PHONE_COUNTRY_CODE,
  );
  const { customerContact } = useSelector(getInvoice);

  const {
    errors,
    handleBlur,
    handleChange,
    setFieldValue,
    handleSubmit,
    touched,
    values,
    isValid,
  } = useFormik({
    initialValues: {
      name: customerContact?.name || "",
      email: customerContact?.email || "",
      mobile: customerContact?.mobile || "",
      address: customerContact?.shippingAddress?.addressLines?.[0] || "",
      addressLine2: customerContact?.shippingAddress?.addressLines?.[1] || "",
      state: customerContact?.shippingAddress?.state,
      city: customerContact?.shippingAddress?.city || "",
      zipCode: customerContact?.shippingAddress?.postcode || "",
    },
    validationSchema: validationSchema(countryCode, t),
    onSubmit: ({ name, email, mobile, address, addressLine2, state, city, zipCode }) => {
      dispatch(setPayerName(name));
      dispatch(setPayerEmail(email || undefined));
      dispatch(setDeliveryEmails({ email }));
      dispatch(
        setPayerPhone(
          mobile.replace(`+${getCountryCallingCode(countryCode)}`, "") !== "" ? mobile : undefined,
        ),
      );

      if (Provider.isMaverick) {
        dispatch(
          setAddressInformation({
            addressLines: address || addressLine2 ? [address, addressLine2] : [],
            state: state || undefined,
            city: city || undefined,
            postcode: zipCode || undefined,
          }),
        );
      }

      history.push(SCREEN_PATHS.RECEIVABLES_DELIVERY_DETAILS);
    },
  });

  return (
    <Page title="Create Customer">
      <form onSubmit={handleSubmit}>
        <ScreenHeader title="Create Customer" />
        <ScreenHeaderSubtitle subtitle={t("Invoice.CreateCustomer.ScreenHeaderSubtitle")} />
        <CustomerDetailsForm
          values={{ ...values, state: customerContact?.shippingAddress?.state }}
          touched={touched}
          errors={errors}
          countryCode={countryCode}
          setCountryCode={setCountryCode}
          handleBlur={handleBlur}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
        />
        <FooterActionsButtons
          backButtonText="Back to Customers"
          handleBackButton={() => history.goBack()}
          disabledContinueButton={!isValid || !values.name}
          typeButtonContinue="submit"
          continueButtonText="Continue"
        />
      </form>
    </Page>
  );
};

export default CreateCustomer;
