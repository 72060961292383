import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import InputMask from "react-input-mask";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Box, Card, CardHeader, Divider, Grid } from "@mui/material";
import { TFunction, useTranslation } from "react-i18next";

import {
  CommonTextField,
  FooterActionsButtons,
  InvoiceTotal,
  InvoiceDetails,
  NumberFormatCustom,
} from "@APP/components";
import { BankAccountExtended, Payable } from "@APP/types";
import {
  getPayable,
  getUser,
  setBankAccountData,
  setSupplierName,
  useAppDispatch,
} from "@APP/redux";
import { SCREEN_PATHS } from "@APP/navigation";
import { getOnlyDigits } from "@APP/utils";
import CONFIG from "@APP/config";

const SORT_CODE_LENGTH = 6;
const ACCOUNT_NUMBER_LENGTH = 8;

const beneficiaryValidationSchema = (
  bankAccounts: BankAccountExtended[] | null = [],
  t: TFunction,
) =>
  Yup.object().shape({
    supplierName: Yup.string().required(
      t("Errors.OutboundPayments.Validation.SupplierInvoiceNameRequired"),
    ),
    sortCode: Yup.string()
      .required(t("Errors.OutboundPayments.Validation.SortCodeInvoiceRequired"))
      .test(
        "sort code",
        t("Errors.OutboundPayments.Validation.SortCodeLength"),
        (value: string = "") => value.replaceAll("-", "").length === 6,
      ),
    accountNumber: Yup.string()
      .required(t("Errors.OutboundPayments.Validation.AccountNumberInvoiceRequired"))
      .test(
        "account number",
        t("Errors.OutboundPayments.Validation.AccountNumberLength"),
        (value: string = "") =>
          value && bankAccounts?.every((account) => account.bankInfo.provider === "moneyhub")
            ? value.length === ACCOUNT_NUMBER_LENGTH &&
              value.match(`[0-9]{${ACCOUNT_NUMBER_LENGTH}}`) != null
            : true,
      ),
  });

type Props = {
  payable: Payable | null;
};

const MoneyhubPayableDetailsForm = ({ payable }: Props) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { bankAccountData } = useSelector(getPayable);
  const { t } = useTranslation();

  const user = useSelector(getUser);

  const bankSortCode = payable?.supplierContact.bankDetails?.identification?.substring(
    0,
    SORT_CODE_LENGTH,
  );
  const bankAccountNumber = payable?.supplierContact.bankDetails?.identification?.substring(
    SORT_CODE_LENGTH,
    15,
  );

  const handleBackNavigation = () => history.push(SCREEN_PATHS.PAYABLES_LIST);

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    isValid,
    touched,
    values,
    dirty,
  } = useFormik({
    initialValues: {
      supplierName: payable?.supplierContact.name || "",
      sortCode: bankAccountData?.sortCode || bankSortCode || "",
      accountNumber: bankAccountData?.accountNumber || bankAccountNumber || "",
    },
    enableReinitialize: true,
    validationSchema: beneficiaryValidationSchema(user?.bankAccounts, t),
    onSubmit: ({ supplierName, sortCode, accountNumber }) => {
      dispatch(setBankAccountData({ sortCode, accountNumber }));
      dispatch(setSupplierName(supplierName));

      history.push(SCREEN_PATHS.PAYABLE_SELECT_ACCOUNT);
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={6}>
        <Grid item lg={6} md={6} xs={12}>
          <Card elevation={12}>
            <CardHeader title="Beneficiary" data-testid="beneficiary-card-title" />
            <Divider />
            <Box display="flex" flexDirection="column" padding={[1, 2, 2]}>
              <CommonTextField
                label={t("Payables.PayableInvoiceDetails.SupplierName.Label")}
                disabled={isSubmitting}
                error={Boolean(touched.supplierName && errors.supplierName)}
                helperText={touched.supplierName && errors.supplierName}
                margin="normal"
                name="supplierName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.supplierName}
                data-testid="supplier-name-text-field"
              />
              <InputMask
                name="sortCode"
                value={getOnlyDigits(values.sortCode)}
                mask={CONFIG.INPUTS.BANK_SORT_CODE_MASK ?? ""}
                maskPlaceholder=""
                disabled={isSubmitting}
                onChange={handleChange}
                onBlur={handleBlur}
                data-testid="sort-code-input">
                <CommonTextField
                  label={t("Payables.PayableInvoiceDetails.AccountSortCode.Label")}
                  error={Boolean(touched.sortCode && errors.sortCode)}
                  helperText={touched.sortCode && errors.sortCode}
                  margin="normal"
                  name="sortCode"
                  data-testid="sort-code-text-field"
                />
              </InputMask>
              <NumberFormatCustom
                label={t("Payables.PayableInvoiceDetails.AccountNumber.Label")}
                type="text"
                disabled={isSubmitting}
                fullWidth
                margin="normal"
                value={getOnlyDigits(values.accountNumber)}
                allowNegative={false}
                decimalScale={0}
                name="accountNumber"
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.accountNumber && errors.accountNumber)}
                helperText={touched.accountNumber && errors.accountNumber}
                inputProps={{
                  "data-testid": "bank-account-number-text-field",
                  maxLength: ACCOUNT_NUMBER_LENGTH,
                }}
                variant="outlined"
              />
            </Box>
          </Card>
        </Grid>
        <Grid item lg={6} md={6} xs={12}>
          <InvoiceDetails dueDateTime={payable?.dueDateTime} reference={payable?.reference} />
        </Grid>
        <Grid item lg={12} xs={12}>
          <InvoiceTotal
            totalAmountTaxExclusive={payable?.totalAmountTaxExclusive.amount}
            totalAmountTaxInclusive={payable?.totalAmountTaxInclusive.amount}
            lineItems={payable?.lineItems}
            currency={payable?.totalAmountTaxExclusive.currency}
            isDisplayingHeader
          />
        </Grid>
      </Grid>
      <FooterActionsButtons
        backButtonText="Back to Invoices"
        handleBackButton={handleBackNavigation}
        continueButtonText="Continue"
        typeButtonContinue="submit"
        disabledContinueButton={
          !isValid || (!dirty && !values.sortCode?.length && !values.accountNumber?.length)
        }
        continueButtonDataTestId="invoice-continue-btn"
        backButtonDataTestId="back-to-invoices-btn"
      />
    </form>
  );
};

export default MoneyhubPayableDetailsForm;
