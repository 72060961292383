import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { IconWithTooltip } from "@APP/components";

const useStyles = makeStyles((theme) => ({
  requirementsList: {
    marginTop: theme.spacing(0),
    paddingLeft: theme.spacing(2),
  },
}));

const PASSWORD_REQUIREMENTS = [
  "at least 8 characters",
  "an uppercase letter",
  "a lowercase letter",
  "a number",
  "a special character",
];

const PasswordTooltipContent = () => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h5">Your password must include:</Typography>
      <ul className={classes.requirementsList}>
        {PASSWORD_REQUIREMENTS.map((text) => (
          <li key={text}>
            <Typography variant="body2">{text}</Typography>{" "}
          </li>
        ))}
      </ul>
    </>
  );
};

const PasswordTooltip = () => (
  <IconWithTooltip
    title={<PasswordTooltipContent />}
    iconButtonProps={{
      "aria-label": `Your password must include: ${PASSWORD_REQUIREMENTS.join(", ")}`,
    }}
  />
);

export default PasswordTooltip;
