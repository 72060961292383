import { Box, Card, Link, TableCell, TableRow } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ReceiptIcon from "@mui/icons-material/Receipt";

import { ICustomerReceivablesDetails } from "@APP/types";
import { Table } from "@APP/components";
import { formatCurrency, formatDisplayedDate } from "@APP/utils";

type Props = {
  data: Array<ICustomerReceivablesDetails & { failureReason?: string }>;
  handleClickOnCustomerName: (receivableId: string) => () => void;
  emailTitle?: string;
};

const useStyles = makeStyles((theme) => ({
  linkTitle: {
    ...theme.typography.body1,
  },
  tableIcon: {
    marginRight: theme.spacing(1),
  },
  cellWithTextWrap: {
    whiteSpace: "pre-line",
  },
}));

const CustomerReceivablesDetailsTable = ({
  data,
  handleClickOnCustomerName,
  emailTitle,
}: Props) => {
  const classes = useStyles();
  const isFailureReasonExist = data.some((item) => item.failureReason);
  const areUniqueReferencesEmpty = data.every((item) => !item.reference);

  const renderHeader = () => (
    <TableRow>
      <TableCell>Customer Name</TableCell>
      <TableCell>{emailTitle || "Email"}</TableCell>
      <TableCell>Amount</TableCell>
      {!areUniqueReferencesEmpty && <TableCell>Unique Reference</TableCell>}
      <TableCell>Invoice Due Date</TableCell>
      {isFailureReasonExist && <TableCell>Failure Reason</TableCell>}
    </TableRow>
  );

  const renderRows = (item: Props["data"][0]) => (
    <TableRow key={item.reference} hover>
      <TableCell>
        <Box display="flex" alignItems="center">
          <ReceiptIcon className={classes.tableIcon} color="action" />
          <Link
            component="button"
            className={classes.linkTitle}
            onClick={handleClickOnCustomerName(item.id)}>
            {item.name}
          </Link>
        </Box>
      </TableCell>
      <TableCell>{item.email}</TableCell>
      <TableCell>{formatCurrency(item.amount, { currency: item.currency })}</TableCell>
      {!areUniqueReferencesEmpty && <TableCell>{item.reference}</TableCell>}
      <TableCell>{formatDisplayedDate(item.dueDateTime)}</TableCell>
      {isFailureReasonExist && (
        <TableCell className={classes.cellWithTextWrap}>{item.failureReason}</TableCell>
      )}
    </TableRow>
  );

  return (
    <Card elevation={12}>
      <Table
        data={data}
        renderHeader={renderHeader}
        renderRows={renderRows}
        showPagination={false}
      />
    </Card>
  );
};

export default CustomerReceivablesDetailsTable;
