import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { Paper, CardContent, Avatar, Box, Typography, IconButton } from "@mui/material";
import InfoIcon from "@mui/icons-material/InfoRounded";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import makeStyles from "@mui/styles/makeStyles";

import { getBankLedgers, getErpId, getUser } from "@APP/redux";
import { capitalize, formatCurrency, isLinkedLedger } from "@APP/utils";
import { useAlert } from "@APP/hooks";
import { SCREEN_PATHS } from "@APP/navigation";
import { ErpId, TabsName } from "@APP/constants";
import { Custodian, Amount } from "@APP/types";
import clsx from "clsx";

export type LinkedLedgerProps = {
  showLinkedLedgerContent?: boolean;
  showLinkedLedgerAlert?: boolean;
};

export type DisplayedBankData = {
  accountNumber: string;
  accountName: string;
  balance?: Amount;
  bankInfo?: Pick<Custodian, "fullName" | "logo">;
};

type BankAccountTileProps = DisplayedBankData & LinkedLedgerProps;

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: 0,
    "&:last-child": {
      padding: 0,
    },
  },
  accountList: {
    "& p, & span": {
      wordBreak: "break-all",
    },
    display: "flex",
    flexWrap: "wrap",
    padding: theme.spacing(1),
    maxHeight: 278,
    overflow: "auto",
  },
  accountListCentered: {
    justifyContent: "center",
    [theme.breakpoints.down("lg")]: {
      justifyContent: "flex-start",
    },
  },
  accountItemContainer: {
    flexShrink: 0,
    flexGrow: 0,
    flexBasis: "25%",
    [theme.breakpoints.down("xl")]: {
      flexBasis: "33.3333%",
    },
    [theme.breakpoints.down("lg")]: {
      flexBasis: "50%",
    },
    [theme.breakpoints.down("sm")]: {
      flexBasis: "100%",
    },
  },
  accountItem: {
    padding: theme.spacing(1.5, 2),
    height: "90%",
    borderRadius: theme.shape.borderRadius,
    margin: theme.spacing(1),
    boxShadow: theme.shadows[1],
  },
  bankLogo: {
    height: 28,
    width: 28,
    marginRight: theme.spacing(1),
  },
}));

type CommonBankAccountsContentProps = LinkedLedgerProps & {
  bankAccounts: DisplayedBankData[];
};

export const BankAccountsTiled = ({
  bankAccounts,
  showLinkedLedgerContent = false,
  showLinkedLedgerAlert = false,
}: CommonBankAccountsContentProps) => {
  const classes = useStyles();

  return (
    <CardContent className={classes.mainContainer}>
      <Box
        className={clsx(classes.accountList, {
          [classes.accountListCentered]: (bankAccounts?.length || 0) < 3,
        })}>
        {bankAccounts?.map((props) => (
          <BankAccountTile
            key={props.accountNumber}
            showLinkedLedgerContent={showLinkedLedgerContent}
            showLinkedLedgerAlert={showLinkedLedgerAlert}
            {...props}
          />
        ))}
      </Box>
    </CardContent>
  );
};

const BankAccountTile = ({
  accountNumber,
  accountName,
  balance,
  bankInfo,
  showLinkedLedgerContent = false,
  showLinkedLedgerAlert = false,
}: BankAccountTileProps) => {
  const classes = useStyles();
  const user = useSelector(getUser);
  const ERPLedgers = useSelector(getBankLedgers);
  const ERPId = useSelector(getErpId);
  const history = useHistory();
  const alert = useAlert();
  const { t } = useTranslation();

  const showAlertToLinkedLedger = () => {
    alert.open(
      t("Errors.InvoiceCreation.Alerts.APLedgersMissing.Title", {
        ACCOUNTING_PACKAGE: capitalize(user?.erp),
      }),
      t("Errors.InvoiceCreation.Alerts.APLedgersMissing.Message", {
        ACCOUNTING_PACKAGE: capitalize(user?.erp),
      }),
      [
        { text: "Cancel" },
        {
          text: "Select Bank Ledger",
          onClick: () =>
            history.push(`${SCREEN_PATHS.SETTINGS}?tab=${TabsName.ACCOUNTING_PACKAGE}`),
        },
      ],
    );
  };

  const findLedgerDescription = (accountNumber: String) => {
    const ledgerInformation = ERPLedgers.find(
      (ledger) => ledger.bankDetails?.accountNumber === accountNumber,
    );
    return ledgerInformation ? ledgerInformation.description : null;
  };

  return (
    <Box className={classes.accountItemContainer} key={accountNumber}>
      <Paper className={classes.accountItem}>
        <Box display="flex" alignItems="center" mb={1}>
          {bankInfo && (
            <Box
              display="flex"
              alignItems="center"
              data-testid={"bank-name-" + bankInfo.fullName.toLowerCase().replace(/ /g, "-")}>
              <Avatar className={classes.bankLogo} src={bankInfo.logo} alt={bankInfo.fullName}>
                <AccountBalanceIcon fontSize="small" />
              </Avatar>
              <Typography color="primary" variant="h4" title={bankInfo.fullName} component="span">
                {capitalize(bankInfo.fullName)}
              </Typography>
            </Box>
          )}
          {showLinkedLedgerAlert && !isLinkedLedger(ERPLedgers, accountNumber) && bankInfo && (
            <Box marginLeft="auto">
              <IconButton
                size="small"
                onClick={showAlertToLinkedLedger}
                edge="end"
                aria-label={`Your bank ledger from ${capitalize(ERPId)} is missing`}
                aria-haspopup="dialog">
                <InfoIcon fontSize="medium" color="error" />
              </IconButton>
            </Box>
          )}
        </Box>
        {balance && (
          <Box mb={1}>
            <Typography
              color="textPrimary"
              variant="h4"
              component="span"
              data-testid="acc-balance-amount-label">
              {formatCurrency(balance?.amount, {
                currency: balance?.currency,
              })}
            </Typography>
          </Box>
        )}
        <Box mb={1}>
          <Typography data-testid="acc-id-name-label">
            {accountNumber} / {accountName}
          </Typography>
        </Box>
        {showLinkedLedgerContent && user?.erp !== ErpId.INTERNAL && (
          <Box>
            <Typography data-testid="booking-ledger-head-label">
              <b>Corresponding {capitalize(ERPId)} Bank Account</b>
            </Typography>
            <Typography data-testid="booking-ledger-label">
              {isLinkedLedger(ERPLedgers, accountNumber)
                ? `${capitalize(user?.erp)} / ${findLedgerDescription(accountNumber)}`
                : "No linked account"}
            </Typography>
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default BankAccountsTiled;
