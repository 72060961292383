import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { Box, Button, Grid, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import { MessageCard } from "@APP/components";
import { useStyles } from "@APP/views/setup/SetupBankAccountsView/styles";
import { BankConsentLocalData, Custodian, SubscriptionFeatureTypes } from "@APP/types";
import {
  fetchUserData,
  hideLoader,
  showLoader,
  useAppDispatch,
  getUser,
  getSubscription,
} from "@APP/redux";
import { API, AppLocalStorage } from "@APP/services";
import { SCREEN_PATHS } from "@APP/navigation";
import { useAlert, useHandleErrorCodes } from "@APP/hooks";
import { TabsName } from "@APP/constants";
import { useSubscription } from "@APP/hooks";
import CONFIG from "@APP/config";

import YourBankAccounts from "../YourBankAccounts";
import SelectedBanksToLinkBankList from "./SelectedBanksToLinkBankList";

export const SUBSCRIPTION_RESULT_MESSAGES = {
  errorMessageOnUnsubscribe:
    "<br />This service is free of charge to cooperative bank account holders, to activate your free account please deactivate your account and then activate account in your account tab.",
  errorMessageOnSubscribe:
    "<br />This service is free of charge to cooperative bank account holders, please activate your account in your account tab.",
  resubscribeSuccessfullMessage:
    "<br />You will no longer be charged for this service, it is free of charge to cooperative bank account holders.",
};

type Props = {
  onCompleteRegistration: () => void;
};

const LinkMoneyhubBankAccounts = ({ onCompleteRegistration }: Props) => {
  const classes = useStyles();
  const alert = useAlert();
  const { t } = useTranslation();
  const handleErrorCodes = useHandleErrorCodes();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { resubscribeToDefaultSubscription } = useSubscription();
  const subscription = useSelector(getSubscription);

  const [banksList, setBanksList] = useState<Custodian[] | null>(null);
  const [isConsentAuthorised, setIsConsentAuthorised] = useState(false);
  const [provider, setProvider] = useState("");
  const [bankName, setBankName] = useState("");
  const [isShowSelectedBanks, setIsShowSelectedBanks] = useState(false);
  const subscriptionMessage = useRef("");

  const user = useSelector(getUser);

  const hasLinkedAccounts = user?.bankAccounts?.length;
  const { code, error, error_description } = queryString.parse(location.search);

  useEffect(() => {
    /**
     * If a user has landed on the page with consent authorisation code, handles the authorisation.
     * Otherwise, cleans up all any locally stored data that can be left after previous consent initiation
     */
    (async () => {
      try {
        if (code) {
          const response = await processConsentAuthorisation();

          if (!response?.bankName) {
            return;
          }

          if (
            !subscription &&
            location.pathname === SCREEN_PATHS.SETUP_BANK_ACCOUNTS &&
            CONFIG.URLS.REDIRECT_URL_TO_MOBILE
          ) {
            return alert.open(
              "Success",
              `Your ${response?.bankName} bank account(s) have been successfully linked.</br></br> If you would like to add more bank account(s) please select yes, or select no to complete registration.`,
              [{ text: "No", onClick: onCompleteRegistration }, { text: "Yes" }],
            );
          }

          return alert.open(
            "Success",
            `Your ${response?.bankName} bank account(s) have been successfully linked.${subscriptionMessage.current}`,
            [{ text: "Okay" }],
          );
        } else {
          AppLocalStorage.removeItem("setup.bankConsentData");
          if (error) {
            let errorTitle = error;
            let errorDescription =
              (error_description as string) ||
              t("Errors.BankAccountLinking.Alerts.AISAuth.Message");

            if (error === "access_denied" || error === "login_required") {
              errorTitle = t("Errors.BankAccountLinking.Alerts.AuthFail.Title");
              errorDescription = t("Errors.BankAccountLinking.Alerts.AuthFail.Message");
            }

            alert.open(errorTitle as string, errorDescription);
          }
        }
      } catch (error) {
        const errorCode = error?.response?.data?.errorCode;

        const isHandled = handleErrorCodes(errorCode);

        if (!isHandled)
          alert.open(
            t("Errors.Common.Alerts.AlertTitles.Error"),
            t("Errors.Common.Alerts.Generic.Message"),
          );
      }
    })();
  }, [code]);

  /**
   * Authorises bank consent using the local data saved earlier at the stage of consent initiation.
   * Attempts to link bank accounts to a user account.
   */
  const processConsentAuthorisation = async () => {
    subscriptionMessage.current = "";

    const localStorageData = AppLocalStorage.getItem("setup.bankConsentData");
    if (!localStorageData) {
      history.push(
        window.location.pathname === SCREEN_PATHS.SETTINGS
          ? `${SCREEN_PATHS.SETTINGS}?tab=${TabsName.BANK_ACCOUNTS}`
          : window.location.origin + window.location.pathname,
      );

      await getBanksList();

      return;
    }
    const bankConsentLocalData: BankConsentLocalData = JSON.parse(localStorageData || "");
    const { consentId, bankId, bankName, sub } = bankConsentLocalData;

    try {
      dispatch(showLoader());
      const redirectUrl =
        window.location.pathname === SCREEN_PATHS.SETTINGS
          ? window.location.origin + `${SCREEN_PATHS.SETTINGS}?tab=${TabsName.BANK_ACCOUNTS}`
          : window.location.origin + window.location.pathname;
      await API.authorizeBankConsent(bankId, consentId, code as string, sub as string, redirectUrl);
      // Request is needed to run accounts validation for selected bank on the BE side
      await API.getMoneyhubBankAccounts(bankId);

      if (hasLinkedAccounts) {
        setIsConsentAuthorised(true);
      }
      setProvider(provider);
      setBankName(bankName);
      AppLocalStorage.removeItem("setup.bankConsentData");

      /**
       * Resubscribe user in case of plan missmatching
       */
      if (CONFIG.FEATURES.SUBSCRIPTIONS.TYPE === SubscriptionFeatureTypes.Flexible) {
        const resubscriptionResult = await resubscribeToDefaultSubscription();

        if (resubscriptionResult?.errorOnUnsubscribe) {
          subscriptionMessage.current = SUBSCRIPTION_RESULT_MESSAGES.errorMessageOnUnsubscribe;
        }

        if (resubscriptionResult?.errorOnSubscribe) {
          subscriptionMessage.current = SUBSCRIPTION_RESULT_MESSAGES.errorMessageOnSubscribe;
        }

        if (resubscriptionResult?.resubscribedSuccessfully || subscription?.isPlanFree) {
          subscriptionMessage.current = SUBSCRIPTION_RESULT_MESSAGES.resubscribeSuccessfullMessage;
        }
      }

      await dispatch(fetchUserData());
    } catch (error) {
      await getBanksList();
      dispatch(hideLoader());
      throw error;
    }
    await getBanksList();
    dispatch(hideLoader());

    return { bankName };
  };

  useEffect(() => {
    (async () => {
      if (code) return;

      const consentLoading = AppLocalStorage.getItem("setup.bankConsentData");

      if (!consentLoading) dispatch(showLoader());

      await getBanksList();
      if (!consentLoading) {
        dispatch(hideLoader());
      }
    })();
  }, []);

  const getBanksList = async () => {
    try {
      const data = await API.getCustodiansByType("Bank");
      setBanksList(data);

      if (data.length === 0) setIsShowSelectedBanks(true);
    } catch (error) {
      console.log(error);
      setIsShowSelectedBanks(true);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} className={classes.gridBankAccounts}>
        {isConsentAuthorised && provider === "moneyhub" && (
          <Box mb={4}>
            <MessageCard type="info">
              <Typography data-testid="attention-msg-title">ATTENTION</Typography>
              <Typography data-testid="attention-msg-description">
                The process of updating the consent for <b>{bankName}</b>
                &nbsp;bank accounts takes a little longer. You can see the relevant list of linked
                bank accounts later in your user profile settings.
              </Typography>
            </MessageCard>
          </Box>
        )}

        <YourBankAccounts />
      </Grid>
      <Grid item xs={12}>
        <Box mt={1}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => setIsShowSelectedBanks(!isShowSelectedBanks)}>
            <Box
              display="flex"
              justifyContent="space-between"
              width="100%"
              data-testid="select-bank-to-link-button-title"
              onClick={() => setIsShowSelectedBanks(!isShowSelectedBanks)}
              aria-expanded={isShowSelectedBanks}
              aria-controls="expanded-banks-list">
              Select bank to link bank accounts
              {isShowSelectedBanks ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </Box>
          </Button>
          {isShowSelectedBanks && (
            <Box px={2} py={1} id="expanded-banks-list">
              <SelectedBanksToLinkBankList banksList={banksList} />
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default LinkMoneyhubBankAccounts;
