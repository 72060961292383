import { useEffect } from "react";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { useHistory } from "react-router-dom";

import { SCREEN_PATHS } from "@APP/navigation";
import { FooterActionsButtons, Page, ResultNotification, ScreenHeader } from "@APP/components";
import { confirmPayment } from "@APP/services/api";
import {
  getDashboardApp,
  getPayable,
  hideLoader,
  setInitialData,
  showLoader,
  useAppDispatch,
} from "@APP/redux";
import { useHandleErrorCodes } from "@APP/hooks";
import { Provider } from "@APP/constants";

const PayableSuccess = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const handleErrorCodes = useHandleErrorCodes();
  const queryParams = queryString.parse(window.location?.search ?? "");
  const { code, error } = queryParams;

  const { isLoading } = useSelector(getDashboardApp);
  const { paymentInitiation, payerBankAccount } = useSelector(getPayable);

  useEffect(() => {
    const moneyhubPaymentInitiation = () => async () => {
      dispatch(showLoader());

      if (error || !paymentInitiation) {
        history.push(`${SCREEN_PATHS.PAYABLE_FAILURE}/${error}`);
      } else if (!code) {
        history.push(`${SCREEN_PATHS.PAYABLE_FAILURE}/auth_code_missing}`);
      } else {
        try {
          const bankId = payerBankAccount?.bankId.toLowerCase()!;
          const { consentId } = paymentInitiation!;
          await confirmPayment(
            bankId,
            consentId,
            code as string,
            window.location.origin + SCREEN_PATHS.PAYABLE_SUCCESS,
          );

          dispatch(hideLoader());
        } catch (err) {
          dispatch(hideLoader());
          const errorCode = err.response?.data?.errorCode;

          const isHandled = handleErrorCodes(errorCode, { errorType: "using" }, () =>
            history.push(SCREEN_PATHS.PAYABLE_FAILURE),
          );

          if (!isHandled) {
            history.push(SCREEN_PATHS.PAYABLE_FAILURE);
          }
        }
      }
    };

    if (!Provider.isMaverick) {
      moneyhubPaymentInitiation();
    }

    return () => {
      if (!isLoading && !error && (paymentInitiation || Provider.isMaverick))
        dispatch(setInitialData());
    };
  }, []);

  if (isLoading) {
    return null;
  }

  const handleNavigateToDashboard = () => history.push(SCREEN_PATHS.DASHBOARD);
  const handleNavigateToCreateRTP = () => history.push(SCREEN_PATHS.PAYABLES_LIST);

  return (
    <Page title="Supplier Payment">
      <ScreenHeader title="Supplier Payment" />

      <ResultNotification type="success">
        Your supplier payment was successfully processed.
      </ResultNotification>

      <FooterActionsButtons
        backButtonText="Back to Dashboard"
        handleBackButton={handleNavigateToDashboard}
        continueButtonText="Create new supplier payment"
        handleContinue={handleNavigateToCreateRTP}
        continueButtonDataTestId="create-new-supplier-payment-continue-btn"
        backButtonDataTestId="back-to-dashboard-btn"
      />
    </Page>
  );
};

export default PayableSuccess;
