import React from "react";
import { useSelector } from "react-redux";
import { Box, Grid, ListItemText, Paper } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { getClearingLedger, getErpId } from "@APP/redux";
import { capitalize } from "@APP/utils";

import ClearingLedgerContainer from "./ClearingLedgerContainer";

const useStyles = makeStyles((theme) => ({
  clearingLedgerContainerItem: {
    padding: theme.spacing(2, 2),
    margin: theme.spacing(2, 0),
    width: "100%",
    "& .MuiSelect-select:focus": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

const ClearingLedgerView = () => {
  const classes = useStyles();
  const clearingLedger = useSelector(getClearingLedger);
  const erpId = useSelector(getErpId);

  if (!clearingLedger) return null;

  return (
    <ClearingLedgerContainer>
      <Box mx={2}>
        <Paper
          className={classes.clearingLedgerContainerItem}
          component={(props) => <Grid container alignItems="center" component="tr" {...props} />}>
          <Grid item xs={6} md={7} component="td">
            <Grid container>
              <Grid item xs={12} md={7}>
                <ListItemText
                  primary={
                    <span>
                      <b>Name:</b> {clearingLedger.description}
                    </span>
                  }
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <ListItemText
                  primary={
                    <span>
                      <b>Account number in {capitalize(erpId)}:</b>{" "}
                      {clearingLedger.bankDetails.accountNumber}
                    </span>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </ClearingLedgerContainer>
  );
};

export default ClearingLedgerView;
