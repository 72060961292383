import React from "react";
import { useTranslation } from "react-i18next";
import Receipt from "@mui/icons-material/Receipt";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import AccountBalanceWallet from "@mui/icons-material/AccountBalanceWallet";
import { Box, Typography } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import { useSelector } from "react-redux";

import CONFIG from "@APP/config";
import { getErpId } from "@APP/redux";
import { ErpId } from "@APP/constants";

const AvailableFeaturesOnlyForExternalErp: { title: string; icon: React.ElementType }[] = [
  {
    icon: Receipt,
    title: "Customer Invoices",
  },
  {
    icon: Receipt,
    title: "Supplier Invoices",
  },
  {
    icon: ShowChartIcon,
    title: "Cash Flow Forecast",
  },
  {
    icon: AccountBalanceWallet,
    title: "Payment Requests Management",
  },
];

const AccountingPackageSetupHeader = ({
  headerText,
  error,
}: {
  headerText: string;
  error?: string | (string | null)[] | null;
}) => {
  const erpId = useSelector(getErpId);
  const { t } = useTranslation();

  const internalOrNoErpSelected = erpId === ErpId.INTERNAL || erpId === ErpId.NO_ERP;

  return (
    <Box textAlign="center" p={3}>
      <Box mb={2}>
        <LinkIcon sx={{ fontSize: 120 }} color="primary" />
      </Box>
      <Typography
        align="center"
        color="textPrimary"
        variant="h4"
        gutterBottom
        data-testid="setup-your-accounting-package-title">
        {headerText}
      </Typography>
      <Box mt={3}>
        <Typography
          align="center"
          color="textSecondary"
          variant="body2"
          data-testid="please-select-an-accounting-package-text">
          {error === "access_denied"
            ? t("Setup.SetupAccountingPackage.SelectAccountingPackageErrorText")
            : t("Setup.SetupAccountingPackage.SelectAccountingPackageText")}
        </Typography>
        {(!error || internalOrNoErpSelected) && CONFIG.FEATURES.MULTI_CHANNEL && (
          <Typography
            align="center"
            color="textSecondary"
            variant="body2"
            data-testid="please-select-an-accounting-package-text">
            After you link your accounting package, you will get access to the following features in{" "}
            {CONFIG.PRODUCT_NAME} web app:
          </Typography>
        )}
      </Box>
      {(!error || internalOrNoErpSelected) && CONFIG.FEATURES.MULTI_CHANNEL && (
        <>
          <Box mt={3} display="flex" justifyContent="space-between">
            {AvailableFeaturesOnlyForExternalErp.map(({ icon: Icon, title }) => (
              <Box
                key={title}
                px={1.5}
                display="flex"
                alignItems="center"
                flexDirection="column"
                width="25%">
                <Icon aria-label={`${title} icon`} color="primary" fontSize="large" />
                <Typography variant="caption">{title}</Typography>
              </Box>
            ))}
          </Box>
        </>
      )}
    </Box>
  );
};

export default AccountingPackageSetupHeader;
