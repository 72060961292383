import APP_LOGO from "./logo.svg";
import STORE_ICON from "./storeIcon.svg";
import GOOGLE_PLAY from "./storeBadges/google-play-badge.svg";
import IOS_APP_STORE from "./storeBadges/app-store-badge.svg";

interface Assets {
  IMAGES: {
    APP_LOGO: string;
    STORE_ICON: string;
    GOOGLE_PLAY: string;
    IOS_APP_STORE: string;
  };
}

export const IMAGES: Assets["IMAGES"] = {
  APP_LOGO,
  STORE_ICON,
  GOOGLE_PLAY,
  IOS_APP_STORE,
};

export default {
  IMAGES,
} as Assets;
