import { NavLink as RouterLink } from "react-router-dom";
import clsx from "clsx";
import { Button, ListItem, Tooltip } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

interface Props {
  className?: string;
  href: string;
  icon?: React.ElementType;
  title: string;
  isFullMenu: boolean;
  disabled?: boolean;
  onClick?: (e: React.SyntheticEvent) => void;
}

const useStyles = makeStyles((theme) => ({
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: theme.spacing(2),
  },
  title: {
    marginLeft: theme.spacing(1),
    marginRight: "auto",
  },
  active: {
    backgroundColor: theme.palette.action.active,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.action.active,
    },
  },
}));

const NavItem = ({
  className,
  href,
  icon: Icon,
  title,
  isFullMenu,
  onClick,
  disabled = false,
  ...rest
}: Props) => {
  const classes = useStyles();

  return (
    <ListItem className={clsx(classes.item, className)} disableGutters {...rest}>
      <Tooltip title={title} placement="right" disableHoverListener={isFullMenu}>
        <Button
          color="primary"
          variant="text"
          aria-label={`navigate to ${title}`}
          fullWidth
          activeClassName={classes.active}
          component={RouterLink}
          onClick={onClick}
          disabled={disabled}
          to={href}>
          {Icon && <Icon size="20" aria-label={`${title} icon`} />}
          {isFullMenu && <span className={classes.title}>{title}</span>}
        </Button>
      </Tooltip>
    </ListItem>
  );
};

export default NavItem;
