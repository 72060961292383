import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { Invoice } from "@APP/types";
import { formatDisplayedDate } from "@APP/utils";

type Props = Partial<Pick<Invoice, "reference" | "dueDateTime" | "dateTimeIssued">>;

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    height: "100%",
  },
  divider: {
    marginTop: 18,
    marginBottom: 10,
  },
  tableCell: {
    border: "none",
    paddingBottom: theme.spacing(0),
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
  smallCell: {
    width: 160,
  },
}));

const InvoiceDetails = ({ reference, dueDateTime, dateTimeIssued }: Props) => {
  const classes = useStyles();

  return (
    <Card className={classes.cardContainer} elevation={12}>
      <CardHeader title="Invoice details" />
      <Divider />
      <CardContent>
        <Table>
          <caption className="visuallyHidden">Invoice details table</caption>
          <TableBody>
            {reference && (
              <TableRow>
                <TableCell
                  className={clsx(classes.tableCell, classes.smallCell)}
                  component="th"
                  scope="row">
                  <Typography variant="h6" component="p">
                    Unique Reference
                  </Typography>
                </TableCell>
                <TableCell className={classes.tableCell}>{reference}</TableCell>
              </TableRow>
            )}
            {dateTimeIssued && (
              <TableRow>
                <TableCell
                  className={clsx(classes.tableCell, classes.smallCell)}
                  component="th"
                  scope="row">
                  <Typography variant="h6" component="p">
                    Invoice Issue Date
                  </Typography>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {formatDisplayedDate(dateTimeIssued)}
                </TableCell>
              </TableRow>
            )}
            {dueDateTime && (
              <TableRow>
                <TableCell
                  className={clsx(classes.tableCell, classes.smallCell)}
                  component="th"
                  scope="row">
                  <Typography variant="h6" component="p">
                    Invoice Due Date
                  </Typography>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {formatDisplayedDate(dueDateTime)}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default InvoiceDetails;
