import { Box, Button, Typography } from "@mui/material";

interface Props {
  errorMessage: string;
  onTryAgain?: () => void;
}

const ErrorContent = ({ onTryAgain, errorMessage }: Props) => (
  <Box
    minHeight={150}
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    textAlign="center">
    <Typography variant="body1">{errorMessage}</Typography>
    {onTryAgain && (
      <Box mt={3}>
        <Button color="primary" variant="contained" onClick={() => onTryAgain()}>
          Try Again
        </Button>
      </Box>
    )}
  </Box>
);

export default ErrorContent;
