import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  fetchUserData,
  getBankAccounts,
  getErpId,
  getUser,
  logout,
  useAppDispatch,
} from "@APP/redux";
import { AppRouter, getRoutes, SCREEN_PATHS } from "@APP/navigation";
import { AppLoader } from "@APP/components";
import { AppLocalStorage, AppStorageKey } from "@APP/services";
import { ErpId } from "@APP/constants";
import { useHandleErrorCodes } from "@APP/hooks";
import { BANK_CONSENT_EXPIRED_ERROR_CODE } from "@APP/services/api";

export const RootComponent = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const handleErrorCodes = useHandleErrorCodes();
  const [appReady, setAppReady] = useState(false);
  const erpId = useSelector(getErpId);
  const bankAccounts = useSelector(getBankAccounts);
  const routes = getRoutes(bankAccounts || [], erpId!);
  const user = useSelector(getUser);

  const localStorageHandler = useCallback((e: StorageEvent) => {
    const appStoreKey = e.key as AppStorageKey;
    if (appStoreKey === "auth.token" && !e.newValue) {
      dispatch(logout());
    }
  }, []);

  /**
   * Reloads page in case it's taken from bfcache
   */
  const onPageShowHandler = useCallback((e: PageTransitionEvent) => {
    if (e.persisted) {
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    (async () => {
      window.addEventListener("storage", localStorageHandler);
      window.addEventListener("pageshow", onPageShowHandler);

      try {
        setAppReady(false);
        const authToken = AppLocalStorage.getItem("auth.token");

        if (authToken && !user) await dispatch(fetchUserData());

        if (window.location.pathname === SCREEN_PATHS.APP_ERROR) {
          history.replace(SCREEN_PATHS.DASHBOARD);
        }
      } catch (error) {
        const errorCode = error.response?.data?.errorCode;

        const isHandled = handleErrorCodes(errorCode, {
          errorCodes: [BANK_CONSENT_EXPIRED_ERROR_CODE],
        });

        if (!isHandled && AppLocalStorage.getItem("auth.token")) {
          history.push(SCREEN_PATHS.APP_ERROR);
        }
      } finally {
        setAppReady(true);
      }
    })();

    return () => {
      window.removeEventListener("storage", localStorageHandler);
      window.removeEventListener("pageshow", onPageShowHandler);
    };
  }, []);

  useEffect(() => {
    if (
      window.location.pathname === SCREEN_PATHS.SETUP_ACCOUNTING_PACKAGE &&
      !window.location.search &&
      erpId &&
      erpId !== ErpId.INTERNAL
    ) {
      history.push(SCREEN_PATHS.DASHBOARD);
    }
  }, [erpId]);

  return <>{appReady ? <AppRouter routes={routes} /> : <AppLoader />}</>;
};

export default RootComponent;
