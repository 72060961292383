import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from "@mui/material";
import { FormikErrors, FormikTouched } from "formik/dist/types";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";

import { CommonTextField, IconWithTooltip, NumberFormatCustom } from "@APP/components";
import {
  capitalize,
  formatCurrency,
  getCurrencySymbol,
  getPriceInNumberFormat,
  handleAriaActiveDescendantChange,
} from "@APP/utils";
import { ExternalLedger, LineItemType, ProductOrService, VatRateLineItem } from "@APP/types";
import { getInvoice, getUser } from "@APP/redux";
import { ErpId } from "@APP/constants";

import { MAX_LINE_ITEM_AMOUNT_INCLUDING_VAT } from "../InvoiceLineItemsView";

type Props = {
  lineItem: LineItemType;
  numberOfItem: number;
  showDeleteIcon: boolean;
  taxRateInfoMessage: string;
  removeItemFromLineItems: (numberOfItem: number) => void;
  paymentAccounts: { label: string; value: ExternalLedger }[] | null;
  vatRates: VatRateLineItem[] | null;
  vatSelectable?: boolean;
  productsOrServices?: ProductOrService[] | null;
  handleChange: (e: React.ChangeEvent) => void;
  handleBlur: (e: React.FocusEvent) => void;
  errors: FormikErrors<{ lineItems: LineItemType[] }>;
  touched: FormikTouched<{ lineItems: LineItemType[] }>;
  handleChangeVatRate: (e: React.ChangeEvent) => void;
  handleChangeExternalLedger: (e: React.ChangeEvent) => void;
  handleProductOrServiceChange?: (e: React.ChangeEvent) => void;
  handleChangeQuantity: (e: React.ChangeEvent) => void;
  handleChangeUnitPrice: (e: React.ChangeEvent) => void;
};

const MAX_LENGTH_OF_UNIT_PRICE = 10;
const LineItemForm = ({
  numberOfItem,
  lineItem,
  removeItemFromLineItems,
  showDeleteIcon,
  paymentAccounts,
  vatRates,
  productsOrServices,
  handleBlur,
  handleChange,
  errors,
  touched,
  handleChangeVatRate,
  handleChangeExternalLedger,
  handleProductOrServiceChange,
  vatSelectable,
  handleChangeQuantity,
  handleChangeUnitPrice,
  taxRateInfoMessage,
}: Props) => {
  const { t } = useTranslation();
  const { invoiceCurrency } = useSelector(getInvoice);
  const user = useSelector(getUser);

  const getFieldError = (name: keyof LineItemType) => {
    return (
      errors?.lineItems &&
      errors.lineItems[numberOfItem] &&
      (errors.lineItems[numberOfItem] as LineItemType)[name]
    );
  };

  const getFieldTouched = (name: keyof LineItemType) => {
    return (
      touched?.lineItems && touched.lineItems[numberOfItem] && touched.lineItems[numberOfItem][name]
    );
  };

  const getPickerVatRateError = () => {
    const picketVatRateError = getFieldError("pickerVatRate");
    const picketVatRateTouched = getFieldTouched("pickerVatRate");

    if (picketVatRateError && picketVatRateTouched) {
      return picketVatRateTouched && (picketVatRateError as VatRateLineItem).value;
    }
  };

  const amount = useMemo(() => {
    if (!lineItem.quantity || !lineItem.unitPrice) {
      return "0.00";
    }

    const amount: number =
      +lineItem.quantity.replaceAll(",", "") *
      getPriceInNumberFormat(lineItem.unitPrice, invoiceCurrency);

    return isNaN(amount)
      ? "0.00"
      : formatCurrency(amount.toString(), { currency: invoiceCurrency });
  }, [lineItem.quantity, lineItem.unitPrice]);

  const amountIncludingVAT = useMemo(() => {
    return getPriceInNumberFormat(amount, invoiceCurrency);
  }, [amount, lineItem.pickerVatRate]);

  const getMaxLengthInDigitsField = (maxLength: number) => {
    if (!lineItem || !lineItem.unitPrice) return maxLength;

    return (
      lineItem.unitPrice.length +
      (maxLength -
        getPriceInNumberFormat(lineItem.unitPrice, invoiceCurrency).toString().replaceAll(".", "")
          .length)
    );
  };

  const renderVatRatesAdditionalMenuItems = () => {
    if (!vatRates) {
      return (
        <MenuItem disabled>{t("Invoice.AddInvoiceLineItems.UnableToRetrieveVatMessage")}</MenuItem>
      );
    }

    if (!vatRates.length) {
      return (
        <MenuItem disabled value="" style={{ whiteSpace: "pre-line" }}>
          {t("Invoice.AddInvoiceLineItems.EmptyListOfVats", { erp: capitalize(user?.erp) })}
        </MenuItem>
      );
    }

    if (vatSelectable) {
      return <MenuItem value="">None</MenuItem>;
    }

    return null;
  };

  const renderProductServicesAdditionalMenuItems = () => {
    if (!productsOrServices) {
      return (
        <MenuItem disabled>
          We were unable to retrieve your products/services from your accounting package. Please try
          again later.
        </MenuItem>
      );
    }

    if (!productsOrServices.length) {
      return (
        <MenuItem disabled value="">
          We haven’t found any product/service in your accounting package.
        </MenuItem>
      );
    }

    return <MenuItem value="">None</MenuItem>;
  };

  const renderAccountAdditionalMenuItems = () => {
    if (!paymentAccounts) {
      return (
        <MenuItem disabled>
          {t("Invoice.AddInvoiceLineItems.UnableToRetrieveLedgerMessage")}
        </MenuItem>
      );
    }

    if (!paymentAccounts.length) {
      return (
        <MenuItem disabled value="" style={{ whiteSpace: "pre-line" }}>
          {`We haven’t found any appropriate ledger in your accounting package. Please set it up first in ${capitalize(
            user?.erp,
          )} and complete this step once that is done.`}
        </MenuItem>
      );
    }

    return null;
  };

  return (
    <Card elevation={12}>
      <CardHeader
        title={
          <Grid container>
            <Grid item xs={10}>
              <Typography variant="h5">Line Item #{numberOfItem + 1}</Typography>
            </Grid>
            <Grid item xs={2}>
              {showDeleteIcon && (
                <Box display="flex" justifyContent="flex-end">
                  <IconButton
                    className="lineItemButton"
                    size="small"
                    color="primary"
                    onClick={() => removeItemFromLineItems(numberOfItem)}
                    aria-label="delete line item">
                    <DeleteIcon />
                  </IconButton>
                </Box>
              )}
            </Grid>
          </Grid>
        }
      />
      <Divider />
      <CardContent>
        <CommonTextField
          fullWidth
          select
          label="Product/Service (Optional)"
          id={`lineItems[${numberOfItem}].productService-select`}
          data-testid="product-service-select"
          name={`lineItems[${numberOfItem}].productService`}
          margin="normal"
          value={lineItem.productService?.details.externalId || ""}
          onChange={handleProductOrServiceChange}
          onBlur={handleBlur}
          variant="outlined"
          inputProps={{
            id: `product-service-${numberOfItem}-field`,
          }}
          InputLabelProps={{
            htmlFor: `product-service-${numberOfItem}-field`,
          }}
          SelectProps={{
            MenuProps: {
              MenuListProps: {
                "aria-activedescendant": `product-service-option-${lineItem.productService?.details.externalId}-${numberOfItem}`,
                onFocus: handleAriaActiveDescendantChange,
              },
            },
          }}>
          {renderProductServicesAdditionalMenuItems()}
          {productsOrServices?.map(({ details: { externalId }, name }) => (
            <MenuItem
              key={externalId}
              id={`product-services-option-${externalId}-${numberOfItem}`}
              value={externalId || ""}>
              {name || ""}
            </MenuItem>
          ))}
        </CommonTextField>
        <CommonTextField
          placeholder="Description"
          label="Description"
          fullWidth
          name={`lineItems[${numberOfItem}].description`}
          margin="normal"
          value={lineItem.description}
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean(getFieldError("description") && getFieldTouched("description"))}
          helperText={
            (getFieldTouched("description") &&
              getFieldError("description") &&
              (getFieldError("description") as string)) ||
            undefined
          }
          inputProps={{ "data-testid": "description-input", maxLength: 2000 }}
        />
        {user?.erp !== ErpId.QUICKBOOKS && (
          <CommonTextField
            fullWidth
            select
            label="Account"
            id={`lineItems[${numberOfItem}].externalLedger-select`}
            data-testid="external-ledger-select"
            name={`lineItems[${numberOfItem}].externalLedger`}
            margin="normal"
            value={lineItem.externalLedger?.id! || ""}
            onChange={handleChangeExternalLedger}
            onBlur={handleBlur}
            error={Boolean(getFieldError("externalLedger") && getFieldTouched("externalLedger"))}
            helperText={
              (getFieldTouched("externalLedger") &&
                getFieldError("externalLedger") &&
                (getFieldError("externalLedger") as string)) ||
              undefined
            }
            variant="outlined"
            inputProps={{
              id: `account-${numberOfItem}-field`,
            }}
            InputLabelProps={{
              htmlFor: `account-${numberOfItem}-field`,
            }}
            SelectProps={{
              MenuProps: {
                MenuListProps: {
                  "aria-activedescendant": `external-ledger-option-${lineItem.externalLedger?.id}-${numberOfItem}`,
                  onFocus: handleAriaActiveDescendantChange,
                },
              },
            }}>
            {renderAccountAdditionalMenuItems()}
            {paymentAccounts?.map(({ label, value }) => (
              <MenuItem
                key={value?.id}
                id={`external-ledger-option-${value?.id}-${numberOfItem}`}
                value={value?.id! || ""}>
                {label || ""}
              </MenuItem>
            ))}
          </CommonTextField>
        )}
        <NumberFormatCustom
          placeholder="Quantity"
          label="Quantity"
          fullWidth
          type="text"
          margin="normal"
          value={lineItem.quantity}
          name={`lineItems[${numberOfItem}].quantity`}
          thousandSeparator={true}
          onChange={handleChangeQuantity}
          allowNegative={false}
          onBlur={handleBlur}
          decimalScale={0}
          error={Boolean(getFieldError("quantity") && getFieldTouched("quantity"))}
          helperText={
            ((getFieldTouched("quantity") &&
              getFieldError("quantity") &&
              getFieldError("quantity")) as string) || undefined
          }
          inputProps={{ "data-testid": "quantity-input", maxLength: 13 }}
          variant="outlined"
        />
        <NumberFormatCustom
          placeholder="Unit Price"
          label="Unit Price"
          type="text"
          fullWidth
          margin="normal"
          value={lineItem.unitPrice}
          name={`lineItems[${numberOfItem}].unitPrice`}
          prefix={getCurrencySymbol(invoiceCurrency)}
          onChange={handleChangeUnitPrice}
          onBlur={handleBlur}
          decimalScale={2}
          allowNegative={false}
          thousandSeparator={true}
          error={Boolean(getFieldError("unitPrice") && getFieldTouched("unitPrice"))}
          helperText={
            (getFieldTouched("unitPrice") &&
              typeof getFieldError("unitPrice") === "string" &&
              (getFieldError("unitPrice") as string)) ||
            undefined
          }
          inputProps={{
            "data-testid": "unit-price-input",
            maxLength: getMaxLengthInDigitsField(MAX_LENGTH_OF_UNIT_PRICE),
          }}
          variant="outlined"
        />
        <Box display="flex">
          <CommonTextField
            fullWidth
            select
            label={
              vatSelectable ? `${t("Invoice.VatRateLabel")} (Optional)` : t("Invoice.VatRateLabel")
            }
            id="vat-rate-select"
            data-testid="vat-rate-select"
            name={`lineItems[${numberOfItem}].pickerVatRate.value`}
            margin="normal"
            value={lineItem.pickerVatRate?.value || ""}
            onChange={handleChangeVatRate}
            onBlur={handleBlur}
            error={Boolean(getPickerVatRateError())}
            helperText={getPickerVatRateError()}
            variant="outlined"
            inputProps={{
              id: `vat-rate-${numberOfItem}-field`,
            }}
            InputLabelProps={{
              htmlFor: `vat-rate-${numberOfItem}-field`,
            }}
            SelectProps={{
              MenuProps: {
                MenuListProps: {
                  "aria-activedescendant": `line-item-vat-option-${lineItem.pickerVatRate?.requestValue}-${numberOfItem}`,
                  onFocus: handleAriaActiveDescendantChange,
                },
              },
            }}>
            {renderVatRatesAdditionalMenuItems()}
            {vatRates?.map((vatRate) => (
              <MenuItem
                key={vatRate.requestValue}
                id={`line-item-vat-option-${vatRate.requestValue}-${numberOfItem}`}
                value={vatRate?.value || ""}>
                {vatRate.label || ""}
              </MenuItem>
            ))}
          </CommonTextField>
          {taxRateInfoMessage && (
            <Box
              display="flex"
              justifyContent="center"
              mt={2}
              data-testid="tooltip-match-acc-not-found">
              <IconWithTooltip
                title={taxRateInfoMessage}
                infoIconSize="medium"
                color="primary"
                placement="bottom"
              />
            </Box>
          )}
        </Box>
        <Box mt={2} textAlign="right">
          <Typography variant="h5">
            {t("Invoice.AmountOfLineItemLabel")}: {amount}
          </Typography>
          {amountIncludingVAT > MAX_LINE_ITEM_AMOUNT_INCLUDING_VAT && (
            <Typography variant="caption" color="error">
              {t("Invoice.AddInvoiceLineItems.LineItemCannotExceedMessage")}
            </Typography>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default React.memo(LineItemForm);
