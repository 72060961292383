import * as Yup from "yup";
import { TFunction } from "i18next";
import { ObjectShape } from "yup/lib/object";

import { getPriceInNumberFormat } from "@APP/utils/helpers";

export const validationSchema = ({
  t,
  currency,
  vatRateOptional,
  validateAccount,
}: {
  t: TFunction;
  currency: string;
  vatRateOptional: boolean;
  validateAccount: boolean;
}) => {
  let lineItemSchemaObject: ObjectShape = {
    description: Yup.string().required(
      t("Errors.InvoiceCreation.Validation.LineItemDescriptionRequired"),
    ),
    quantity: Yup.string()
      .required(t("Errors.InvoiceCreation.Validation.LineItemQuantityRequired"))
      .test(
        "Check on zero in number field",
        t("Errors.InvoiceCreation.Validation.LineItemQuantityRequired"),
        (value = "") => {
          return Number(value) !== 0;
        },
      ),
    unitPrice: Yup.string()
      .required(t("Errors.InvoiceCreation.Validation.LineItemUnitPriceRequired"))
      .test(
        "Check on zero in number field",
        t("Errors.InvoiceCreation.Validation.LineItemUnitPriceNotZero"),
        (value = "") => {
          return getPriceInNumberFormat(value, currency) !== 0;
        },
      )
      .test(
        "Check on value more 0.01",
        t("Errors.InvoiceCreation.Validation.LineItemUnitPriceMore"),
        (value = "") => getPriceInNumberFormat(value, currency) > 0.01,
      ),
    pickerVatRate: Yup.object().shape({
      numericalValue: Yup.number(),
      requestValue: Yup.string(),
      label: Yup.string(),
      value: Yup.string().required(t("Invoice.AddInvoiceLineItems.VatRateRequiredMessage")),
    }),
  };

  if (!validateAccount) {
    lineItemSchemaObject = {
      ...lineItemSchemaObject,
      externalLedger: Yup.object()
        .shape({
          id: Yup.string(),
          description: Yup.string(),
          code: Yup.string(),
        })
        .nullable()
        .required(t("Errors.InvoiceCreation.Validation.LineItemAccountRequired")),
    };
  }

  if (vatRateOptional) {
    return Yup.object().shape({
      lineItems: Yup.array().of(
        Yup.object().shape({ ...lineItemSchemaObject, pickerVatRate: Yup.object() }),
      ),
    });
  }

  return Yup.object().shape({
    lineItems: Yup.array().of(Yup.object().shape(lineItemSchemaObject)),
  });
};
