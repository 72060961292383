import { createActionCreator } from "deox";
import { ResourceId, LineItemType, Receivable, Address } from "@APP/types";

export const setPayerName = createActionCreator(
  "@INVOICE/SET_PAYER_NAME",
  (resolve) => (payerName: string) => resolve(payerName),
);

export const setPayerEmail = createActionCreator(
  "@INVOICE/SET_PAYER_EMAIL",
  (resolve) => (payerEmail?: string) => resolve(payerEmail),
);

export const setInvoiceIssueDateTime = createActionCreator(
  "@INVOICE/SET_INVOICE_ISSUE_DATE_TIME",
  (resolve) => (issueDateTime: Date) => resolve(issueDateTime),
);

export const setInvoiceDueDateTime = createActionCreator(
  "@INVOICE/SET_INVOICE_DUE_DATE_TIME",
  (resolve) => (dueDateTime: Date) => resolve(dueDateTime),
);

export const setInvoiceCurrency = createActionCreator(
  "@INVOICE/SET_INVOICE_CURRENCY",
  (resolve) => (currency: string) => resolve(currency),
);

export const setPayerPhone = createActionCreator(
  "@INVOICE/SET_PAYER_Phone",
  (resolve) => (payerName?: string) => resolve(payerName),
);

export const setInvoiceTotalAmount = createActionCreator(
  "INVOICE/SET_INVOICE_TOTAL_AMOUNT",
  (resolve) => (totalAmount: number) => resolve(totalAmount),
);

export const setInvoiceAmountGross = createActionCreator(
  "INVOICE/SET_INVOICE_AMOUNT_GROSS",
  (resolve) => (invoiceAmountGross: number) => resolve(invoiceAmountGross),
);

export const setInvoiceLineItems = createActionCreator(
  "INVOICE/SET_INVOICE_LINE_ITEMS",
  (resolve) => (lineItems: LineItemType[]) => resolve(lineItems),
);

export const setDefaultInvoiceState = createActionCreator(
  "INVOICE/SET_DEFAULT_INVOICE_STATE",
  (resolve) => () => resolve(),
);

export const setPayerId = createActionCreator(
  "INVOICE/SET_PAYER_ID",
  (resolve) => (payerId: ResourceId | undefined) => resolve(payerId),
);

export const setCreatedReceivable = createActionCreator(
  "INVOICE/SET_RECEIVABLE_FOR_INVOICE_CREATED",
  (resolve) => (receivable: Receivable) => resolve(receivable),
);

export const setAddressInformation = createActionCreator(
  "INVOICE/SET_ADDRESS_INFORMATION",
  (resolve) => (addressInformation: Address) => resolve(addressInformation),
);
