import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TableBody,
  TableHead,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import InfoIcon from "@mui/icons-material/InfoRounded";
import ArrowBackIosIcon from "@mui/icons-material/ChevronLeft";
import { useTranslation } from "react-i18next";

import {
  getBankLedgers,
  getDashboardApp,
  getUser,
  hideLoader,
  setBankLedgers,
  showLoader,
  useAppDispatch,
} from "@APP/redux";
import { MessageCard, NoteBox } from "@APP/components";
import {
  BankAccountExtended,
  ErpBanksAccounts,
  LedgerType,
  Ledger,
  SubscriptionFeatureTypes,
} from "@APP/types";
import { useAlert, useHandleErrorCodes } from "@APP/hooks";
import {
  createLinkExternalDefaultLedger,
  deleteLinkExternalDefaultLedger,
  fetchLinkedERPLedgers,
  getERPBankAccounts,
  updateLinkExternalDefaultLedger,
} from "@APP/services/api";
import { capitalize } from "@APP/utils";
import { ErpId } from "@APP/constants";
import BankAccountWithSelectableLedger from "./BankAccountWithSelectableLedger";
import useSubscription from "@APP/hooks/useSubscription";
import { SCREEN_PATHS } from "@APP/navigation";
import CONFIG from "@APP/config";

export type BankAccountExtendedWithLedger = BankAccountExtended & {
  ledgerAccount?: ErpBanksAccounts;
};

export const NO_LINKED_BANK_ACCOUNT_VALUE = "no-linked-bank-account";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  setLedgerButton: {
    marginTop: theme.spacing(3),
  },
}));

const getCheckForMatchingLedgerAndBankAccount = (
  erp: ErpId,
  { erpBankAccount, ledgerAccount }: { erpBankAccount: ErpBanksAccounts; ledgerAccount: Ledger },
) => {
  if (erp === ErpId.SAGE) {
    return erpBankAccount?.accountId === ledgerAccount?.additionalAccountId;
  }

  return erpBankAccount?.accountId === ledgerAccount?.ledgerId;
};

const ALREADY_LINKED_LEDGER_ACCOUNT_ERROR_CODE = 6917;

const SetLedgerForPaymentsBooking = () => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const alert = useAlert();
  const history = useHistory();
  const subscription = useSubscription();
  const { t } = useTranslation();
  const isDisplaySizeLessLaptop = useMediaQuery(theme.breakpoints.down("md"));
  const [erpBanksAccounts, setErpBanksAccounts] = useState<ErpBanksAccounts[]>([]);
  const [erpBanksAccountError, setErpBanksAccountError] = useState(false);
  const [banksAccounts, setBanksAccounts] = useState<BankAccountExtendedWithLedger[]>([]);
  const [localLoading, setLocalLoading] = useState(true);
  const handleErrorCodes = useHandleErrorCodes();

  const user = useSelector(getUser);
  const { isLoading } = useSelector(getDashboardApp);
  const ERPLedgers = useSelector(getBankLedgers);

  const isDefaultLedgerLinked =
    ERPLedgers.length === 1 &&
    !ERPLedgers[0].bankDetails &&
    ERPLedgers[0].ledgerType === LedgerType.Bank;

  const getListERPBankAccounts = async () => {
    try {
      if (!isLoading) dispatch(showLoader());

      const { data } = await getERPBankAccounts(user?.erp!);
      setErpBanksAccounts(data);
      setErpBanksAccountError(false);
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      const errorData = error?.response?.data;
      const isHandled = handleErrorCodes(errorData?.errorCode);

      if (isHandled) return;

      setErpBanksAccountError(true);
      alert.open(
        t("Errors.Common.Alerts.AlertTitles.Failure"),
        t("Errors.AccountingPackageLinking.Alerts.RetrieveLedgerAccounts.Message"),
        [
          { text: "Cancel", onClick: () => history.goBack() },
          { text: "Try again", onClick: async () => await getListERPBankAccounts() },
        ],
      );
    }
    setLocalLoading(false);
  };

  const updateErpList = async () => {
    try {
      if (user && user.erp && user.erp !== ErpId.INTERNAL) {
        const { data } = await fetchLinkedERPLedgers(user.erp);
        if (data) {
          dispatch(setBankLedgers(data));
        }
      }
    } catch (error) {
      const errorData = error?.response?.data;

      handleErrorCodes(errorData?.errorCode);
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    (async () => {
      dispatch(showLoader());
      await updateErpList();
      await getListERPBankAccounts();
    })();
  }, []);

  useEffect(() => {
    setBanksAccounts(
      user?.bankAccounts?.map((bankAccount) => {
        const currentLedger = ERPLedgers.find(
          (ledger) => ledger.bankDetails?.accountNumber === bankAccount.account.identification,
        );

        if (ERPLedgers.length === 0 && erpBanksAccounts.length === 1) {
          return {
            ...bankAccount,
            ledgerAccount: erpBanksAccounts[0],
          };
        }

        return {
          ...bankAccount,
          ledgerAccount: currentLedger
            ? erpBanksAccounts.find((erpBankAccount) =>
                getCheckForMatchingLedgerAndBankAccount(user.erp!, {
                  erpBankAccount,
                  ledgerAccount: currentLedger,
                }),
              )
            : erpBanksAccounts.find(
                (erpBankAccount) =>
                  erpBankAccount.accountNumber === bankAccount.account.identification.slice(0, 14),
              ),
        };
      }) || [],
    );
  }, [erpBanksAccounts, ERPLedgers]);

  const updateBankAccountLedger = (
    bankAccount: BankAccountExtendedWithLedger,
    ledgerValue: ErpBanksAccounts | typeof NO_LINKED_BANK_ACCOUNT_VALUE,
  ) => {
    setBanksAccounts(
      banksAccounts.map((account) => ({
        ...account,
        ledgerAccount:
          account.accountId === bankAccount.accountId
            ? ledgerValue === NO_LINKED_BANK_ACCOUNT_VALUE
              ? { ...account.ledgerAccount!, accountId: NO_LINKED_BANK_ACCOUNT_VALUE }
              : ledgerValue
            : account.ledgerAccount!,
      })),
    );
  };

  const handleChangeLedger =
    (bankAccount: BankAccountExtendedWithLedger) =>
    (event: React.ChangeEvent<{ value: unknown | typeof NO_LINKED_BANK_ACCOUNT_VALUE }>) => {
      const value = event.target.value as string;

      if (value === NO_LINKED_BANK_ACCOUNT_VALUE) {
        updateBankAccountLedger(bankAccount, NO_LINKED_BANK_ACCOUNT_VALUE);

        return;
      }

      const selectedErpBankAccount = erpBanksAccounts.find((bank) => bank.accountId === value);

      if (!selectedErpBankAccount) return;

      if (
        selectedErpBankAccount.accountNumber !== bankAccount.account.identification.slice(0, 14)
      ) {
        return alert.open(
          t("Errors.AccountingPackageLinking.Alerts.LinkingLedger.Title", {
            ERP: capitalize(user?.erp),
          }),
          t("Errors.AccountingPackageLinking.Alerts.LinkingLedger.Message", {
            ERP: capitalize(user?.erp),
          }),

          [
            { text: "Cancel" },
            {
              text: "Yes",
              onClick: () => updateBankAccountLedger(bankAccount, selectedErpBankAccount),
            },
          ],
        );
      } else if (selectedErpBankAccount.accountNumber === bankAccount.account.identification) {
        updateBankAccountLedger(bankAccount, selectedErpBankAccount);
      }
    };

  const handleClickFinish = async () => {
    try {
      await updateOrCreateLinkedLedgers();
      if (CONFIG.FEATURES.SUBSCRIPTIONS.TYPE !== SubscriptionFeatureTypes.None) {
        return subscription.subscribeToDefaultPlan();
      }
    } catch (e) {
      alert.open(
        t("Errors.AccountingPackageLinking.Alerts.AddLedgers.Title"),
        t("Errors.AccountingPackageLinking.Alerts.AddLedgers.Message"),
        [{ text: "Okay" }, { text: "Try again", onClick: () => handleClickFinish() }],
      );
    }

    try {
      const { data } = await fetchLinkedERPLedgers(user?.erp!);
      dispatch(setBankLedgers(data));
    } finally {
      dispatch(hideLoader());

      history.push(SCREEN_PATHS.REGISTRATION_COMPLETE);
    }
  };

  const isLinkedLedgersChanged = useMemo(() => {
    for (const bank of banksAccounts) {
      const linkedLedger = ERPLedgers.find((ledger) => {
        return ledger.bankDetails?.accountNumber === bank.account.identification;
      });

      if (bank.ledgerAccount?.accountId === NO_LINKED_BANK_ACCOUNT_VALUE && linkedLedger) {
        return true;
      }

      const isSelectedAndCurrentLedgerDifferent = !getCheckForMatchingLedgerAndBankAccount(
        user!.erp!,
        {
          erpBankAccount: bank.ledgerAccount!,
          ledgerAccount: linkedLedger!,
        },
      );

      if (
        isSelectedAndCurrentLedgerDifferent &&
        bank.ledgerAccount &&
        bank.ledgerAccount?.accountId !== NO_LINKED_BANK_ACCOUNT_VALUE
      ) {
        return true;
      }
    }

    return false;
  }, [banksAccounts, ERPLedgers]);

  const updateOrCreateLinkedLedgers = async () => {
    let isUpdatedLedger = false;

    for (const bank of banksAccounts) {
      const linkedLedger = ERPLedgers.find((ledger) => {
        return ledger.bankDetails?.accountNumber === bank.account.identification;
      });

      if (bank.ledgerAccount?.accountId === NO_LINKED_BANK_ACCOUNT_VALUE && linkedLedger) {
        isUpdatedLedger = true;
        await deleteLinkExternalDefaultLedger(user?.erp!, linkedLedger.linkId);
        continue;
      }

      const isSelectedAndCurrentLedgerDifferent = !getCheckForMatchingLedgerAndBankAccount(
        user!.erp!,
        {
          erpBankAccount: bank.ledgerAccount!,
          ledgerAccount: linkedLedger!,
        },
      );

      if (
        isSelectedAndCurrentLedgerDifferent &&
        bank.ledgerAccount &&
        bank.ledgerAccount?.accountId !== NO_LINKED_BANK_ACCOUNT_VALUE
      ) {
        if (!isUpdatedLedger) isUpdatedLedger = true;

        try {
          if (linkedLedger) {
            await updateLinkExternalDefaultLedger(
              user?.erp!,
              bank.ledgerAccount!.accountId,
              bank.account.identification,
              bank.account.schemeName,
              bank.bankId,
            );
          } else {
            await createLinkExternalDefaultLedger(
              user?.erp!,
              bank.ledgerAccount!.accountId,
              bank.account.identification,
              bank.account.schemeName,
              bank.bankId,
            );
          }
        } catch (error) {
          const errorCode = error.response?.data?.errorCode;

          if (errorCode !== ALREADY_LINKED_LEDGER_ACCOUNT_ERROR_CODE) {
            throw error;
          }
        }
      }
    }

    return isUpdatedLedger;
  };

  const saveUpdates = async () => {
    dispatch(showLoader());

    try {
      const isUpdatedLedger = await updateOrCreateLinkedLedgers();

      dispatch(hideLoader());

      if (isUpdatedLedger) {
        const { data } = await fetchLinkedERPLedgers(user?.erp!);
        dispatch(setBankLedgers(data));
        return alert.open(
          `Your ${capitalize(user?.erp)} bank accounts successfully updated.`,
          `You have successfully updated the links to your ${capitalize(user?.erp)} bank accounts.`,
          [{ text: "Okay" }],
        );
      } else {
        return;
      }
    } catch (e) {
      dispatch(hideLoader());

      alert.open(
        t("Errors.AccountingPackageLinking.Alerts.UpdateAP.Title", { ERP: capitalize(user?.erp) }),
        t("Errors.AccountingPackageLinking.Alerts.UpdateAP.Message", {
          ERP: capitalize(user?.erp),
        }),

        [{ text: "Okay" }, { text: "Try again", onClick: () => saveUpdates() }],
      );
    }
  };

  const unlinkDefaultLedger = async () => {
    try {
      dispatch(showLoader());

      await deleteLinkExternalDefaultLedger(user?.erp!, ERPLedgers[0].linkId);

      dispatch(setBankLedgers([]));
    } catch (error) {
      alert.open(
        t("Errors.Common.Alerts.AlertTitles.Failure"),
        t("Errors.AccountingPackageLinking.Alerts.UnlinkDefaultLedger.Message"),
        [{ text: "Okay" }],
      );
    }

    dispatch(hideLoader());
  };

  const renderLinkingLedgerContent = () => {
    if (isLoading && localLoading) {
      return null;
    } else if (!erpBanksAccounts.length) {
      return (
        <Box mt={3}>
          <MessageCard type="info">
            <Box display="flex" alignItems="center" height="100%">
              <Typography
                variant="subtitle1"
                component="p"
                data-testid="warning-no-bank-account-text">
                {t("Errors.AccountingPackageLinking.Messages.NoBanks", {
                  ERP: capitalize(user?.erp),
                })}
              </Typography>
            </Box>
          </MessageCard>
        </Box>
      );
    }

    if (isDefaultLedgerLinked) {
      return (
        <Box mb={1} mt={3}>
          <Card elevation={4}>
            <Box p={2}>
              <Box display="flex">
                <Box mr={1} display="flex">
                  <InfoIcon color="primary" />
                </Box>
                <Box mt={0.4}>
                  <Typography variant="body2">
                    Your {capitalize(user?.erp)} bank ledger is set to ‘{ERPLedgers[0].description}
                    ’.
                  </Typography>
                  <Box mt={1}>
                    <Typography variant="h6" component="p">
                      Do you want to set the Bank Ledgers from your accounting package that match
                      the bank accounts in this app for each bank account you have?
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Card>
        </Box>
      );
    }

    return (
      <Box component="table" role="table" width="100%">
        <caption className="visuallyHidden">
          Table displaying your linked bank accounts and the associated bank accounts from your
          accounting package
        </caption>
        <TableHead>
          <Grid container component="tr" mt={4}>
            <Grid item xs={6} md={7} component="th" align="left">
              <Typography
                variant={isDisplaySizeLessLaptop ? "h5" : "h4"}
                component="p"
                data-testid="linked-bank-accounts-label">
                {t(
                  "Settings.AccountingPackage.SetLedgerForPaymentsBooking.LinkedAccountsColumnHeader",
                )}
              </Typography>
            </Grid>
            <Grid item xs={6} md={5} component="th" align="left">
              <Typography
                variant={isDisplaySizeLessLaptop ? "h5" : "h4"}
                component="p"
                id="bank-accounts-label">
                {t(
                  "Settings.AccountingPackage.SetLedgerForPaymentsBooking.AccountsFromErpColumnHeader",
                )}
              </Typography>
            </Grid>
          </Grid>
        </TableHead>
        <TableBody>
          {banksAccounts.map((bankAccount) => {
            return (
              <BankAccountWithSelectableLedger
                key={bankAccount.accountId}
                bankAccount={bankAccount}
                handleChangeLedger={handleChangeLedger}
                erpBanksAccounts={erpBanksAccounts}
              />
            );
          })}
        </TableBody>
      </Box>
    );
  };

  return (
    <Card elevation={4} className={classes.root}>
      <CardHeader
        title={t("Settings.AccountingPackage.SetLedgerForPaymentsBooking.CardTitle")}
        subheader={t("Settings.AccountingPackage.SetLedgerForPaymentsBooking.CardSubheader", {
          productName: t("ProductName"),
        })}
      />
      <Divider />
      <CardContent>
        <NoteBox>
          {t("Settings.AccountingPackage.SetLedgerForPaymentsBooking.NoteBox", {
            erp: capitalize(user?.erp),
          })}
        </NoteBox>
        {!erpBanksAccountError && renderLinkingLedgerContent()}
      </CardContent>
      {!erpBanksAccountError && (
        <>
          {window.location.pathname.includes(SCREEN_PATHS.SETTINGS) ? (
            <CardActions>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                data-testid="footer-button-save"
                disabled={isDefaultLedgerLinked ? false : !isLinkedLedgersChanged}
                onClick={isDefaultLedgerLinked ? unlinkDefaultLedger : saveUpdates}>
                {isDefaultLedgerLinked ? "Confirm" : "Save"}
              </Button>
            </CardActions>
          ) : (
            <Box p={2}>
              <Box mb={2}>
                <Button
                  color="primary"
                  fullWidth
                  type="button"
                  variant="contained"
                  onClick={handleClickFinish}>
                  Finish
                </Button>
              </Box>
              <Button
                onClick={() => history.push(SCREEN_PATHS.SETUP_BANK_ACCOUNTS)}
                color="primary"
                fullWidth
                startIcon={<ArrowBackIosIcon />}
                type="button"
                variant="outlined">
                Back
              </Button>
            </Box>
          )}
        </>
      )}
    </Card>
  );
};

export default SetLedgerForPaymentsBooking;
