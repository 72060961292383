import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { Box, Container, Button, Card, Typography } from "@mui/material";
import EmailIcon from "@mui/icons-material/MailOutline";
import CheckIcon from "@mui/icons-material/Check";
import makeStyles from "@mui/styles/makeStyles";

import { Page } from "@APP/components";
import { fetchUserData, getUser, useAppDispatch } from "@APP/redux";
import { SCREEN_PATHS } from "@APP/navigation";
import { API } from "@APP/services";
import { ErpId, TabsName } from "@APP/constants";
import { formatErrorMessage } from "@APP/utils";
import { useAlert } from "@APP/hooks";
import CONFIG from "@APP/config";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100%",
    paddingTop: theme.spacing(6),
  },
  icon: {
    fontSize: 120,
    marginBottom: theme.spacing(2),
  },
  paragraph: {
    marginBottom: theme.spacing(2),
    "& b": {
      color: theme.palette.text.primary,
    },
  },
  email: {
    wordBreak: "break-all",
  },
}));

const SetupAccountingPackageView = () => {
  const classes = useStyles();
  const history = useHistory();
  const alert = useAlert();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const user = useSelector(getUser);

  const [emailVerified, setEmailVerified] = useState(false);
  const [emailVerificationLoading, setEmailVerificationLoading] = useState(false);
  const [emailResendLoading, setEmailResendLoading] = useState(false);
  const [emailResendRequested, setEmailResendRequested] = useState(false);
  const [verificationCheckFailed, setVerificationCheckFailed] = useState(false);

  const isAccountingPackageLinked = user?.erp && user?.erp !== ErpId.INTERNAL;
  const skipAccountingPackageLinking =
    !CONFIG.FEATURES.SETUP.LINKING_ACCOUNTING_PACKAGE && !user?.org;

  const handleResend = async () => {
    setEmailResendLoading(true);
    setVerificationCheckFailed(false);
    if (!emailResendRequested) {
      try {
        await API.sendVerificationEmail(user?.username ?? "");
        setEmailResendRequested(true);
      } catch (err) {
        alert.open(t("Errors.Common.Alerts.AlertTitles.Error"), formatErrorMessage(err));
      }
    }
    setEmailResendLoading(false);
  };

  const handleVerificationCheck = async () => {
    setEmailVerificationLoading(true);
    setVerificationCheckFailed(false);
    setEmailResendRequested(false);
    try {
      const userData = await dispatch(fetchUserData());
      setEmailVerified(userData?.emailVerified);
      setVerificationCheckFailed(!userData?.emailVerified);
    } catch (err) {
      console.log(err);
      alert.open(t("Errors.Common.Alerts.AlertTitles.Error"), formatErrorMessage(err));
    }
    setEmailVerificationLoading(false);
  };

  const handleNavigateToNextPage = () => {
    if (!user?.emailVerified) {
      return;
    }

    let path = SCREEN_PATHS.DASHBOARD;

    if (skipAccountingPackageLinking) {
      path = SCREEN_PATHS.COMPANY_TYPE_VIEW;
    } else if (isAccountingPackageLinked) {
      path = SCREEN_PATHS.SETUP_ACCOUNTING_PACKAGE;
    }

    history.replace(path);
  };

  useEffect(() => {
    handleNavigateToNextPage();
  }, []);

  return (
    <Page className={classes.root} title="Email Verification Required">
      <Container maxWidth="sm">
        <Card elevation={4}>
          <Box p={3} textAlign="center">
            {emailVerified ? (
              <>
                <CheckIcon className={classes.icon} color="secondary" />
                <Typography
                  className={classes.paragraph}
                  align="center"
                  color="textPrimary"
                  variant="h3">
                  Email Verification Successful.
                </Typography>
                <Typography
                  className={classes.paragraph}
                  align="center"
                  color="textSecondary"
                  variant="body2">
                  Your email address has been verified successfully.
                </Typography>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  textAlign="center"
                  mt={3}>
                  <Box mb={2}>
                    <Button
                      color="secondary"
                      variant="contained"
                      component={RouterLink}
                      replace
                      to={
                        skipAccountingPackageLinking
                          ? SCREEN_PATHS.COMPANY_TYPE_VIEW
                          : isAccountingPackageLinked
                          ? SCREEN_PATHS.DASHBOARD
                          : `${SCREEN_PATHS.SETTINGS}?tab=${TabsName.ACCOUNTING_PACKAGE}`
                      }>
                      Continue
                    </Button>
                  </Box>
                </Box>
              </>
            ) : (
              <>
                <EmailIcon className={classes.icon} color="primary" />
                <Typography
                  className={classes.paragraph}
                  align="center"
                  color="textPrimary"
                  variant="h3"
                  data-testid="email-required-title">
                  Email Verification Required.
                </Typography>
                <Typography
                  className={classes.paragraph}
                  align="center"
                  color="textSecondary"
                  variant="body2"
                  data-testid="email-required-text-content">
                  Your email address hasn’t been verified yet. <br />
                  Please click on the link in the email to verify it.
                </Typography>
                <Typography
                  className={classes.paragraph}
                  align="center"
                  color="textSecondary"
                  variant="body2"
                  data-testid="email-sent-text-content">
                  The verification email has been sent to the below email address: <br />
                  <b className={classes.email}>{user?.username}</b>
                </Typography>
                {verificationCheckFailed && (
                  <Typography
                    color="primary"
                    variant="subtitle2"
                    data-testid="email-not-verified-text-content"
                    gutterBottom>
                    Your email address is not verified, please check your inbox and try again.
                  </Typography>
                )}
                {emailResendRequested && (
                  <Typography
                    color="secondary"
                    variant="subtitle2"
                    data-testid="resend-text-content"
                    gutterBottom>
                    We have resent the verification email, please check your mailbox.
                  </Typography>
                )}
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  textAlign="center"
                  mt={3}>
                  <Box mb={2}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={handleVerificationCheck}
                      disabled={emailVerificationLoading || emailResendLoading}
                      data-testid="check-verification-status-button">
                      Check Verification Status Again
                    </Button>
                  </Box>
                  <Button
                    onClick={handleResend}
                    color="primary"
                    disabled={emailVerificationLoading || emailResendLoading}
                    data-testid="resend-email-button">
                    Resend Email
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </Card>
      </Container>
    </Page>
  );
};

export default SetupAccountingPackageView;
