import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import queryString from "query-string";

import { SCREEN_PATHS } from "@APP/navigation";
import { FooterActionsButtons, Page, ResultNotification, ScreenHeader } from "@APP/components";
import { getMakePayment, hideLoader, setDefaultMakePaymentState, useAppDispatch } from "@APP/redux";
import { confirmPayment } from "@APP/services/api";
import { useHandleErrorCodes } from "@APP/hooks";

const SuccessfulMakePayment = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const handleErrorCodes = useHandleErrorCodes();
  const queryParams = queryString.parse(window.location?.search ?? "");
  const { error, code } = queryParams;

  const { paymentInitiation, selectedPayerAccount } = useSelector(getMakePayment);

  useEffect(() => {
    (async () => {
      if (error || !paymentInitiation) {
        return history.push(`${SCREEN_PATHS.MAKE_PAYMENT_FAILURE}/${error}`);
      } else if (!code) {
        return history.push(`${SCREEN_PATHS.MAKE_PAYMENT_FAILURE}/auth_code_missing`);
      } else {
        try {
          const bankId = selectedPayerAccount?.bankId.toLowerCase()!;
          const { consentId } = paymentInitiation!;
          await confirmPayment(
            bankId,
            consentId,
            code as string,
            window.location.origin + SCREEN_PATHS.MAKE_PAYMENT_SUCCESS,
          );

          dispatch(setDefaultMakePaymentState());
          dispatch(hideLoader());
        } catch (err) {
          dispatch(hideLoader());
          const errorCode = err.response?.data?.errorCode;

          const isHandled = handleErrorCodes(errorCode, { errorType: "using" }, () =>
            history.push(SCREEN_PATHS.MAKE_PAYMENT_FAILURE),
          );

          if (!isHandled) {
            history.push(SCREEN_PATHS.MAKE_PAYMENT_FAILURE);
          }
        }
      }
    })();
  }, []);

  const handleNavigateToDashboard = () => history.push(SCREEN_PATHS.DASHBOARD);
  const handleNavigateToMakePayment = () => history.push(SCREEN_PATHS.MAKE_PAYMENT);

  return (
    <Page title="Make Payment">
      <ScreenHeader title="Make Payment" />
      <ResultNotification type="success">
        Your payment was successfully processed.
      </ResultNotification>
      <FooterActionsButtons
        backButtonText="Back to Dashboard"
        handleBackButton={handleNavigateToDashboard}
        continueButtonText="Create new Payment"
        handleContinue={handleNavigateToMakePayment}
      />
    </Page>
  );
};

export default SuccessfulMakePayment;
