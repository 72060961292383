import { useTranslation } from "react-i18next";
import { Box, CardContent, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import LinkOffIcon from "@mui/icons-material/LinkOff";

const useStyles = makeStyles(() => ({
  linkOffIcon: {
    fontSize: 100,
  },
}));

const NoLinkedBankAccountsContent = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <CardContent>
      <Box alignItems="center" display="flex" flexDirection="column">
        <LinkOffIcon className={classes.linkOffIcon} color="disabled" />
        <Typography
          variant="body2"
          align="center"
          gutterBottom
          data-testid="please-link-bank-accounts-you-want-text">
          {t("CommonViews.NoLinkedBankAccountsContent")}
        </Typography>
      </Box>
    </CardContent>
  );
};

export default NoLinkedBankAccountsContent;
