import React from "react";
import { Box, Card, CardContent, Link, Typography } from "@mui/material";

import CONFIG from "@APP/config";

const MoneyhubFooter = () => (
  <Card elevation={4}>
    <CardContent>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography variant="h6" align="center">
          Moneyhub Financial Technology Limited (Company No. 06909772) is authorised and regulated
          by the Financial Conduct Authority (FCA) as a Payments Initiation Service Provider,
          reference no. 809360.
        </Typography>
        <Box ml={2} mt={2}>
          <ul>
            <li>
              <Link
                href={CONFIG.URLS.PAYMENT_PROVIDER.TERMS_AND_CONDITIONS_URL}
                target="_blank"
                data-testid="customer-terms-link"
                rel="noopener noreferrer">
                <Typography variant="h6">Terms and Conditions</Typography>
              </Link>
            </li>
            <li>
              <Link
                href={CONFIG.URLS.PAYMENT_PROVIDER.PRIVACY_POLICY}
                target="_blank"
                data-testid="third-party-terms-link"
                rel="noopener noreferrer">
                <Typography variant="h6">Privacy Policy</Typography>
              </Link>
            </li>
          </ul>
        </Box>
      </Box>
    </CardContent>
  </Card>
);

export default MoneyhubFooter;
