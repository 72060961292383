import { createReducer } from "deox";

import {
  setAuthenticated,
  setAuthLoading,
  setCardPaymentApp,
  setBankLedgers,
  setReconsentBanks,
  setUserData,
  setUserSubscription,
  setClearingLedger,
} from "../actions";
import {
  BankAccountExtended,
  Ledger,
  ReconsentData,
  UserInfoData,
  UserSubscription,
  CardPaymentApp,
  ClearingLedger,
} from "@APP/types";
import { ErpId } from "@APP/constants";

export interface AuthState {
  loading: boolean;
  authenticated: boolean;
  user:
    | null
    | (UserInfoData & {
        erp: ErpId | null;
        bankAccounts: BankAccountExtended[] | null;
        subscription: UserSubscription | null;
        cardPaymentApp: CardPaymentApp | null;
      });
  bankLedgers: Ledger[];
  clearingLedger: ClearingLedger | null;
  reconsentBanks: ReconsentData[];
}

const defaultState: AuthState = {
  loading: false,
  authenticated: false,
  user: null,
  bankLedgers: [],
  clearingLedger: null,
  reconsentBanks: [],
};

const authReducer = createReducer(defaultState, (handleAction) => [
  handleAction(setAuthLoading, (state, { payload }) => ({ ...state, loading: payload })),
  handleAction(setAuthenticated, (state, { payload }) => ({ ...state, authenticated: payload })),
  handleAction(setUserData, (state, { payload }) => ({ ...state, user: payload })),
  handleAction(setBankLedgers, (state, { payload }) => ({ ...state, bankLedgers: payload })),
  handleAction(setClearingLedger, (state, { payload }) => ({
    ...state,
    clearingLedger: payload,
  })),
  handleAction(setReconsentBanks, (state, { payload }) => ({
    ...state,
    reconsentBanks: payload,
  })),
  handleAction(setCardPaymentApp, (state, { payload }) => ({
    ...state,
    user: {
      ...state.user!,
      cardPaymentApp: payload,
    },
  })),
  handleAction(setUserSubscription, (state, { payload }) => ({
    ...state,
    user: {
      ...state.user!,
      subscription: payload,
    },
  })),
]);

export default authReducer;
