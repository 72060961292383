import React, { useEffect } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { TFunction, useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { Box, Button, Link, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import queryString from "query-string";

import { SCREEN_PATHS } from "@APP/navigation";
import { AppState, login, logout, resetToDefaultState } from "@APP/redux";
import { formatErrorMessage } from "@APP/utils";
import { useAlert, useReconsentBanks, useOrganisationDetails } from "@APP/hooks";
import { Page, AuthLayout, AuthForm, CommonTextField, PasswordField } from "@APP/components";
import { EMAIL_REGEX, TabsName, Provider } from "@APP/constants";
import { BANK_CONSENT_EXPIRED_ERROR_CODE } from "@APP/services/api";

export interface Props {
  auth: AppState["auth"];
  login: ReturnType<typeof mapDispatchToProps>["login"];
  logout: ReturnType<typeof mapDispatchToProps>["logout"];
  resetToDefaultRegistrationState: ReturnType<
    typeof mapDispatchToProps
  >["resetToDefaultRegistrationState"];
}

const loginValidationSchema = (t: TFunction) =>
  Yup.object().shape({
    email: Yup.string()
      .email(t("Errors.Common.Validation.InvalidEmail"))
      .matches(EMAIL_REGEX, t("Errors.Common.Validation.InvalidEmail"))
      .max(255)
      .nullable()
      .required(t("Errors.Common.Validation.EmailRequired")),
    password: Yup.string()
      .max(255)
      .nullable()
      .required(t("Errors.PreAuth.Validation.PasswordRequired")),
  });

const INCORRECT_CREDENTIAL_MESSAGE_FROM_BACKEND = "No user matching supplied credentials found.";

export const LoginView = ({ auth, login, logout, resetToDefaultRegistrationState }: Props) => {
  const history = useHistory();
  const alert = useAlert();
  const organisationDetailsHook = useOrganisationDetails();
  const { t } = useTranslation();

  const { redirectPath } = queryString.parse(history.location.search ?? "") as {
    redirectPath: string;
  };
  const { checkReconsentRequired } = useReconsentBanks();

  useEffect(() => {
    if (auth.authenticated) logout();
  }, []);

  const handleSignUp = (e: React.SyntheticEvent) => {
    e.preventDefault();
    resetToDefaultRegistrationState();
    history.push(SCREEN_PATHS.REGISTRATION_INFORMATION);
  };

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    touched,
    values,
  } = useFormik({
    initialValues: { email: null, password: null },
    validationSchema: loginValidationSchema(t),
    onSubmit: async ({ email, password }: { email: string | null; password: string | null }) => {
      try {
        if (!email || !password) return;
        await login(email.toLowerCase(), password);
        await organisationDetailsHook.getOrganisationDetails({
          errorCodesExcludedFromHandling: [BANK_CONSENT_EXPIRED_ERROR_CODE],
        });

        if (Provider.isMoneyhub) {
          const isReconsentRequired = await checkReconsentRequired();

          if (isReconsentRequired) {
            return history.replace(`${SCREEN_PATHS.SETTINGS}?tab=${TabsName.CONSENT_EXPIRED}`);
          }
        }

        history.push(
          redirectPath && redirectPath !== SCREEN_PATHS.APP_ERROR
            ? redirectPath
            : SCREEN_PATHS.APP_ROOT,
        );
      } catch (error) {
        const errorMessage = formatErrorMessage(error);
        return alert.open(
          t("Errors.Common.Alerts.AlertTitles.Error"),
          errorMessage === INCORRECT_CREDENTIAL_MESSAGE_FROM_BACKEND
            ? t("Errors.PreAuth.Alerts.Credentials.Message")
            : errorMessage,
        );
      }
    },
  });

  const renderMainContent = () => (
    <AuthForm title="Log in">
      <form onSubmit={handleSubmit} noValidate>
        <CommonTextField
          type="email"
          autoComplete="email"
          disabled={isSubmitting}
          error={Boolean(touched.email && errors.email)}
          fullWidth
          helperText={touched.email && errors.email}
          label="Email Address"
          margin="normal"
          name="email"
          onBlur={handleBlur}
          onChange={handleChange}
          onValueChange={setFieldValue}
          value={values.email}
          inputProps={{ "data-testid": "email-input" }}
          data-testid="email-input-container"
        />
        <PasswordField
          disabled={isSubmitting}
          error={Boolean(touched.password && errors.password)}
          helperText={touched.password && errors.password}
          fullWidth
          label="Password"
          name="password"
          margin="normal"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.password}
          FormHelperTextProps={{ "aria-live": "polite" }}
          inputProps={{ "data-testid": "password-input" }}
          data-testid="password-input-container"
          autoComplete="current-password"
        />
        <Box mt={2}>
          <Button
            color="primary"
            disabled={isSubmitting}
            fullWidth
            size="large"
            type="submit"
            data-testid="submit-button"
            variant="contained">
            Log in
          </Button>
        </Box>
        <Box mt={1} textAlign="center">
          <Button
            component={RouterLink}
            to={SCREEN_PATHS.FORGOT_PASSWORD}
            color="primary"
            data-testid="forgot-password-button">
            Forgot your password?
          </Button>
        </Box>
        <Box mt={4} textAlign="center">
          <Typography variant="h5" component="p">
            Don't have an account?{" "}
            <Link
              onClick={handleSignUp}
              to={SCREEN_PATHS.REGISTRATION_INFORMATION}
              component={RouterLink}
              data-testid="registration-link">
              Get started
            </Link>
          </Typography>
        </Box>
      </form>
    </AuthForm>
  );

  return (
    <Page title="Sign In" display="flex" height="100%" p={0}>
      <AuthLayout mainContent={renderMainContent()} />
    </Page>
  );
};

const mapStateToProps = ({ auth }: AppState) => ({ auth });

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>) => ({
  login: (email: string, password: string) => dispatch(login(email, password)),
  logout: () => dispatch(logout()),
  resetToDefaultRegistrationState: () => dispatch(resetToDefaultState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginView);
