import { ChangeEvent, FocusEvent } from "react";
import { CountryCode } from "libphonenumber-js";
import { FormikErrors, FormikTouched } from "formik/dist/types";
import makeStyles from "@mui/styles/makeStyles";
import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { CommonTextField, IconWithTooltip, PhoneField } from "@APP/components";
import { US_STATES, Provider } from "@APP/constants";
import { Customer } from "@APP/types";

const useStyles = makeStyles((theme) => ({
  card: {
    overflow: "visible",
  },
  fieldsSection: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
    columnGap: theme.spacing(2),
    flexDirection: Provider.isMaverick ? "row" : "column",
    alignItems: Provider.isMaverick ? "flex-start" : "center",
  },
  field: Provider.isMaverick
    ? {
        display: "flex",
        minWidth: "30%",
        flex: 1,
        [theme.breakpoints.down("md")]: {
          minWidth: "100%",
        },
        [theme.breakpoints.down("sm")]: {
          minWidth: "100%",
        },
      }
    : {
        display: "flex",
        width: "50%",
        [theme.breakpoints.down("md")]: {
          minWidth: "100%",
          width: "100%",
        },
        [theme.breakpoints.down("sm")]: {
          minWidth: "100%",
          width: "100%",
        },
      },
}));

type Props = {
  values: Customer;
  formEditable?: boolean;
  touched?: FormikTouched<Customer>;
  errors?: FormikErrors<Customer>;
  countryCode?: CountryCode;
  setCountryCode?: (value: CountryCode) => void;
  handleBlur?: (e: FocusEvent) => void;
  handleChange?: (e: ChangeEvent) => void;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
};

const CustomerDetailsForm = ({
  values,
  formEditable = true,
  touched,
  errors,
  handleBlur,
  handleChange,
  setFieldValue,
  countryCode,
  setCountryCode,
}: Props) => {
  const classes = useStyles();
  const theme = useTheme();

  const isSizeScreenUpMd = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Card elevation={12} className={classes.card}>
      <CardContent>
        <Typography variant="h5" data-testid="contact-details-title">
          Contact Details
        </Typography>
        <Box className={classes.fieldsSection} mb={Provider.isMaverick ? 4 : 0}>
          <CommonTextField
            placeholder="Name"
            label="Name"
            margin="normal"
            name="name"
            fullWidth
            className={classes.field}
            value={values.name}
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched?.name && errors?.name)}
            helperText={touched?.name && errors?.name}
            inputProps={{
              readOnly: !formEditable,
              disabled: !formEditable,
              "data-testid": "name-input",
            }}
            data-testid="name-input-container"
          />
          <CommonTextField
            placeholder="Email (Optional)"
            label="Email (Optional)"
            margin="normal"
            name="email"
            type="email"
            fullWidth
            className={classes.field}
            value={values.email}
            onBlur={handleBlur}
            onChange={handleChange}
            onValueChange={setFieldValue}
            error={Boolean(touched?.email && errors?.email)}
            helperText={touched?.email && errors?.email}
            inputProps={{
              readOnly: !formEditable,
              disabled: !formEditable,
              "data-testid": "email-input",
            }}
            data-testid="email-input-container"
          />
          <PhoneField
            name="phone"
            label="Mobile Telephone Number (Optional)"
            containerClassName={classes.field}
            onValueChange={(value) => setFieldValue && setFieldValue("mobile", value)}
            onBlur={handleBlur}
            fullWidth
            margin="normal"
            value={values.mobile}
            error={Boolean(touched?.mobile && errors?.mobile)}
            helperText={touched?.mobile && errors?.mobile}
            setCountryCode={setCountryCode}
            countryCode={countryCode}
            variant="outlined"
            dataTestId="phone-input"
            inputProps={{ disabled: !formEditable }}
            readOnly={!formEditable}
          />
        </Box>
        {Provider.isMaverick && (
          <>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Box mr={1}>
                <Typography variant="h5" data-testid="contact-details-title">
                  Address Information
                </Typography>
              </Box>
              {formEditable && (
                <IconWithTooltip
                  color="primary"
                  placement="bottom"
                  title="The physical address of the customer you're invoicing may impact which sales tax rate is applied to the invoice line item of the created invoice."
                />
              )}
            </Box>
            <Box className={classes.fieldsSection}>
              <CommonTextField
                placeholder="Address (Optional)"
                label="Address (Optional)"
                margin="normal"
                name="address"
                className={classes.field}
                fullWidth
                value={values.address}
                onBlur={handleBlur}
                onChange={handleChange}
                onValueChange={setFieldValue}
                error={Boolean(touched?.address && errors?.address)}
                helperText={touched?.address && errors?.address}
                inputProps={{
                  readOnly: !formEditable,
                  disabled: !formEditable,
                  "data-testid": "address-input",
                }}
                data-testid="address-input-container"
              />
              <CommonTextField
                placeholder="Address Line 2 (Optional)"
                label="Address Line 2 (Optional)"
                margin="normal"
                name="addressLine2"
                className={classes.field}
                fullWidth
                value={values.addressLine2}
                onBlur={handleBlur}
                onChange={handleChange}
                onValueChange={setFieldValue}
                error={Boolean(touched?.addressLine2 && errors?.addressLine2)}
                helperText={touched?.addressLine2 && errors?.addressLine2}
                inputProps={{
                  readOnly: !formEditable,
                  disabled: !formEditable,
                  "data-testid": "address-line2-input",
                }}
                data-testid="address-line2-input-container"
              />
              {formEditable ? (
                <Autocomplete
                  disablePortal
                  id="state-box"
                  fullWidth
                  defaultValue={values.state}
                  sx={{ marginBottom: 0 }}
                  className={classes.field}
                  onChange={(e, value) => setFieldValue && setFieldValue("state", value)}
                  filterOptions={(options, state) =>
                    options.filter((option) =>
                      option.toLowerCase().startsWith(state.inputValue.toLowerCase()),
                    )
                  }
                  options={US_STATES}
                  onBlur={handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="State (Optional)"
                      margin="normal"
                      name="state"
                      value={values.state}
                      style={{ marginBottom: 0 }}
                    />
                  )}
                />
              ) : (
                <CommonTextField
                  placeholder="State (Optional)"
                  label="State (Optional)"
                  margin="normal"
                  value={values.state}
                  fullWidth
                  className={classes.field}
                  inputProps={{ readOnly: true, disabled: true }}
                  data-testid="state-input-container"
                />
              )}
              <CommonTextField
                placeholder="City (Optional)"
                label="City (Optional)"
                margin="normal"
                name="city"
                fullWidth
                className={classes.field}
                value={values.city}
                onBlur={handleBlur}
                onChange={handleChange}
                onValueChange={setFieldValue}
                error={Boolean(touched?.city && errors?.city)}
                helperText={touched?.city && errors?.city}
                inputProps={{
                  readOnly: !formEditable,
                  disabled: !formEditable,
                  "data-testid": "city-input",
                }}
                data-testid="city-input-container"
              />
              <CommonTextField
                placeholder="Zip Code (Optional)"
                label="Zip Code (Optional)"
                margin="normal"
                name="zipCode"
                fullWidth
                className={classes.field}
                value={values.zipCode}
                onBlur={handleBlur}
                onChange={handleChange}
                onValueChange={setFieldValue}
                error={Boolean(touched?.zipCode && errors?.zipCode)}
                helperText={touched?.zipCode && errors?.zipCode}
                inputProps={{
                  readOnly: !formEditable,
                  disabled: !formEditable,
                  "data-testid": "zipCode-input",
                }}
                data-testid="zipCode-input-container"
              />
              {isSizeScreenUpMd && <Box className={classes.field} />}
            </Box>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default CustomerDetailsForm;
