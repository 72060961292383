import React from "react";
import { NavLink as RouterLink } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import { isMobile } from "react-device-detect";

import CONFIG from "@APP/config";
import { AppStorePromoSection, AccountingPackageSetupHeader, Page } from "@APP/components";
import { SCREEN_PATHS } from "@APP/navigation";
import { TabsName } from "@APP/constants";

const NoLinkedAccountingPackageView = () => {
  return (
    <Page title="No Linked Accounting Package">
      <Container maxWidth="md">
        <Card elevation={4}>
          <AccountingPackageSetupHeader headerText={`Welcome to ${CONFIG.PRODUCT_NAME} web app`} />
          <Divider />
          <CardActions>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              component={RouterLink}
              data-testid="link-bank-accounts-button"
              to={`${SCREEN_PATHS.SETTINGS}?tab=${TabsName.SETUP_ACCOUNTING_PACKAGE}`}>
              Link Accounting Package
            </Button>
          </CardActions>
        </Card>
        <Box mt={4}>
          <Card>
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center">
              <Grid item xs={12} md={8} lg={6}>
                <Card elevation={4}>
                  {isMobile && (
                    <>
                      <CardContent>
                        <Box textAlign="center">
                          <PhoneIphoneIcon color="primary" sx={{ fontSize: "5rem" }} />
                          <Box my={3}>
                            <Typography color="textPrimary" align="center" variant="h4">
                              Open {CONFIG.PRODUCT_NAME} mobile app
                            </Typography>
                          </Box>
                          <Typography
                            align="center"
                            color="textSecondary"
                            variant="body2"
                            data-testid="please-select-an-accounting-package-text">
                            You can always use your {CONFIG.PRODUCT_NAME} mobile app to create and
                            send payment requests, reduce late payments, manage your cash flow, and
                            automate your financial admin.
                          </Typography>
                        </Box>
                      </CardContent>
                      <Divider />
                    </>
                  )}
                  <CardActions>
                    <AppStorePromoSection showGoToMobileAppButton={isMobile} />
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </Card>
        </Box>
      </Container>
    </Page>
  );
};

export default NoLinkedAccountingPackageView;
