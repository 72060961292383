import { useState } from "react";
import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Pagination from "@mui/material/Pagination";
import { InputProps } from "@mui/material/Input";
import { darken } from "@mui/system/colorManipulator";
import { handleAriaActiveDescendantChange } from "@APP/utils";

const useStyles = makeStyles((theme) => ({
  paginationPanelItem: {
    "& .Mui-selected": {
      backgroundColor: theme.palette.action.active,
      color: theme.palette.secondary.contrastText,
      "&:hover": {
        backgroundColor: darken(theme.palette.action.active, 0.1),
      },
      "&:focus": {
        backgroundColor: darken(theme.palette.action.active, 0.1),
      },
    },
  },
}));

type Props = {
  entries: number;
  rowsPerPageOptions: number[];
  lastPage: number;
  page: number;
  onEntriesChange?: (entries: number) => void;
  onPageChange?: (newPage: number) => void;
  paginationSelectInputProps?: InputProps["inputProps"];
};

const PaginationPanel = ({
  entries,
  onEntriesChange,
  rowsPerPageOptions,
  lastPage,
  page,
  onPageChange,
  paginationSelectInputProps,
}: Props) => {
  const theme = useTheme();
  const classes = useStyles();
  const isDisplaySizeLessLaptop = useMediaQuery(theme.breakpoints.down("md"));

  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  const handleOpen = () => setOpen(true);

  const handleChangeEntries = (event: SelectChangeEvent<{ value: unknown }>) => {
    onEntriesChange?.(Number(event.target.value));
  };

  const changePage = (_0: any, newPage: number) => {
    onPageChange?.(newPage - 1);
  };

  return (
    <Box
      p={2}
      justifyContent="end"
      display="flex"
      flexDirection={isDisplaySizeLessLaptop ? "column" : "row"}
      alignItems="center">
      <Box display="flex" mb={isDisplaySizeLessLaptop ? 1 : 0}>
        <Box alignItems="center" display="flex" mr={1}>
          <Typography
            variant="body1"
            component="label"
            htmlFor={paginationSelectInputProps?.id || "rows-per-page-pagination-input"}
            id="rows-per-page-pagination-label">
            Rows per page:
          </Typography>
        </Box>
        {rowsPerPageOptions.length && (
          <Select
            variant="standard"
            disableUnderline
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            value={entries as any}
            id="rows-per-page-pagination-select"
            renderValue={(value) => value as any}
            inputProps={{
              id: "rows-per-page-pagination-input",
              ...paginationSelectInputProps,
            }}
            MenuProps={{
              MenuListProps: {
                "aria-activedescendant": `rows-per-page-pagination-option-${entries}`,
                onFocus: handleAriaActiveDescendantChange,
              },
            }}
            labelId="rows-per-page-pagination-label"
            onChange={handleChangeEntries}>
            {rowsPerPageOptions.map((option) => (
              <MenuItem
                key={option}
                id={`rows-per-page-pagination-option-${option}`}
                value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        )}
      </Box>
      <Pagination
        showFirstButton
        showLastButton
        onChange={changePage}
        page={page + 1}
        count={lastPage}
        boundaryCount={1}
        size="small"
        siblingCount={0}
        classes={{ ul: classes.paginationPanelItem }}
      />
    </Box>
  );
};

export default PaginationPanel;
