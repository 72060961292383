import CONFIG from "@APP/config";
import { Custodian, CustodianType } from "@APP/types";
import request from "../request";

export const DEFAULT_CUSTODIAN_PROVIDER = "moneyhub";

/*
 ------- Moneyhub integration -------
*/

export const getCustodian = async (custodianId: string) => {
  const response = await request<Custodian>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/custodians/${custodianId}`,
  });
  return response.data;
};

export const getCustodiansByType = async (
  custodianType: CustodianType,
  custodianProvider = DEFAULT_CUSTODIAN_PROVIDER,
) => {
  const response = await request<{ data: Custodian[] }>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/custodians`,
    params: {
      custodian_type: custodianType,
      provider: custodianType === "Bank" ? custodianProvider : null,
      features: custodianType === "Bank" ? "AccountInformation" : null,
    },
  });
  return response.data.data;
};

/*
  ------- MX integration -------
*/

export const getMxInstitution = async (institutionId: string) => {
  const response = await request<{ data: Custodian[] }>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/institutions/${institutionId}`,
  });

  return response.data.data[0];
};
