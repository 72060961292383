import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  capitalize,
  CardActions,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";

import {
  fetchCardPaymentAppDetails,
  getBankAccounts,
  getCardPaymentApp,
  getErpId,
  useAppDispatch,
} from "@APP/redux";
import { CardPaymentApplicationStatus, ErpBanksAccounts } from "@APP/types";
import { SCREEN_PATHS } from "@APP/navigation";
import { ErpId, TabsName, Provider } from "@APP/constants";
import { NoteBox } from "@APP/components";

const CommonCardActions = ({
  erpBanksAccounts,
  onCompleteRegistration,
}: {
  erpBanksAccounts: ErpBanksAccounts[] | null;
  onCompleteRegistration: () => void;
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const erp = useSelector(getErpId);
  const bankAccounts = useSelector(getBankAccounts);
  const queryParams = queryString.parse(history.location?.search ?? "") as { tab: string };
  const isSettingsScreen = window.location.pathname.includes(SCREEN_PATHS.SETTINGS);
  const handleClickSetLedger = () => {
    if (isSettingsScreen) {
      return history.push(`${SCREEN_PATHS.SETTINGS}?tab=${TabsName.ACCOUNTING_PACKAGE}`);
    }
    return history.push(SCREEN_PATHS.SET_LEDGER_FOR_PAYMENTS_BOOKING);
  };

  if (
    !isSettingsScreen &&
    (erp === ErpId.INTERNAL ||
      !bankAccounts?.length ||
      !erpBanksAccounts?.length ||
      (erpBanksAccounts?.length === 1 && bankAccounts?.length === 1))
  ) {
    return (
      <>
        <Divider />
        <CardActions>
          <Box mx={1} width="100%">
            <Button
              variant="contained"
              onClick={onCompleteRegistration}
              color="primary"
              disabled={!bankAccounts?.length}
              fullWidth>
              Finish
            </Button>
          </Box>
        </CardActions>
      </>
    );
  } else if (
    erp !== ErpId.INTERNAL &&
    (queryParams.tab === TabsName.BANK_ACCOUNTS || !queryParams.tab)
  ) {
    return (
      <>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            disabled={!bankAccounts?.length}
            data-testid="set-ledgers-for-payments-booking-button"
            onClick={handleClickSetLedger}>
            {t("Setup.SetupBankAccounts.SetupBankAccountsCardActions.SetCorrespondingAccounts", {
              erp: capitalize(erp as string),
            })}
          </Button>
        </CardActions>
      </>
    );
  }
  return null;
};

const CardPaymentActions = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const bankAccounts = useSelector(getBankAccounts);
  const cardPaymentApp = useSelector(getCardPaymentApp);

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const queryParams = queryString.parse(history.location?.search ?? "") as { tab: string };

  const handleApplyCardPaymentClick = () => {
    history.push({
      pathname: SCREEN_PATHS.SETUP_PAYMENT_APPLICATION,
      search: `?fromPath=${window.location.pathname}`,
    });
  };

  const renderActionButton = useCallback(
    () => (
      <Button
        color="primary"
        variant="contained"
        fullWidth
        data-testid="apply-for-card-payments-button"
        disabled={!bankAccounts?.length}
        onClick={handleApplyCardPaymentClick}>
        {cardPaymentApp ? "Complete payment application" : "Apply to accept payments"}
      </Button>
    ),
    [bankAccounts, cardPaymentApp],
  );

  const renderActionsContent = useCallback(() => {
    if (isLoading) {
      return (
        <Box mb={1} mt={1} display="flex" justifyContent="center">
          <CircularProgress color="primary" size={30} />
        </Box>
      );
    }

    if (isError) {
      return (
        <Box mb={2} mt={2} textAlign="center">
          <Typography variant="h5" component="p">
            We are unable to retrieve your payment application status, please try again later.
          </Typography>
        </Box>
      );
    }

    return renderActionButton();
  }, [isLoading, isError, renderActionButton]);

  useEffect(() => {
    (async function () {
      try {
        setIsLoading(true);

        await dispatch(fetchCardPaymentAppDetails());
      } catch (error) {
        setIsError(true);
      }

      setIsLoading(false);
    })();
  }, []);

  if (cardPaymentApp?.status === CardPaymentApplicationStatus.Approved) {
    return null;
  }

  return (
    <>
      {queryParams.tab === TabsName.BANK_ACCOUNTS ? null : (
        <>
          <Divider />
          <CardActions>
            <Box display="flex" flexGrow={1} flexDirection="column">
              <NoteBox mt={1} mx={1} mb={2}>
                {t("CardPayments.useApplicationNote")}
              </NoteBox>
              {renderActionsContent()}
            </Box>
          </CardActions>
        </>
      )}
    </>
  );
};

const SetupBankAccountsCardActions = !Provider.isMaverick ? CommonCardActions : CardPaymentActions;

export default SetupBankAccountsCardActions;
