import { useSelector } from "react-redux";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { CommonTextField, NoteBox, PhoneField } from "@APP/components";
import { AppState } from "@APP/redux";
import CONFIG from "@APP/config";

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: "auto",
    height: 150,
    width: 150,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.action.active,
    fontSize: 48,

    [theme.breakpoints.down("md")]: {
      height: 75,
      width: 75,
    },
  },
  userInfo: {
    alignSelf: "center",
  },
}));

const UserDetails = () => {
  const classes = useStyles();
  const theme = useTheme();

  const user = useSelector(({ auth: { user } }: AppState) => user);
  const isSmSizeScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Card elevation={4}>
      <CardHeader title="User" subheader="View user details." data-testid="user-card-header" />
      <Divider />
      <CardContent>
        <Grid container direction={isSmSizeScreen ? "column" : "row"} spacing={3}>
          <Grid item xs={12} md={4}>
            <Avatar className={classes.avatar}>{user?.username[0].toUpperCase() || ""}</Avatar>
          </Grid>
          <Grid item xs={12} md={8} className={classes.userInfo}>
            <Grid container direction="column" spacing={3}>
              <Grid item md={10} xs={12}>
                <CommonTextField
                  inputProps={{ readOnly: true, disabled: true, "data-testid": "email-input" }}
                  fullWidth
                  label="Email"
                  name="email"
                  value={user?.username || ""}
                  autoComplete="email"
                />
              </Grid>
              <Grid item md={10} xs={12}>
                <PhoneField
                  inputProps={{ "data-testid": "phone-number-input", disabled: true }}
                  fullWidth
                  label="Mobile Telephone Number"
                  name="phone"
                  value={user?.phone || ""}
                  countryCode={CONFIG.INPUTS.DEFAULT_PHONE_COUNTRY_CODE}
                  readOnly
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="center">
          <Box mt={6} position="relative">
            <NoteBox>
              <Typography variant="h6" component="p" align="center">
                If you want to delete your account, please send your request to{" "}
                <Link underline="always" href={`mailto:${CONFIG.SUPPORT_EMAIL}`}>
                  {CONFIG.SUPPORT_EMAIL}
                </Link>
                .
              </Typography>
            </NoteBox>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default UserDetails;
