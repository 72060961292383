import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { Box, Button, Card, Grid, Link, TableCell, TableRow, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import {
  CommonTextField,
  FooterActionsButtons,
  Page,
  ResultNotification,
  ScreenHeader,
  ScreenHeaderSubtitle,
  SortableTitle,
  Table,
} from "@APP/components";
import { Contact, ResourceId, CustomerType, SortingAriaType, SortType } from "@APP/types";
import { API } from "@APP/services";
import {
  getInvoice,
  getUser,
  hideLoader,
  setDefaultRtpDetailsToReceivables,
  setDefaultInvoiceState,
  setDeliveryEmails,
  setPayerEmail,
  setPayerId,
  setPayerName,
  setPayerPhone,
  setAddressInformation,
  showLoader,
  useAppDispatch,
} from "@APP/redux";
import { ErpId, NUMBER_OF_FIRST_PAGE_IN_TABLE_WITH_PAGINATION } from "@APP/constants";
import { useAlert, useHandleErrorCodes } from "@APP/hooks";
import { capitalize, formatPhoneNumber, handleKeyboardClick } from "@APP/utils";
import { SCREEN_PATHS } from "@APP/navigation";

export const useStyles = makeStyles((theme) => ({
  tableRow: {
    cursor: "pointer",
  },
  sortHeader: {
    cursor: "pointer",
  },
  pageContent: {
    display: "flex",
    flexDirection: "column",
  },
  containerTable: {
    marginTop: 10,
  },
  searchInput: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: theme.palette.background.paper,
    },
  },
  viewCustomerLink: {
    textDecoration: "underline",
  },
}));

type CustomerTypeWithDisplaying = CustomerType & { hidden: boolean };

const SelectCustomer = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const alert = useAlert();
  const history = useHistory();
  const handleErrorCodes = useHandleErrorCodes();
  const { t } = useTranslation();
  const [sortedCustomers, setSortedCustomers] = useState<CustomerTypeWithDisplaying[] | null>(null);
  const [selectedCustomerId, setSelectedCustomerId] = useState<undefined | ResourceId>();
  const [sortType, setSortType] = useState<SortType>(SortType.asc);
  const {
    page: initialPage = 0,
    entries: initialEntries = 10,
    searchedValue: initialSearchedValue = null,
  } = queryString.parse(history.location.search);
  const [entries, setEntries] = useState<number>(initialEntries ? Number(initialEntries) : 10);
  const [searchedValue, setSearchedValue] = useState(initialSearchedValue);
  const [page, setPage] = useState<number>(
    initialPage ? Number(initialPage) : NUMBER_OF_FIRST_PAGE_IN_TABLE_WITH_PAGINATION,
  );

  const user = useSelector(getUser);
  const { customerContact } = useSelector(getInvoice);

  const getAllCustomersRecursively = async (
    erpId: string,
    page = 0,
    prevCustomers: CustomerType[] = [],
  ): Promise<CustomerType[]> => {
    const response = await API.fetchCustomers(erpId, { page });
    const customers = [...prevCustomers, ...response.data] as CustomerType[];
    if (response.links.next) {
      return getAllCustomersRecursively(erpId, page + 1, customers);
    }
    return customers;
  };

  const loadCustomers = async () => {
    try {
      dispatch(showLoader());
      const customers = await getAllCustomersRecursively(user?.erp as ErpId);

      if (!customers.length) {
        setSortedCustomers([]);

        return alert.open(
          "",
          t("Errors.OutboundPayments.Alerts.NoCustomers.Message", {
            ACCOUNTING_PACKAGE: capitalize(user?.erp),
          }),
          [
            {
              text: "Create Customer",
              onClick: () => history.push(SCREEN_PATHS.RECEIVABLES_CREATE_CUSTOMER),
            },
          ],
        );
      }

      let filteredCustomers = filterCustomersBySearchValue(
        searchedValue as string,
        customers.map((customer) => ({ ...customer, hidden: false })),
      ).sort((a: CustomerType, b: CustomerType) =>
        a.entityContact.name.toLowerCase() > b.entityContact.name.toLowerCase()
          ? 1
          : b.entityContact.name.toLowerCase() > a.entityContact.name.toLowerCase()
          ? -1
          : 0,
      );

      if (sortType === SortType.desc) {
        filteredCustomers = filteredCustomers.reverse();
      }

      setSortedCustomers(filteredCustomers);
    } catch (error) {
      const errorData = error?.response?.data;
      const isHandled = handleErrorCodes(errorData?.errorCode);

      if (isHandled) return;

      alert.open("", t("Errors.OutboundPayments.Alerts.GetCustomers.Message"), [
        { text: "Cancel" },
        { text: "Try again", onClick: async () => await loadCustomers() },
      ]);
    } finally {
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    dispatch(setDefaultRtpDetailsToReceivables());
    setSelectedCustomerId(customerContact.id);
    (async () => {
      await loadCustomers();
      await handlePagination(page, entries);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      handlePagination(page, entries);
    })();
  }, [page, entries, sortType, searchedValue]);

  const createQueryParamsString = (params?: any) => {
    const queryStringResults = queryString.parse(history.location.search);

    // create search query url
    return Object.entries({
      page,
      entries,
      ...queryStringResults,
      sort_type: sortType,
      searchedValue: searchedValue,
      ...params,
    })
      .filter((query) => {
        // filter out falsy values (except 0)
        return !!query[1] || query[1] === Number(0);
      })
      .reduce((acc, query, index) => {
        const [key, value] = query;

        if (index === 0) acc += `?${key}=${value}`;
        else acc += `&${key}=${value}`;

        return acc;
      }, "");
  };

  const handlePagination = (page: number, entries: number) => {
    const search = createQueryParamsString({ page, entries });
    history.replace({
      pathname: history.location.pathname,
      search,
    });
  };

  const filterCustomersBySearchValue = (value: string, customers: CustomerTypeWithDisplaying[]) => {
    if (!value) return customers;

    const filterIn: (keyof Contact)[] = ["name", "email", "mobile"];

    return customers.map((customer: CustomerTypeWithDisplaying) => {
      for (let i = 0; i < filterIn.length; i++) {
        if ((customer.entityContact[filterIn[i]] as string)?.toLowerCase().includes(value)) {
          return { ...customer, hidden: false };
        }
      }

      return { ...customer, hidden: true };
    });
  };

  const handleChangeSearchedCustomers = useCallback(
    (e: React.ChangeEvent<any>) => {
      if (!sortedCustomers) return;

      const value = e.target.value.toLowerCase();
      if (!value) {
        setSearchedValue(value);
        setSortedCustomers(sortedCustomers.map((customer) => ({ ...customer, hidden: false })));

        return;
      }

      const filteredList = filterCustomersBySearchValue(value, sortedCustomers);

      setSearchedValue(value);
      setSortedCustomers(filteredList);
      setPage(NUMBER_OF_FIRST_PAGE_IN_TABLE_WITH_PAGINATION);
    },
    [sortedCustomers],
  );

  const handleCreateCustomer = () => {
    dispatch(setDefaultInvoiceState());
    history.push(SCREEN_PATHS.RECEIVABLES_CREATE_CUSTOMER);
  };

  const handleOnEntriesChange = (entries: number) => {
    setPage(NUMBER_OF_FIRST_PAGE_IN_TABLE_WITH_PAGINATION);
    setEntries(entries);
  };

  const handleRowClick = (contactId?: ResourceId) => {
    if (!contactId) return;

    setSelectedCustomerId(contactId);
    dispatch(setDefaultInvoiceState());
  };

  const handleViewCustomerClick = (customerId?: string) => async (e: React.SyntheticEvent) => {
    e.stopPropagation();
    if (!customerId) return;

    history.push(`${SCREEN_PATHS.RECEIVABLES_CUSTOMER_DETAILS}/${customerId}`);
  };

  const renderHeader = () => (
    <TableRow>
      <TableCell
        className={classes.sortHeader}
        onClick={handleSorting}
        onKeyDown={(event) => handleKeyboardClick(event, handleSorting)}
        role="columnheader"
        aria-label={`sort by name ${sortType}`}
        aria-sort={SortingAriaType[sortType]}
        tabIndex={0}
        data-testid="customer-name-table-cell">
        <SortableTitle type={sortType} active={sortType !== null}>
          Customer Name
        </SortableTitle>
      </TableCell>
      <TableCell data-testid="customer-email-table-cell">Email</TableCell>
      <TableCell data-testid="customer-phone-number-table-cell">Phone Number</TableCell>
      <TableCell>
        <Typography className="visuallyHidden">View Invoice Details</Typography>
      </TableCell>
    </TableRow>
  );

  const renderRows = (item: CustomerTypeWithDisplaying) => (
    <TableRow
      className={classes.tableRow}
      key={`${item.entityContact.name}-${item.entityContact.id?.externalId}`}
      selected={selectedCustomerId?.externalId === item.entityContact.id?.externalId}
      onClick={() => handleRowClick(item.entityContact.id)}
      onKeyDown={(event) => handleKeyboardClick(event, () => handleRowClick(item.entityContact.id))}
      tabIndex={0}
      role="button"
      aria-label={`select customer ${item.entityContact.name}`}
      data-testid={`${item.entityContact.name}-${item.entityContact.id?.externalId}`}
      aria-pressed={selectedCustomerId?.externalId === item.entityContact.id?.externalId}>
      <TableCell>{item.entityContact.name}</TableCell>
      <TableCell>{item.entityContact.email}</TableCell>
      <TableCell>{formatPhoneNumber(item.entityContact.mobile, "")}</TableCell>
      <TableCell>
        <Link
          component="button"
          onClick={handleViewCustomerClick(item.entityContact.id?.externalId)}
          className={classes.viewCustomerLink}>
          View Customer
        </Link>
      </TableCell>
    </TableRow>
  );

  const renderEmptyDataRow = () => (
    <TableRow>
      <TableCell colSpan={3} align="center">
        {t("Errors.OutboundPayments.Alerts.NoResults.Message", {
          SEARCHED_CUSTOMER: searchedValue,
        })}
      </TableCell>
    </TableRow>
  );

  if (!sortedCustomers) {
    return null;
  }

  const handleSorting = () => {
    setSortType(sortType === SortType.asc ? SortType.desc : SortType.asc);
    setSortedCustomers(sortedCustomers.reverse());
  };

  const filteredCustomer = sortedCustomers.filter((customer) => !customer.hidden);

  const handleContinue = () => {
    const selectedCustomer = sortedCustomers.find(
      (customer) => customer.entityContact.id?.externalId === selectedCustomerId?.externalId,
    );

    if (!selectedCustomer) return;

    dispatch(setPayerName(selectedCustomer?.entityContact.name!));
    dispatch(setPayerId(selectedCustomer?.entityContact.id));

    if (selectedCustomer?.entityContact.email) {
      dispatch(setPayerEmail(selectedCustomer?.entityContact.email));
      dispatch(setDeliveryEmails({ email: selectedCustomer?.entityContact.email }));
    }

    if (selectedCustomer?.entityContact.mobile)
      dispatch(setPayerPhone(selectedCustomer?.entityContact.mobile));

    if (selectedCustomer?.entityContact.shippingAddress)
      dispatch(setAddressInformation(selectedCustomer.entityContact.shippingAddress));

    history.push(SCREEN_PATHS.RECEIVABLES_DELIVERY_DETAILS);
  };

  const handleClickBackButton = () => {
    const queryParams = queryString.parse(history.location.search) as { prevPage: string };

    history.push(queryParams.prevPage ? queryParams.prevPage : SCREEN_PATHS.RECEIVABLES_LIST);
  };

  return (
    <Page title="Create Invoice" className={classes.pageContent}>
      <Grid container>
        <Grid item xs={12}>
          <ScreenHeader title="Select Customer" />
          <ScreenHeaderSubtitle subtitle="Choose a customer from your accounting package or create a new one." />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" textAlign="center" justifyContent="flex-end">
            <Button variant="contained" color="secondary" onClick={handleCreateCustomer}>
              Create new Customer
            </Button>
          </Box>
        </Grid>
      </Grid>
      {sortedCustomers.length ? (
        <>
          <Grid container justifyContent="flex-end">
            <Grid item md={8} />
            <Grid item md={4} xs={12}>
              <CommonTextField
                fullWidth
                label="Name, Email or Phone Number"
                hiddenLabel
                placeholder="Enter Name, Email or Phone Number..."
                className={classes.searchInput}
                margin="normal"
                name="searchedValue"
                variant="outlined"
                onChange={handleChangeSearchedCustomers}
                value={searchedValue}
                inputProps={{ "data-testid": "search-input" }}
                data-testid="search-input-container"
              />
            </Grid>
          </Grid>
          <Box mb={3} mt={3}>
            <Card elevation={12}>
              <Table
                data={filteredCustomer.slice(page * entries, (page + 1) * entries)}
                renderHeader={renderHeader}
                renderRows={renderRows}
                onEntriesChange={handleOnEntriesChange}
                onPageChange={setPage}
                renderEmptyDataRow={renderEmptyDataRow}
                page={page}
                entries={entries}
                lastPage={
                  filteredCustomer.length && filteredCustomer.length <= entries
                    ? 1
                    : Math.ceil(filteredCustomer.length / entries)
                }
              />
            </Card>
          </Box>
          <FooterActionsButtons
            backButtonText="Back"
            handleBackButton={handleClickBackButton}
            disabledContinueButton={!selectedCustomerId}
            handleContinue={handleContinue}
            continueButtonText="Continue"
          />
        </>
      ) : (
        <Box mt={2}>
          <ResultNotification type="info" title="Warning">
            No customers were found.
          </ResultNotification>
        </Box>
      )}
    </Page>
  );
};

export default SelectCustomer;
