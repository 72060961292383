import { useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from "@mui/icons-material/Close";
import TickIcon from "@mui/icons-material/Done";
import { green } from "@mui/material/colors";

import { NoteBox, Page, AppStorePromoSection } from "@APP/components";
import { SCREEN_PATHS } from "@APP/navigation";
import CONFIG from "@APP/config";
import { AppState } from "@APP/redux";
import { useAlert } from "@APP/hooks";
import { SubscriptionFeatureTypes } from "@APP/types";
import { TabsName } from "@APP/constants";
import PreviewOfRtpScreenImage from "@APP/assets/preview-of-rtp-screen.png";

const useStyles = makeStyles((theme) => ({
  tickIcon: {
    fontSize: "10rem",
  },
  infoIcon: {
    position: "absolute",
    top: 10,
    left: 10,
    width: "1.5rem",
    height: "1.5rem",
  },
  infoContent: {
    position: "relative",
  },
  info: {
    backgroundColor: green[50],
  },
  iconButton: {
    width: "8rem",

    [theme.breakpoints.up("sm")]: {
      width: "12rem",
    },
  },
  previewOfRtpScreenDialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  previewOfRtpScreenDialogContent: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(2),
  },
  link: {
    verticalAlign: "baseline",
  },
}));

const RegistrationComplete = () => {
  const classes = useStyles();
  const alert = useAlert();
  const { t } = useTranslation();
  const { subscription } = useSelector(({ auth: { user } }: AppState) => ({
    subscription: user?.subscription,
  }));

  const showPreviewOfRtpScreen = () => {
    alert.render(
      <>
        <DialogTitle
          className={classes.previewOfRtpScreenDialogTitle}
          data-testid="modal-window-title">
          <Grid container>
            <Grid item md={10} xs={9}>
              <Typography variant="h4">
                This preview is for illustration purposes only. This is what your customer will
                receive:
              </Typography>
            </Grid>
            <Grid item md={2} xs={3}>
              <Box display="flex" justifyContent="flex-end">
                <IconButton size="small" color="inherit" onClick={() => alert.close()}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent
          className={classes.previewOfRtpScreenDialogContent}
          data-testid="modal-window-message">
          <img src={PreviewOfRtpScreenImage} alt="Preview of RTP screen" width="100%" />
        </DialogContent>
      </>,
    );
  };

  return (
    <Page title="Registration Completed">
      <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
        <Grid item xs={12} md={8} lg={6}>
          <Card elevation={4}>
            <CardHeader title="Registration Completed" />
            <Divider />
            <CardContent>
              <Box textAlign="center">
                <TickIcon color="secondary" className={classes.tickIcon} />
                <Typography color="textPrimary" align="center" variant="h4">
                  Welcome to {t("ProductName")}. Start getting paid today!
                </Typography>
                <Box mt={4} display="flex" justifyContent="center">
                  <Typography color="textPrimary">
                    Want to see what the requests to your customer will look like?{" "}
                    <Link
                      className={classes.link}
                      component="button"
                      variant="body1"
                      underline="always"
                      onClick={showPreviewOfRtpScreen}>
                      Click here
                    </Link>
                  </Typography>
                </Box>
              </Box>
            </CardContent>
            <CardContent className={classes.infoContent}>
              {!subscription ||
              (subscription.isPlanFree &&
                CONFIG.FEATURES.SUBSCRIPTIONS.TYPE === SubscriptionFeatureTypes.Flexible) ||
              !t("Registration.RegistrationCompletedMessage") ? null : (
                <NoteBox>
                  <Typography variant="body2">
                    <Trans
                      i18nKey="Registration.RegistrationCompletedMessage"
                      values={{
                        freeTrialDays: subscription?.plan?.freeTrialDays,
                        billMonth: subscription?.plan?.price.amount,
                        periodDays: subscription?.plan?.periodDays,
                      }}
                    />
                  </Typography>
                </NoteBox>
              )}
              <Box mt={3} mb={2}>
                <Typography color="textPrimary" align="center">
                  You can manage your account in the app{" "}
                  <Link
                    underline="always"
                    component={RouterLink}
                    variant="body1"
                    to={`${SCREEN_PATHS.SETTINGS}?tab=${TabsName.USER}`}>
                    Settings
                  </Link>
                </Typography>
              </Box>
              <Divider />
              <AppStorePromoSection />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Page>
  );
};

export default RegistrationComplete;
