import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Box } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { IMAGES } from "@APP/assets";
import CONFIG from "@APP/config";

interface AuthLayoutProps {
  mainContent: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  introContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: theme.palette.background.gradient ?? theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(4),
  },
}));

const AuthLayout = ({ mainContent }: AuthLayoutProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid className={classes.introContainer} item md={6}>
        <Box mb={4} display="flex" justifyContent="center">
          <img src={IMAGES.APP_LOGO} width="45%" alt={CONFIG.PRODUCT_NAME} />
        </Box>
        <Typography variant="h4" component="p" gutterBottom>
          {t("AuthLayout.welcomeText")}
        </Typography>
        <Typography variant="h1">{t("AuthLayout.welcomeSubtitle")}</Typography>
      </Grid>
      <Grid className={classes.mainContainer} item xs={12} md={6}>
        {mainContent}
      </Grid>
    </Grid>
  );
};

export default AuthLayout;
