import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useSelector } from "react-redux";

import {
  fetchCardPaymentAppDetails,
  getCardPaymentApp,
  hideLoader,
  showLoader,
  useAppDispatch,
} from "@APP/redux";
import { NoteBox, Page } from "@APP/components";
import { SCREEN_PATHS } from "@APP/navigation";
import { useAlert } from "@APP/hooks";
import { TabsName } from "@APP/constants";
import { CardPaymentApplicationStatus } from "@APP/types";

import MaverickWidgetIframe from "./MaverickWidgetIframe";

const useStyles = makeStyles(() => ({
  cardRoot: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    overflow: "auto",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: 0,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  filledCardContent: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
}));

const SetupCardPaymentsView = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const alert = useAlert();
  const dispatch = useAppDispatch();
  const isLessThenLg = useMediaQuery(theme.breakpoints.down("lg"));
  const cardPayment = useSelector(getCardPaymentApp);

  const queries = queryString.parse(history.location.search ?? "") as {
    fromPath?: string;
  };

  const fromPath = queries?.fromPath;

  const handleOnFinishClick = async () => {
    try {
      dispatch(showLoader());

      await dispatch(fetchCardPaymentAppDetails());

      dispatch(hideLoader());

      if (fromPath?.includes(TabsName.CARD_PAYMENTS)) {
        return history.replace(`${SCREEN_PATHS.SETTINGS}?tab=${TabsName.CARD_PAYMENTS}`);
      }

      if (fromPath === SCREEN_PATHS.SETUP_BANK_ACCOUNTS) {
        return history.replace(
          cardPayment?.status === CardPaymentApplicationStatus.Approved
            ? SCREEN_PATHS.SETUP_CLEARING_LEDGER
            : SCREEN_PATHS.REGISTRATION_COMPLETE,
        );
      }

      if (fromPath?.includes(SCREEN_PATHS.SETTINGS)) {
        return history.replace(SCREEN_PATHS.SETTINGS);
      }

      return history.replace(SCREEN_PATHS.DASHBOARD);
    } catch (error) {
      dispatch(hideLoader());

      alert.open(
        t("Errors.Common.Alerts.AlertTitles.Error"),
        t("Errors.Common.Alerts.Generic.Message"),
      );
    }
  };

  return (
    <Page title="Payment Application" style={{ display: isLessThenLg ? undefined : "flex" }}>
      <Card className={classes.cardRoot} elevation={4}>
        <CardHeader title="Payment Application" subheader="Apply to accept payments" />
        <Divider />
        <CardContent className={classes.filledCardContent}>
          <Box mb={2}>
            <NoteBox>{t("CardPayments.useApplicationNote")}</NoteBox>
          </Box>
          <MaverickWidgetIframe />
        </CardContent>
        <Divider />
        <CardActions>
          <Box mx={1} width="100%">
            <Button variant="contained" onClick={handleOnFinishClick} color="primary" fullWidth>
              Finish
            </Button>
          </Box>
        </CardActions>
      </Card>
    </Page>
  );
};

export default SetupCardPaymentsView;
