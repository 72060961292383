import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { differenceInDays } from "date-fns";
import { useHistory } from "react-router-dom";
import { Box, Button, Card, CardContent, Divider, Typography } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { SubscriptionOperations, TabsName } from "@APP/constants";
import { SCREEN_PATHS } from "@APP/navigation";
// import { fetchUserSubscription, hideLoader, showLoader, getSubscription } from "@APP/redux";
import { fetchUserData, hideLoader, showLoader, getSubscription } from "@APP/redux";
import { API } from "@APP/services";
import { useAlert } from "@APP/hooks";
import { getErrorMessageByErrorCode } from "@APP/utils";
import { NoteBox } from "@APP/components";
import { ErrorCode } from "@APP/types";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
  },
  confirmButton: {
    marginBottom: theme.spacing(2),
  },
}));

const SUBSCRIPTION_IS_ALREADY_DEACTIVATED_ERROR_CODE: ErrorCode = 1031;

const DeactivateAccount = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const alert = useAlert();
  const { t } = useTranslation();

  const subscription = useSelector(getSubscription);

  const billingRemainingDays = useMemo(() => {
    return differenceInDays(new Date(subscription?.endDate!), new Date());
  }, [subscription?.endDate]);

  const onConfirm = useCallback(async () => {
    try {
      dispatch(showLoader());

      await API.unsubscribeFromPlan(subscription?.id!);
      // await dispatch(fetchUserSubscription());
      await dispatch(fetchUserData());

      history.push(
        `${SCREEN_PATHS.SETTINGS}?tab=${TabsName.ACCOUNT_SUCCESS}&operation=${SubscriptionOperations.UNSUBSCRIBE}`,
      );
    } catch (error) {
      const errorData = error?.response?.data;

      if (errorData?.errorCode === SUBSCRIPTION_IS_ALREADY_DEACTIVATED_ERROR_CODE) {
        return alert.open(
          t("Errors.Common.Alerts.AlertTitles.Failure"),
          getErrorMessageByErrorCode(errorData?.errorCode as ErrorCode),
          [{ text: "Finish" }],
        );
      } else {
        alert.open(
          t("Errors.Common.Alerts.AlertTitles.Error"),
          t("Errors.Common.Alerts.Generic.Message"),
          [
            {
              text: "Finish",
            },
          ],
        );
      }
    } finally {
      dispatch(hideLoader());
    }
  }, [subscription]);

  return (
    <Card elevation={4} className={classes.root}>
      <Box display="flex" flexDirection="column" alignItems="center" mb={3} mt={3}>
        <Typography variant="h3" color="primary">
          Deactivate Account
        </Typography>
      </Box>
      <CardContent>
        <Box textAlign="center">
          {subscription?.plan?.freeTrialDays ? (
            <Typography>
              You are still in your free period. You can continue to use all features during this
              time. We’ll remind you as you near the end of the free period.
            </Typography>
          ) : (
            <Typography>
              You have {billingRemainingDays} days remaining in your current billing period. You can
              continue to use all features in the app for these days.
            </Typography>
          )}
        </Box>
      </CardContent>
      <CardContent>
        <NoteBox>
          {subscription?.plan?.freeTrialDays ? (
            <Typography variant="body2">
              After your free period you will still have access to your account but will no longer
              be able to use the features in the app. If you would like to activate your account at
              any time please go to the ‘Account’ section in the app.
            </Typography>
          ) : (
            <Typography variant="body2">
              After deactivating you will still have access to your account but will no longer be
              able to use the features in the app. If you would like to re-activate at any time
              please go to the ‘Account’ section in the app.
            </Typography>
          )}
        </NoteBox>
      </CardContent>
      <Divider />
      <Box display="flex" flexDirection="column" margin={2}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          className={classes.confirmButton}
          onClick={onConfirm}>
          Confirm
        </Button>
        <Button
          variant="outlined"
          fullWidth
          onClick={() => history.push(`${SCREEN_PATHS.SETTINGS}?tab=${TabsName.ACCOUNT}`)}>
          Cancel
        </Button>
      </Box>
    </Card>
  );
};

export default DeactivateAccount;
