import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation, TFunction } from "react-i18next";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { isBefore, isValid as isValidDate, startOfDay } from "date-fns";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";

import { FooterActionsButtons, Page, ScreenHeader } from "@APP/components";
import {
  convertReceivablesToDeliveryData,
  emailValidationSchema,
  formatDisplayedDate,
  isLinkedLedger,
} from "@APP/utils";
import { SCREEN_PATHS } from "@APP/navigation";
import {
  getAutomatedCollections,
  getBankAccounts,
  getBankLedgers,
  getRtpDetails,
  removeSelectedReceivable,
  setAccountToDeliveryDetailsToReceivables,
  setDefaultInvoiceState,
  setDeliveryDateToDeliveryDetailsToReceivables,
  setDeliveryEmails,
  useAppDispatch,
} from "@APP/redux";
import { useAlert } from "@APP/hooks";
import { ICustomerReceivablesDetails } from "@APP/types";
import { RtpDetailsState } from "@APP/redux/reducers/rtpDetails";
import { Provider } from "@APP/constants";

import ReceivablesContactInfo from "./ReceivablesContactInfo";
import ReceivablesDeliveryDetails from "./ReceivablesDeliveryDetails";

export interface IReceivablesDeliveryAndContactDetailsForm {
  deliveryDetails: RtpDetailsState["deliveryDetails"];
  data: ICustomerReceivablesDetails[];
}

const contactAndDeliveryDetailsValidationSchema = (t: TFunction) =>
  Yup.object().shape({
    data: Yup.array().of(emailValidationSchema(t)),
    deliveryDetails: Yup.object().shape({
      account: Provider.isMaverick
        ? Yup.string().notRequired()
        : Yup.string().required(t("Errors.RTPCreation.Validation.AccountRequired")),
      reminder: Yup.boolean(),
      deliveryDate: Yup.date()
        .nullable()
        .required(t("Errors.RTPCreation.Validation.DeliveryDateRequired"))
        .typeError(t("Errors.RTPCreation.Validation.DeliveryDateValid"))
        .isDateCorrectlyFormed(t("Errors.RTPCreation.Validation.DeliveryDateValid"))
        .test("deliveryDate", t("Errors.RTPCreation.Validation.DeliveryDateValid"), (value) => {
          if (!value) return false;
          return isValidDate(new Date(value));
        })
        .min(startOfDay(new Date()), t("Errors.RTPCreation.Validation.DeliveryDatePast")),
    }),
  });

const ReceivablesDeliveryAndContactDetails = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const alert = useAlert();
  const { t } = useTranslation();

  const { receivables } = useSelector(getAutomatedCollections);
  const { isRecurring } = useSelector(getRtpDetails);
  const { deliveryDetails } = useSelector(getRtpDetails);
  const bankAccounts = useSelector(getBankAccounts);
  const ERPLedgers = useSelector(getBankLedgers);

  useEffect(() => {
    if (!!bankAccounts?.length && !Provider.isMaverick) {
      bankAccounts.forEach((el) => {
        if (isLinkedLedger(ERPLedgers, el.account.identification)) {
          const account = el.account.identification || "";

          setFieldValue("deliveryDetails.account", account);
          dispatch(setAccountToDeliveryDetailsToReceivables(account));
        }
      });
    }
  }, [bankAccounts]);

  useEffect(() => {
    // if a user provides an invalid date then refreshes the page then "deliveryDetails.deliveryDate" will be rehydrated as null
    !deliveryDetails.deliveryDate &&
      dispatch(setDeliveryDateToDeliveryDetailsToReceivables(new Date()));
  }, []);

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    values,
    isValid,
    setValues,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    initialValues: {
      deliveryDetails: {
        ...deliveryDetails,
        deliveryDate: deliveryDetails.deliveryDate ?? new Date(),
      },
      data: convertReceivablesToDeliveryData({
        receivables,
        deliveryEmails: deliveryDetails.deliveryEmails,
      }),
    },
    validationSchema: contactAndDeliveryDetailsValidationSchema(t),
    onSubmit: ({ deliveryDetails, data }) => {
      const deliveryDate = startOfDay(new Date(deliveryDetails.deliveryDate));
      const isOverdue = data.some((item) => isBefore(new Date(item.dueDateTime), deliveryDate));

      if (isOverdue) {
        const formattedDeliveryDate = formatDisplayedDate(deliveryDate);
        const titleAlert =
          receivables.length === 1
            ? "Sending payment request for an overdue Invoice"
            : `Sending payment requests for overdue Invoices`;
        const contentMessageAlert =
          receivables.length === 1
            ? `Your Invoice will be overdue on ${formattedDeliveryDate}, do you want to continue?`
            : `Some of your Invoices will be overdue on ${formattedDeliveryDate}, do you want to continue?`;

        return alert.open(titleAlert, contentMessageAlert, [
          { text: "Cancel" },
          {
            text: "Continue",
            onClick: () => redirectToNextStep(),
          },
        ]);
      }

      redirectToNextStep();
    },
  });

  const redirectToNextStep = () => {
    if (isRecurring) {
      return history.push(SCREEN_PATHS.RECEIVABLE_INSTALMENT_DETAILS);
    }

    history.push(SCREEN_PATHS.PAYMENT_REQUESTS_SUMMARY);
  };

  const removeReceivable = (externalId: string) => () => {
    if (!externalId) {
      dispatch(setDefaultInvoiceState());
      history.push(SCREEN_PATHS.RECEIVABLES_LIST);
    }

    setValues({
      ...values,
      data: values.data.filter((item) => item.id !== externalId),
    });

    dispatch(removeSelectedReceivable(externalId));
  };

  const saveEmail = (externalId: string, email: string) => {
    dispatch(setDeliveryEmails({ invoiceId: externalId, email }));
  };

  const saveAccount = () => {
    dispatch(setAccountToDeliveryDetailsToReceivables(values.deliveryDetails.account));
  };

  const handleOnChangeDeliveryDate = (date: Date | null) => {
    setFieldValue("deliveryDetails.deliveryDate", date, true);

    if (date) {
      dispatch(setDeliveryDateToDeliveryDetailsToReceivables(date));
    }
  };

  const handleNavigateToDashboard = () => history.push(SCREEN_PATHS.RECEIVABLES_LIST);

  return (
    <Page title="Create Payment Request">
      <ScreenHeader title="Create your Payment Requests" />
      <form onSubmit={handleSubmit}>
        <Box mt={3} mb={2}>
          <Typography variant="h6">Please provide the customer email address.</Typography>
        </Box>

        <Box>
          <ReceivablesContactInfo
            data={values.data}
            errors={errors}
            handleChange={handleChange}
            handleBlur={handleBlur}
            onValueChange={setFieldValue}
            touched={touched}
            removeReceivable={removeReceivable}
            saveEmail={saveEmail}
          />
        </Box>

        <Box mt={3} mb={2}>
          <Typography variant="h6" data-testid="nominate-account">
            {t("RTP.ReceivablesDeliveryAndContactDetails.ConfirmOtherDetails")}
          </Typography>
        </Box>

        <ReceivablesDeliveryDetails
          deliveryDetails={values.deliveryDetails}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
          handleChange={handleChange}
          handleOnChangeDeliveryDate={handleOnChangeDeliveryDate}
          saveAccount={saveAccount}
          onFieldTouched={setFieldTouched}
        />

        <FooterActionsButtons
          backButtonText="Back to Invoices"
          backButtonDataTestId="back-to-invoice-button"
          handleBackButton={handleNavigateToDashboard}
          continueButtonText="Continue"
          continueButtonDataTestId="continue-button"
          disabledContinueButton={!isValid}
          typeButtonContinue="submit"
        />
      </form>
    </Page>
  );
};

export default ReceivablesDeliveryAndContactDetails;
