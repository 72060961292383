import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ChevronLeft";

import { NoteBox, Page } from "@APP/components";
import { SCREEN_PATHS } from "@APP/navigation";
import { getSelectedRegisteredCompanyType, setSelectedCompanyType } from "@APP/redux";
import { CompanyType } from "@APP/constants";
import CONFIG from "@APP/config";

export const CompanyTypeView = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const companyType = useSelector(getSelectedRegisteredCompanyType);

  const handleClickContinueButton = () => {
    if (companyType === CompanyType.RegisteredCompany) {
      history.push(SCREEN_PATHS.REGISTERED_COMPANY_VIEW);
    } else if (companyType === CompanyType.OtherOrgTypes) {
      history.push(SCREEN_PATHS.OTHER_ORG_TYPES_VIEW);
    } else if (companyType === CompanyType.SoleTrader) {
      history.push(SCREEN_PATHS.SOLE_TRADER_TYPES_VIEW);
    }
  };

  return (
    <Page>
      <Card elevation={4}>
        <CardHeader
          title={t("Registration.CompanyType.CardTitle")}
          subheader={t("Registration.CompanyType.CardSubheader")}
          data-testid="company-type-header"
        />
        <Divider />
        <CardContent>
          <Box mb={2}>
            <NoteBox>{t("Registration.CompanyType.NoteBox")}</NoteBox>
          </Box>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="company_type"
              name="companyType"
              onChange={(e) => dispatch(setSelectedCompanyType(e.target.value as CompanyType))}
              value={companyType}>
              <FormControlLabel
                value={CompanyType.RegisteredCompany}
                control={<Radio color="secondary" />}
                label={t("Common.CompanyType.RegisteredCompany")}
              />
              <FormControlLabel
                value={CompanyType.SoleTrader}
                control={<Radio color="secondary" />}
                label={t("Common.CompanyType.SoleTrader")}
              />
              <FormControlLabel
                value={CompanyType.OtherOrgTypes}
                control={<Radio color="secondary" />}
                label={t("Common.CompanyType.OtherOrgTypes")}
              />
            </RadioGroup>
          </FormControl>
        </CardContent>
        <Divider />
        <Box p={2}>
          <Button
            color="primary"
            fullWidth
            type="submit"
            variant="contained"
            disabled={!companyType}
            onClick={handleClickContinueButton}>
            Continue
          </Button>
          {CONFIG.FEATURES.SETUP.LINKING_ACCOUNTING_PACKAGE && (
            <Box mt={2}>
              <Button
                onClick={() => history.push(SCREEN_PATHS.SETUP_ACCOUNTING_PACKAGE)}
                color="primary"
                fullWidth
                startIcon={<ArrowBackIosIcon />}
                type="button"
                variant="outlined">
                Back
              </Button>
            </Box>
          )}
        </Box>
      </Card>
    </Page>
  );
};

export default CompanyTypeView;
