import React from "react";
import { useSelector } from "react-redux";
import { Box, Button, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  CLEARING_LEDGER_ALREADY_EXIST_ERROR_CODE,
  createClearingLedger,
  fetchClearingLedger,
} from "@APP/services/api";
import {
  getBankAccounts,
  getClearingLedger,
  getErpId,
  hideLoader,
  setClearingLedger,
  showLoader,
  useAppDispatch,
} from "@APP/redux";
import { useAlert, useBankLedgers } from "@APP/hooks";
import { SCREEN_PATHS } from "@APP/navigation";

import ClearingLedgerContainer from "./ClearingLedgerContainer";

const CreateClearingLedgerView = ({ isRegistrationFlow }: { isRegistrationFlow?: boolean }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const erpId = useSelector(getErpId);
  const { linkSingleDefaultLedger, fetchERPBankAccounts } = useBankLedgers();
  const alert = useAlert();
  const { t } = useTranslation();
  const clearingLedger = useSelector(getClearingLedger);
  const bankAccounts = useSelector(getBankAccounts);

  const redirectToNextStep = async () => {
    try {
      const erpBanksAccounts = await fetchERPBankAccounts(redirectToNextStep);

      if (erpBanksAccounts?.length === 1 && bankAccounts?.length === 1) {
        await linkSingleDefaultLedger(erpBanksAccounts, bankAccounts);
        history.push(SCREEN_PATHS.REGISTRATION_COMPLETE);
      } else {
        history.push(SCREEN_PATHS.SET_LEDGER_FOR_PAYMENTS_BOOKING);
      }
    } catch (e) {
      return;
    }
  };

  const handleClickCreateClearingLedger = async () => {
    dispatch(showLoader());

    if (!clearingLedger) {
      try {
        await createClearingLedger(erpId!);
      } catch (error) {
        dispatch(hideLoader());
        const errorData = error.response?.data;

        if (errorData?.errorCode !== CLEARING_LEDGER_ALREADY_EXIST_ERROR_CODE) {
          return alert.open(
            t("Errors.Common.Alerts.AlertTitles.Error"),
            "We have been unable to create your clearing ledger, please try again later.",
            [{ text: "Cancel" }],
          );
        }
      }

      try {
        const data = await fetchClearingLedger(erpId!);
        dispatch(setClearingLedger(data));
      } catch (e) {
        dispatch(hideLoader());
        return alert.open(
          t("Errors.Common.Alerts.AlertTitles.Error"),
          "We have been unable to retrieve your clearing ledger, please try again later.",
          [{ text: "Cancel" }],
        );
      }
    }

    if (isRegistrationFlow) await redirectToNextStep();

    dispatch(hideLoader());
  };

  return (
    <ClearingLedgerContainer>
      <Divider />
      <Box p={2}>
        <Button
          color="primary"
          fullWidth
          type="submit"
          variant="contained"
          onClick={handleClickCreateClearingLedger}>
          Create clearing ledger
        </Button>
      </Box>
    </ClearingLedgerContainer>
  );
};

export default CreateClearingLedgerView;
