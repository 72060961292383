import { useState } from "react";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import { IconButton, InputAdornment } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { CommonTextField } from "@APP/components";

const PasswordField = (props: TextFieldProps) => {
  const [isShow, setIsShow] = useState(false);

  return (
    <CommonTextField
      {...props}
      type={isShow ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setIsShow(!isShow)}
              edge="end"
              size="large"
              aria-pressed={isShow}>
              {isShow ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordField;
