import { RequestPagination } from "@APP/types";
import request from "@APP/services/api/request";
import CONFIG from "@APP/config";

/* =============================================== */
/* ================== Customers ================== */
/* =============================================== */

export const fetchCustomers = async (erpId: string, pagination?: RequestPagination) => {
  const { page, entries } = { page: 0, entries: 50, ...pagination };

  const response = await request({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/erp/${erpId}/customers`,
    params: {
      page: `${page ? page * entries + 1 : page * entries};;;${entries}`,
    },
  });

  return response.data;
};

export const fetchCustomerById = async (erpId: string, customerId: string) => {
  const response = await request({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/erp/${erpId}/customers/${customerId}`,
  });

  return response.data;
};
