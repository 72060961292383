import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TFunction, useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  lighten,
  Link,
  List,
  ListItemAvatar,
  ListItemButton,
  Typography,
  FormHelperText,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import * as Yup from "yup";
import clsx from "clsx";
import AccountBalanceIcon from "@mui/icons-material/AccountBalanceRounded";
import BusinessIcon from "@mui/icons-material/ApartmentRounded";
import PersonOutline from "@mui/icons-material/Person";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIosIcon from "@mui/icons-material/ChevronLeft";

import { SCREEN_PATHS } from "@APP/navigation";
import { CommonTextField, Page } from "@APP/components";
import {
  fetchUserData,
  getSelectedRegisteredCompany,
  getUser,
  hideLoader,
  setRegisteredCompanySearchingValue,
  setSelectedRegisteredCompany,
  setSelectedRegisteredDirector,
  showLoader,
} from "@APP/redux";
import {
  createOrganisation,
  getCompanyByKeyword,
  getDirectorsByRegistrationNumber,
  refreshToken,
} from "@APP/services/api";
import { useAlert } from "@APP/hooks";
import { AppLocalStorage } from "@APP/services";
import { CompanyType } from "@APP/constants";
import {
  CreateOrganisationCompanyType,
  GetCompanyByKeywordType,
  GetDirectorsByRegistrationNumberType,
} from "@APP/types";

enum CompanyStatus {
  ACTIVE = "active",
}

const registeredCompanyValidationSchema = (
  t: TFunction,
  companyList: GetCompanyByKeywordType[] | null,
) =>
  Yup.object().shape({
    keyword: Yup.string().test(
      "keywordSearched",
      t("Errors.Registration.Validation.CompanyRequired"),
      (value) => {
        return Boolean(companyList && value);
      },
    ),
    company: Yup.string().nullable().required("Please select your Company from the list."),
    director: Yup.string().nullable().required("Please select your Director from the list."),
  });

const useStyles = makeStyles((theme) => ({
  fieldset: {
    border: 0,
    marginBottom: theme.spacing(2),
  },
  legend: {
    marginBottom: theme.spacing(2),
  },
  grid: {
    backgroundColor: lighten(theme.palette.secondary.main, 0.9),
    padding: theme.spacing(2, 0),
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(3),
  },
  gridItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(2),
      "&:last-child": {
        marginBottom: 0,
      },
    },
  },
  helperText: {
    ...theme.typography.body2,
  },
  gridText: {
    fontWeight: "bold",
    marginLeft: theme.spacing(2),
  },
  list: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    overflowY: "auto",
    maxHeight: 300,
    borderRadius: theme.shape.borderRadius < 20 ? theme.shape.borderRadius : 20,
  },
  listItem: {
    borderRadius: theme.shape.borderRadius < 20 ? theme.shape.borderRadius : 20,
    cursor: "pointer",
    "&.Mui-selected": {
      backgroundColor: lighten(theme.palette.primary.main, 0.5),
      color: theme.palette.secondary.contrastText,
    },
    "&.Mui-selected:hover": {
      backgroundColor: lighten(theme.palette.primary.main, 0.5),
      color: theme.palette.secondary.contrastText,
    },
  },
  avatarIcon: {
    minWidth: 30,
    [theme.breakpoints.up("md")]: {
      minWidth: 40,
    },
  },
}));

const RegisteredCompanyView = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const alert = useAlert();
  const dispatch = useDispatch();

  const user = useSelector(getUser);
  const { selectedCompany, selectedDirector, searchingValue } = useSelector(
    getSelectedRegisteredCompany,
  );

  const [companyList, setCompanyList] = useState<GetCompanyByKeywordType[] | null>(null);
  const [directorsList, setDirectorsList] = useState<GetDirectorsByRegistrationNumberType[] | null>(
    null,
  );

  const handleNextScreen = () => history.push(SCREEN_PATHS.SETUP_BANK_ACCOUNTS);
  const handleBackNavigation = () => history.push(SCREEN_PATHS.COMPANY_TYPE_VIEW);

  const handleOnSubmit = async () => {
    try {
      if (!selectedCompany || !user || !user.username || !user.phone) throw new Error("");

      dispatch(showLoader());

      const body: CreateOrganisationCompanyType = {
        companyName: selectedCompany?.title,
        companyNumber: selectedCompany?.company_number,
        orgType: CompanyType.RegisteredCompany,
      };

      await createOrganisation(user.username, user.phone, body);
      const { token } = await refreshToken();
      AppLocalStorage.setItem("auth.token", token);
      await dispatch(fetchUserData());
      dispatch(hideLoader());

      handleNextScreen();
    } catch (error) {
      dispatch(hideLoader());
      alert.open(
        t("Errors.Common.Alerts.AlertTitles.Error"),
        t("Registration.RegisteredCompanyView.ErrorTextFailedToCreateOrganisation."),
        [{ text: "Okay" }],
      );
    }
  };

  const {
    values,
    errors,
    handleSubmit,
    touched,
    setFieldValue,
    handleBlur,
    handleChange,
    setTouched,
    validateField,
  } = useFormik({
    initialValues: {
      keyword: searchingValue,
      company: selectedCompany,
      director: selectedDirector,
    },
    validationSchema: registeredCompanyValidationSchema(t, companyList),
    onSubmit: handleOnSubmit,
  });

  useEffect(() => {
    setFieldValue("company", selectedCompany?.title || null);
  }, [selectedCompany]);

  useEffect(() => {
    setFieldValue("director", selectedDirector?.name || null);
  }, [selectedDirector]);

  useEffect(() => {
    (async () => {
      if (values.keyword) {
        await searchCompany();
      }

      if (selectedCompany) {
        await selectCompany(selectedCompany);
      }
    })();
  }, []);

  const searchCompany = async () => {
    dispatch(setRegisteredCompanySearchingValue(values.keyword));
    if (values.keyword.length < 1) {
      setCompanyList(null);
      return;
    }

    try {
      dispatch(showLoader());

      const { data } = await getCompanyByKeyword(values.keyword);

      const activeCompanies = data.items.filter(
        (company: GetCompanyByKeywordType) => company.company_status === CompanyStatus.ACTIVE,
      );

      setCompanyList(activeCompanies);
    } catch (error) {
      setCompanyList([]);
    }
    validateField("keyword");
    dispatch(hideLoader());
  };

  const handleClickSearch = async () => {
    await searchCompany();
    setDirectorsList(null);
    dispatch(setSelectedRegisteredDirector(null));
    dispatch(setSelectedRegisteredCompany(null));
    await setTouched({ keyword: true }, true);
  };

  const selectCompany = async (company: GetCompanyByKeywordType) => {
    try {
      const { data } = await getDirectorsByRegistrationNumber(company.company_number);

      const activeDirectors = data.items.filter(
        (director: GetDirectorsByRegistrationNumberType) => director.resigned_on === null,
      );
      setDirectorsList(activeDirectors);
    } catch (error) {
      setDirectorsList([]);
    }
  };

  const handleSelectCompany = async (company: GetCompanyByKeywordType) => {
    dispatch(setSelectedRegisteredCompany(company));
    dispatch(setSelectedRegisteredDirector(null));

    await selectCompany(company);
  };

  const handleKeyPressOnSearchInput = async (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      await searchCompany();
      setDirectorsList(null);
      dispatch(setSelectedRegisteredDirector(null));
      dispatch(setSelectedRegisteredCompany(null));
      await setTouched({ keyword: true }, true);
    }
  };

  const renderCompanySearch = () => (
    <fieldset className={clsx(classes.fieldset)}>
      <legend className={clsx(classes.legend)}>
        <Typography variant="h4" component="h3">
          Companies House
        </Typography>
        <Typography variant="caption" component="p">
          Search by Company Name or Registration Number.
        </Typography>
      </legend>

      <Box mt={1}>
        <CommonTextField
          label="Company Name or Registration Number"
          error={Boolean(touched.keyword && errors.keyword)}
          helperText={touched.keyword && errors.keyword}
          fullWidth
          margin="none"
          name="keyword"
          onBlur={handleBlur}
          onChange={handleChange}
          onKeyDown={handleKeyPressOnSearchInput}
          type="text"
          value={values.keyword}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <IconButton onClick={handleClickSearch} size="large">
                <SearchIcon />
              </IconButton>
            ),
          }}
          autoComplete="organization"
        />
      </Box>
    </fieldset>
  );

  const renderCompanyList = () => {
    if (companyList === null) {
      return (
        <Card elevation={4}>
          <Box p={2} maxHeight={300}>
            <Typography>
              Please search for your company by Company Name or Registration Number.
            </Typography>
          </Box>
        </Card>
      );
    }

    if (companyList.length === 0)
      return (
        <Card elevation={4}>
          <Box p={2} maxHeight={300}>
            <Typography>{t("Errors.Registration.Messages.CompanyHouse")}</Typography>
          </Box>
        </Card>
      );

    return (
      <>
        <List className={classes.list} component={Card} elevation={4}>
          {companyList.map((company) => (
            <ListItemButton
              className={classes.listItem}
              selected={company.company_number === selectedCompany?.company_number}
              key={company.company_number}
              onClick={() => handleSelectCompany(company)}
              aria-pressed={company.company_number === selectedCompany?.company_number}>
              <ListItemAvatar className={classes.avatarIcon}>
                <PersonOutline fontSize="medium" color="primary" />
              </ListItemAvatar>
              <Typography variant="body2">{company.title}</Typography>
            </ListItemButton>
          ))}
        </List>
        {touched.company && errors.company && (
          <FormHelperText className={classes.helperText} error>
            {errors.company}
          </FormHelperText>
        )}
      </>
    );
  };

  const renderDirectorList = () => {
    if (directorsList === null) {
      return (
        <Card elevation={4}>
          <Box p={2} maxHeight={300}>
            <Typography>
              Please search for your company by Company Name or Registration Number.
            </Typography>
          </Box>
        </Card>
      );
    }

    if (directorsList.length === 0)
      return (
        <Card elevation={4}>
          <Box p={2} maxHeight={300}>
            <Typography>{t("Errors.Registration.Messages.Directors")}</Typography>
          </Box>
        </Card>
      );

    return (
      <>
        <List className={classes.list} component={Card} elevation={4}>
          {directorsList.map((director) => (
            <ListItemButton
              className={classes.listItem}
              selected={director.name === selectedDirector?.name}
              key={director.name}
              onClick={() => dispatch(setSelectedRegisteredDirector(director))}
              aria-pressed={director.name === selectedDirector?.name}>
              <ListItemAvatar className={classes.avatarIcon}>
                <PersonOutline fontSize="medium" color="primary" />
              </ListItemAvatar>
              <Typography variant="body2">{director.name}</Typography>
            </ListItemButton>
          ))}
        </List>
        {touched.director && errors.director && (
          <FormHelperText className={classes.helperText} error>
            {errors.director}
          </FormHelperText>
        )}
      </>
    );
  };

  const handleKeyPressOnSubmitForm = async (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <Page>
      <Card elevation={4}>
        <form onSubmit={handleSubmit} onKeyDown={handleKeyPressOnSubmitForm}>
          <CardHeader
            title={t("Common.CompanyType.RegisteredCompany")}
            subheader={t("Registration.RegisteredCompany.CardSubheader")}
            data-testid="registered-company-header"
          />
          <Divider />
          <CardContent>
            <Box>
              <Box mb={2}>
                <Typography variant="h2" component="h2" color="primary" align="center">
                  Please provide:
                </Typography>
              </Box>

              <Grid container className={classes.grid}>
                <Grid item md={6} xs={12} className={classes.gridItem}>
                  <Box display="flex" alignItems="center">
                    <BusinessIcon color="secondary" fontSize="large" />
                    <Typography
                      variant="body2"
                      color="secondary"
                      className={clsx(classes.gridText)}>
                      Your Company Name
                    </Typography>
                  </Box>
                </Grid>

                <Grid item md={6} xs={12} className={clsx(classes.gridItem)}>
                  <Box display="flex" alignItems="center">
                    <AccountBalanceIcon color="secondary" fontSize="large" />
                    <Typography
                      variant="body2"
                      color="secondary"
                      className={clsx(classes.gridText)}>
                      {t("Common.RequiredBankAccountText")}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <Grid container spacing={5}>
                <Grid item xs={12} md={5}>
                  {renderCompanySearch()}
                </Grid>

                <Grid item xs={12} md={7}>
                  <Box mb={5}>
                    <Typography variant="h4" component="h3">
                      Select your Company below:
                    </Typography>
                  </Box>
                  {renderCompanyList()}
                  <Box mb={3} mt={3}>
                    <Typography variant="h4" component="h3">
                      Select your Director:
                    </Typography>
                  </Box>
                  {renderDirectorList()}
                </Grid>
              </Grid>
            </Box>
            <Box textAlign="center" pt={4}>
              <Typography variant="body1">
                The company information displayed here is retrieved from Companies House{" "}
                <Link
                  variant="body1"
                  href="https://www.gov.uk/file-changes-to-a-company-with-companies-house"
                  target="_blank"
                  data-testid="companies-house-link"
                  rel="noopener noreferrer">
                  click here
                </Link>{" "}
                if you would like to request a change to this information.
              </Typography>
            </Box>
          </CardContent>
          <Box p={2}>
            <Box mb={2}>
              <Button color="primary" fullWidth type="submit" variant="contained">
                Confirm
              </Button>
            </Box>
            <Button
              onClick={handleBackNavigation}
              color="primary"
              fullWidth
              startIcon={<ArrowBackIosIcon />}
              type="button"
              variant="outlined">
              Back
            </Button>
          </Box>
        </form>
      </Card>
    </Page>
  );
};

export default RegisteredCompanyView;
