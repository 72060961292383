import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";

import { formatPhoneNumber } from "@APP/utils";

type Props = {
  name?: string;
  email?: string;
  mobile?: string;
  address?: string;
};

const useStyles = makeStyles((theme) => ({
  tableCell: {
    border: "none",
    paddingBottom: theme.spacing(0),
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
  smallCell: {
    width: 160,
  },
}));

//add minus text, when user don't have filled field, it will be update in future

const CustomerInformation = ({ name, email, mobile, address }: Props) => {
  const classes = useStyles();

  return (
    <Card elevation={12}>
      <CardHeader title="Customer" data-testid="customer-card-header" />
      <Divider />
      <CardContent>
        <Table>
          <caption className="visuallyHidden">Customer information table</caption>
          <TableBody>
            <TableRow>
              <TableCell
                className={clsx(classes.tableCell, classes.smallCell)}
                component="th"
                scope="row">
                <Typography variant="h6" component="p" data-testid="customer-name-field-label">
                  Customer Name
                </Typography>
              </TableCell>
              <TableCell className={classes.tableCell}>{name || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                className={clsx(classes.tableCell, classes.smallCell)}
                component="th"
                scope="row">
                <Typography variant="h6" component="p" data-testid="email-field-label">
                  Email
                </Typography>
              </TableCell>
              <TableCell className={classes.tableCell}>{email || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                className={clsx(classes.tableCell, classes.smallCell)}
                component="th"
                scope="row">
                <Typography variant="h6" component="p" data-testid="mobile-number-field-label">
                  Mobile Number
                </Typography>
              </TableCell>
              <TableCell className={classes.tableCell}>{formatPhoneNumber(mobile)}</TableCell>
            </TableRow>
            {address && (
              <TableRow>
                <TableCell
                  className={clsx(classes.tableCell, classes.smallCell)}
                  component="th"
                  scope="row">
                  <Typography variant="h6" component="p" data-testid="mobile-number-field-label">
                    Address
                  </Typography>
                </TableCell>
                <TableCell className={classes.tableCell}>{address}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default CustomerInformation;
