import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Button, useMediaQuery, useTheme } from "@mui/material";

import { getDashboardAppLoading, getUser } from "@APP/redux";
import { AppRouter, RouteConfig } from "@APP/navigation";
import { CLOSE_SIDEBAR_WIDTH, OPEN_SIDEBAR_WIDTH, TOP_BAR_HEIGHT } from "@APP/styles";
import { Loader } from "@APP/components";
import { useAlert, useNetwork } from "@APP/hooks";

import SideBar from "./SideBar";
import TopBar from "./TopBar";
import MenuButton from "./MenuButton";

interface Props {
  routes: RouteConfig[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: TOP_BAR_HEIGHT,
  },
  wrapperWithFullSideBar: {
    [theme.breakpoints.up("sm")]: {
      paddingLeft: OPEN_SIDEBAR_WIDTH,
      transition: theme.transitions.create("paddingLeft", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  },
  wrapperWithSmallSideBar: {
    [theme.breakpoints.up("sm")]: {
      paddingLeft: CLOSE_SIDEBAR_WIDTH,
      transition: theme.transitions.create("paddingLeft", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    height: "100%",
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "hidden",
  },
  routerContent: {
    // height: `calc(100vh - ${TOP_BAR_HEIGHT}px)`,
    // paddingBottom: theme.spacing(3),
    // paddingTop: theme.spacing(3),
    // scrollBehavior: "smooth",
    // overflow: "scroll",
    overflow: "hidden",
    height: "100%",
  },
  skipToMainContentLink: {
    position: "fixed",
    right: "-9999px",
    zIndex: 1101, // 1100 is the z-index of the top bar
    opacity: 0,
    "&:focus": {
      top: theme.spacing(1),
      right: theme.spacing(1),
      zIndex: 1101,
      opacity: 1,
    },
  },
}));

const DashboardLayout = ({ routes }: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const alert = useAlert();
  const isNetworkConnect = useNetwork();
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const [isFullSideBar, setIsFullSideBar] = useState(false);

  const user = useSelector(getUser);
  const isLoading = useSelector(getDashboardAppLoading);

  useEffect(() => {
    if (!isNetworkConnect) {
      return alert.open(
        t("Errors.Common.Alerts.InternetConnection.Title"),
        t("Errors.Common.Alerts.InternetConnection.Message"),
        [{ text: "Reconnect", onClick: () => window.location.reload() }],
      );
    }

    if (isNetworkConnect && alert.showAlert) {
      alert.close();
    }
  }, [isNetworkConnect]);

  useEffect(() => {
    setIsFullSideBar(!isPhone);
  }, [isPhone]);

  return (
    <div className={classes.root}>
      {isLoading && <Loader isFullSideBar={isFullSideBar} />}
      <Button
        className={classes.skipToMainContentLink}
        href="#maincontent"
        variant="text"
        color="primary">
        Skip to main content
      </Button>
      {!(isFullSideBar && isPhone) && (
        <MenuButton
          onButtonClick={() => setIsFullSideBar(!isFullSideBar)}
          isFullSideBar={isFullSideBar}
        />
      )}
      <TopBar username={user?.org?.name} isFullSideBar={isFullSideBar} />
      <SideBar onMobileClose={() => setIsFullSideBar(false)} isFullSideBar={isFullSideBar} />
      <main
        id="maincontent"
        className={clsx(classes.wrapper, {
          [classes.wrapperWithFullSideBar]: isFullSideBar,
          [classes.wrapperWithSmallSideBar]: !isFullSideBar,
        })}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Box className={classes.routerContent}>
              <AppRouter routes={routes} />
            </Box>
          </div>
        </div>
      </main>
    </div>
  );
};

export default DashboardLayout;
