import { ChangeEvent } from "react";
import { Avatar, Box, Grid, ListItemText, MenuItem, Paper } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { ErpBanksAccounts } from "@APP/types";
import { CommonTextField, IconWithTooltip } from "@APP/components";
import CONFIG from "@APP/config";
import { handleAriaActiveDescendantChange } from "@APP/utils";

import {
  BankAccountExtendedWithLedger,
  NO_LINKED_BANK_ACCOUNT_VALUE,
} from "./SetLedgerForPaymentsBooking";

const useStyles = makeStyles((theme) => ({
  accountContainer: {
    padding: theme.spacing(1, 2),
    margin: theme.spacing(1, 0),
    width: "100%",
    "& .MuiSelect-select:focus": {
      borderRadius: theme.shape.borderRadius,
    },
  },
  bankLogo: {
    height: 25,
    width: 25,
    marginRight: theme.spacing(1.5),
  },
  list: {
    "& .MuiTypography-root": {
      width: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },
}));

type Props = {
  bankAccount: BankAccountExtendedWithLedger;
  erpBanksAccounts: ErpBanksAccounts[];
  handleChangeLedger: (
    bankAccount: BankAccountExtendedWithLedger,
  ) => (event: ChangeEvent<{ value: unknown }>) => void;
};

const BankAccountWithSelectableLedger = ({
  bankAccount,
  handleChangeLedger,
  erpBanksAccounts,
}: Props) => {
  const classes = useStyles();

  return (
    <Paper
      className={classes.accountContainer}
      component={(props) => <Grid container alignItems="center" component="tr" {...props} />}>
      <Grid item xs={6} md={7} component="td">
        <Grid container>
          <Grid item xs={12} md={7}>
            <Box textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden" width="100%">
              <ListItemText
                className={classes.list}
                primary={bankAccount.account.identification}
                id={`bank-${bankAccount.account.identification}-label`}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box display="flex" alignItems="center">
              <Avatar
                className={classes.bankLogo}
                src={bankAccount.bankInfo?.logo}
                alt={bankAccount.bankInfo?.fullName}
              />
              <ListItemText className={classes.list} primary={bankAccount.bankInfo?.fullName} />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6} md={5} component="td" display="flex">
        <CommonTextField
          fullWidth
          select
          label="Bank account from accounting package"
          id="account-select"
          hiddenLabel
          name={`input-selector-${bankAccount.accountId}`}
          inputProps={{ id: `selector-${bankAccount.accountId}-input` }}
          InputLabelProps={{ htmlFor: `selector-${bankAccount.accountId}-input` }}
          value={
            bankAccount.ledgerAccount?.accountNumber ||
            bankAccount.ledgerAccount?.accountId === NO_LINKED_BANK_ACCOUNT_VALUE
              ? bankAccount.ledgerAccount.accountId
              : "No matching bank account was found."
          }
          onChange={handleChangeLedger(bankAccount)}
          SelectProps={{
            MenuProps: {
              MenuListProps: {
                "aria-activedescendant":
                  bankAccount.ledgerAccount?.accountId === NO_LINKED_BANK_ACCOUNT_VALUE
                    ? `${NO_LINKED_BANK_ACCOUNT_VALUE}-${bankAccount.accountId}-option`
                    : `ledger-bank-account-${bankAccount.accountId}-option-${bankAccount.ledgerAccount?.accountId}`,
                onFocus: handleAriaActiveDescendantChange,
              },
            },
          }}>
          {!bankAccount.ledgerAccount?.accountNumber && (
            <MenuItem value="No matching bank account was found" disabled>
              No matching bank account was found.
            </MenuItem>
          )}
          <MenuItem
            value={NO_LINKED_BANK_ACCOUNT_VALUE}
            id={`${NO_LINKED_BANK_ACCOUNT_VALUE}-${bankAccount.accountId}-option`}>
            No linked bank account.
          </MenuItem>
          {erpBanksAccounts?.map(({ accountName, accountId, accountNumber }) => (
            <MenuItem
              key={accountName || accountNumber}
              value={accountId}
              id={`ledger-bank-account-${bankAccount.accountId}-option-${accountId}`}>
              {accountName || accountNumber}
            </MenuItem>
          ))}
        </CommonTextField>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          ml={1.5}
          data-testid="tooltip-match-acc-not-found">
          <IconWithTooltip
            title={`It is important that we match the bank accounts in this system to the bank accounts you nominated in your accounting package. This ensures your payments in and out are updated correctly. If you are in any doubt contact us at ${CONFIG.SUPPORT_EMAIL}.`}
            infoIconSize="medium"
            placement="bottom"
          />
        </Box>
      </Grid>
    </Paper>
  );
};

export default BankAccountWithSelectableLedger;
