import { Box, Typography } from "@mui/material";

type Props = {
  message: string;
  emptyListDataTestId?: string;
};

const EmptyList = ({ message, emptyListDataTestId }: Props) => {
  return (
    <Box display="flex" flexGrow={1} justifyContent="center" alignItems="center" textAlign="center">
      <Typography variant="body1" data-testid={emptyListDataTestId}>
        {message}
      </Typography>
    </Box>
  );
};

export default EmptyList;
