import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { format, isBefore, isEqual } from "date-fns";
import {
  Box,
  CircularProgress,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { getForecastBalances } from "@APP/redux";
import { ActiveCheckbox, EmptyList, Table } from "@APP/components";
import { formatCurrency, formatDisplayedDate } from "@APP/utils";
import { API_DATE_FORMAT } from "@APP/constants";

import { ForecastInvoiceWithSelected } from "../CashflowForecastView";

type TableTitle = {
  title: string;
  testId: string;
};

export interface TableProps {
  tableTitles: TableTitle[];
  tableKey: string;
  handleSelectAllRows: (selectedStatus: boolean) => void;
  emptyListMessage: string;
}
interface Props extends TableProps {
  data: null | ForecastInvoiceWithSelected[];
  handleSelectRow: (item: ForecastInvoiceWithSelected) => void;
}

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 475,
  },
  tableHead: {
    "& th": {
      backgroundColor: theme.palette.common.white,
    },
  },
  dueDateCell: {
    minWidth: 120,
  },
}));

const InvoiceTable = ({
  tableTitles,
  data,
  tableKey,
  handleSelectAllRows,
  handleSelectRow,
  emptyListMessage,
}: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const balances = useSelector(getForecastBalances);

  const [page, setPage] = useState(0);
  const [entries, setEntries] = useState(5);

  const lengthOfSelected = useMemo(() => data?.filter((item) => item.selected).length ?? 0, [data]);

  const isEveryEntrySelected = useMemo(
    () => data?.length === lengthOfSelected,
    [data, lengthOfSelected],
  );

  const countOfSelected = useMemo(() => `${lengthOfSelected} / ${data?.length ?? 0}`, [data]);

  const styleIfPaymentOverdue = (dueDate: Date) =>
    isBefore(new Date(dueDate), new Date()) &&
    !isEqual(new Date(dueDate), new Date(format(new Date(), API_DATE_FORMAT)))
      ? theme.palette.warning.main
      : "inherit";

  const handleOnEntriesChange = (entries: number) => {
    setEntries(entries);
    setPage(0);
  };

  const renderHeader = () => {
    return (
      <TableRow className={classes.tableHead}>
        <TableCell padding="checkbox" style={{ minWidth: 100 }}>
          <ActiveCheckbox
            label={countOfSelected}
            onClick={() => {
              handleSelectAllRows(isEveryEntrySelected);
            }}
            checked={isEveryEntrySelected}
            inputProps={{ "aria-label": "count of selected" }}
          />
        </TableCell>
        {tableTitles.map(({ title, testId }) => (
          <TableCell key={title} data-testid={`table-header-cell-${tableKey}-${testId}`}>
            {title}
          </TableCell>
        ))}
      </TableRow>
    );
  };

  const renderRows = (item: ForecastInvoiceWithSelected, index: number) => {
    const { dueDate, customerName, amount, selected } = item;

    return (
      <TableRow key={`forecast-${tableKey}-${index}`}>
        <TableCell padding="checkbox" style={{ minWidth: 100 }}>
          <ActiveCheckbox
            checked={selected}
            onClick={() => handleSelectRow(item)}
            inputProps={{ "aria-label": "select invoice" }}
          />
        </TableCell>
        <TableCell className={classes.dueDateCell}>
          <Typography
            variant="body2"
            component="span"
            color={styleIfPaymentOverdue(dueDate)}
            className="styleOverdue">
            {formatDisplayedDate(dueDate)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            variant="body2"
            component="span"
            color={styleIfPaymentOverdue(dueDate)}
            className="styleOverdue">
            {customerName}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            variant="body2"
            component="span"
            color={styleIfPaymentOverdue(dueDate)}
            className="styleOverdue">
            {formatCurrency(amount.amount, { currency: balances?.totalBalance?.currency })}
          </Typography>
        </TableCell>
      </TableRow>
    );
  };

  if (!data) {
    return (
      <Box height={400} width="100%" display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  if (!data.length)
    return (
      <Box display="flex" minHeight={200}>
        <EmptyList
          message={emptyListMessage}
          emptyListDataTestId={`empty-table-text-${tableKey}`}
        />
      </Box>
    );

  return (
    <Box minHeight={200}>
      <TableContainer data-testid={`table-body-${tableKey}`}>
        <Table
          data={data.slice(page * entries, (page + 1) * entries)}
          renderHeader={renderHeader}
          renderRows={renderRows}
          onPageChange={setPage}
          onEntriesChange={handleOnEntriesChange}
          page={page}
          entries={entries}
          styleOverrides={classes}
          lastPage={data.length && data.length < entries ? 1 : Math.ceil(data.length / entries)}
          maxHeightTableBody={400}
          paginationSelectInputProps={{ id: `table-${tableKey}-rows-per-page-pagination-input` }}
        />
      </TableContainer>
    </Box>
  );
};

export default InvoiceTable;
