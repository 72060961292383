import formUrlEncoded from "form-urlencoded";
import i18next from "i18next";

import CONFIG from "@APP/config";

import { LoginData, RegistrationBody, UserInfoData, BusinessContactStatus } from "@APP/types";
import request from "../request";

export const login = async (email: string, password: string) => {
  try {
    const response = await request<LoginData>(
      {
        method: "POST",
        url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/user-mgmt/public/login`,
        data: { username: email, password },
      },
      { auth: false },
    );
    return response.data;
  } catch (error) {
    if (error.response.status === 500) {
      throw new Error({
        ...error,
        message: i18next.t("Errors.PreAuth.Alerts.Login.Message"),
      });
    }
    throw error;
  }
};

export const getUserInfo = async () => {
  const response = await request<UserInfoData>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/user-mgmt/userInfo`,
  });

  return response.data;
};

export const refreshToken = async () => {
  const response = await request({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/user-mgmt/token_refresh`,
  });
  return response.data;
};

export const requestPasswordReset = async (email: string) => {
  const response = await request({
    method: "POST",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/onboarding/password_reset`,
    data: { username: email },
  });
  return response.data;
};

export const resetPassword = async (code: string, password: string) => {
  const response = await request({
    method: "POST",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/onboarding/password_reset/confirm`,
    params: { code },
    data: { password },
  });

  return response.data;
};

export const verifyResetPasswordCode = async (code: string) => {
  const response = await request({
    method: "POST",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/onboarding/password_reset/check_code`,
    params: { code },
  });

  return response.data;
};

export const verifyEmail = async (email: string) => {
  const response = await request({
    method: "POST",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/onboarding/register`,
    data: formUrlEncoded({ action: "verify_email", email }),
  });

  return response.data;
};

export const register = (body: RegistrationBody) => {
  return request(
    {
      method: "POST",
      url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/onboarding/register`,
      data: formUrlEncoded(body),
    },
    { auth: false },
  );
};

export const createUser = (email: string, phone: string, password: string) => {
  return request(
    {
      method: "POST",
      url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/onboarding/users`,
      data: { email, phone, password },
    },
    { auth: false },
  );
};

export const sendVerificationEmail = (email: string) => {
  return request<void>(
    {
      method: "POST",
      url: `${CONFIG.API.ROOT_URL}/${
        CONFIG.API.VERSION
      }/onboarding/send_verification_email/${encodeURIComponent(email)}`,
    },
    { auth: false },
  );
};

export const updateContactDetails = async (
  orgId: string,
  name: string,
  email: string,
  telephone: string,
) => {
  return await request({
    method: "PUT",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/user-mgmt/organisations/${orgId}/contact`,
    data: {
      name,
      email,
      telephone,
    },
  });
};

export const getBusinessContactEmail = async (orgId: string) => {
  const response = await request<BusinessContactStatus>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/user-mgmt/organisations/${orgId}/contact/status`,
  });
  return response.data;
};

export const resendContactVerificationEmail = async (orgId: string) => {
  return await request({
    method: "POST",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/user-mgmt/organisations/${orgId}/contact/resend_verification_email`,
  });
};

export default {
  login,
  getUserInfo,
  requestPasswordReset,
  verifyEmail,
  register,
  createUser,
  sendVerificationEmail,
  updateContactDetails,
  getBusinessContactEmail,
  resendContactVerificationEmail,
};
