import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getErpId, setBankLedgers, setClearingLedger, useAppDispatch } from "@APP/redux";
import { useAlert, useHandleErrorCodes } from "@APP/hooks/index";
import { capitalize } from "@APP/utils";
import { TabsName } from "@APP/constants";
import { SCREEN_PATHS } from "@APP/navigation";
import { API } from "@APP/services";
import { NO_CLEARING_LEDGER_CREATED_ERROR_CODE } from "@APP/services/api";

const useCheckLedgersLinked = () => {
  const alert = useAlert();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const handleErrorCodes = useHandleErrorCodes();
  const { t } = useTranslation();

  const erpId = useSelector(getErpId);

  const checkLedgers = useCallback(
    async (checkClearingLedger?: boolean) => {
      try {
        if (checkClearingLedger) {
          try {
            const clearingLedger = await API.fetchClearingLedger(erpId!);
            dispatch(setClearingLedger(clearingLedger));
          } catch (e) {
            const errorData = e?.response?.data;

            if (errorData.errorCode !== NO_CLEARING_LEDGER_CREATED_ERROR_CODE) {
              throw e;
            }

            alert.open(
              t(`Errors.ErrorCodes.${NO_CLEARING_LEDGER_CREATED_ERROR_CODE}.Title`, {
                erpId: capitalize(erpId),
              }),
              t(`Errors.ErrorCodes.${NO_CLEARING_LEDGER_CREATED_ERROR_CODE}.Message`, {
                erpId: capitalize(erpId),
              }),
              [
                {
                  text: "Cancel",
                },
                {
                  text: "Create clearing ledger",
                  onClick: () => history.push(SCREEN_PATHS.SETUP_CLEARING_LEDGER),
                },
              ],
              { persistent: true },
            );

            return false;
          }
        }

        const { data: bankLedgers } = await API.fetchLinkedERPLedgers(erpId!);
        dispatch(setBankLedgers(bankLedgers));

        if (!bankLedgers.length) {
          alert.open(
            t("Errors.InvoiceCreation.Alerts.APLedgersMissing.Title", {
              ACCOUNTING_PACKAGE: capitalize(erpId),
            }),
            t("Errors.InvoiceCreation.Alerts.APLedgersMissing.Message", {
              ACCOUNTING_PACKAGE: capitalize(erpId),
            }),
            [
              {
                text: "Cancel",
              },
              {
                text: "Select Bank Ledgers",
                onClick: () =>
                  history.push(`${SCREEN_PATHS.SETTINGS}?tab=${TabsName.ACCOUNTING_PACKAGE}`),
              },
            ],
            { persistent: true },
          );

          return false;
        }
        return true;
      } catch (e) {
        const errorData = e?.response?.data;

        const isHandled = handleErrorCodes(errorData?.errorCode);

        if (!isHandled)
          history.push({
            pathname: SCREEN_PATHS.APP_ERROR,
            state: { errorMessage: t("Errors.InvoiceCreation.Alerts.FetchBank.Message") },
          });

        return false;
      }
    },
    [handleErrorCodes, erpId],
  );

  return checkLedgers;
};

export default useCheckLedgersLinked;
