import React from "react";
import queryString from "query-string";
import { useHistory } from "react-router-dom";

import { TabsName } from "@APP/constants";
import { SCREEN_PATHS } from "@APP/navigation";

import CurrentAccountingPackage from "./CurrentAccountingPackage";
import SetupAccountingPackage from "./SetupAccountingPackage";

const AccountingPackage = () => {
  const history = useHistory();
  const queryParams = queryString.parse(history.location?.search ?? "") as { tab: string };

  return (
    <>
      {queryParams.tab === TabsName.ACCOUNTING_PACKAGE && <CurrentAccountingPackage />}
      {queryParams.tab === TabsName.SETUP_ACCOUNTING_PACKAGE && (
        <SetupAccountingPackage externalErpRedirectState={SCREEN_PATHS.SETTINGS} showBackButton />
      )}
    </>
  );
};

export default AccountingPackage;
