import { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useSelector } from "react-redux";

import { MessageCard } from "@APP/components";
import { Custodian } from "@APP/types";
import { SCREEN_PATHS } from "@APP/navigation";
import { getDashboardApp } from "@APP/redux";
import { TabsName } from "@APP/constants";

interface Props {
  banksList: Custodian[] | null;
}

const useStyles = makeStyles((theme) => ({
  bankListWrapper: {
    height: theme.spacing(30),
    minHeight: "88%",
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    position: "relative",
  },
  bankListContent: {
    overflowY: "auto",
    padding: 0,
    backgroundColor: theme.palette.background.paper,
    "&::-webkit-scrollbar-track": {
      background: theme.palette.background.default,
    },
    "&::-webkit-scrollbar": {
      width: theme.spacing(0.6),
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.primary.main,
    },
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  bankLogo: {
    height: 24,
    width: 24,
  },
  bankListSearchTitle: {
    margin: theme.spacing(2, 0, 1),
  },
  noFilteredBanksContent: {
    marginLeft: theme.spacing(2),
    wordBreak: "break-word",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: theme.spacing(30),
    minHeight: "100%",
  },
}));

const SelectedBanksToLinkBankList = ({ banksList }: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const [bankSearchValue, setBankSearchValue] = useState("");

  const { isLoading } = useSelector(getDashboardApp);

  const consentPage = (bankId: string, provider: string, bankName: string) => () => {
    const redirectUrl =
      window.location.pathname === SCREEN_PATHS.SETTINGS
        ? `${window.location.origin}${SCREEN_PATHS.SETTINGS}?tab=${TabsName.BANK_ACCOUNTS}`
        : window.location.origin + window.location.pathname;

    history.push({
      pathname:
        window.location.pathname === SCREEN_PATHS.SETTINGS
          ? SCREEN_PATHS.SETTINGS
          : SCREEN_PATHS.SETUP_BANK_CONSENT,
      search:
        window.location.pathname === SCREEN_PATHS.SETTINGS ? `?tab=${TabsName.CONSENT}` : undefined,
      state: { bankId, provider, bankName, redirectUrl },
    });
  };

  const filteredBanks = useMemo(() => {
    if (bankSearchValue && banksList) {
      return banksList.filter(
        (bank) =>
          bank.fullName.toLowerCase().includes(bankSearchValue.toLowerCase()) ||
          bank.shortName.toLowerCase().includes(bankSearchValue.toLowerCase()),
      );
    }
    return banksList || [];
  }, [bankSearchValue, banksList]);

  return (
    <>
      {!!banksList?.length && (
        <Box mb={2}>
          <Typography
            className={classes.bankListSearchTitle}
            variant="h5"
            data-testid="bank-search-title">
            Search for your bank
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            onChange={(e) => setBankSearchValue(e.currentTarget.value)}
            placeholder="Enter bank name..."
            data-testid="bank-search-input"
          />
        </Box>
      )}
      <Card className={classes.bankListWrapper}>
        <CardContent className={classes.bankListContent} data-testid="bank-accounts-box">
          {!!banksList?.length ? (
            bankSearchValue && !filteredBanks.length ? (
              <Box className={classes.noFilteredBanksContent}>
                <Typography>
                  {`There are no results for ${bankSearchValue}. Please check your spelling or try different keywords.`}
                </Typography>
              </Box>
            ) : (
              filteredBanks.map((bank) => (
                <ListItemButton
                  key={bank.id}
                  onClick={consentPage(bank.id, bank.provider, bank.fullName)}
                  data-testid={`bank-link-item-${bank.id}`}>
                  <ListItemAvatar data-testid={`bank-logo-item-${bank.id}`}>
                    <Avatar className={classes.bankLogo} src={bank.logo} />
                  </ListItemAvatar>
                  <ListItemText primary={bank.fullName} secondary={null} />
                </ListItemButton>
              ))
            )
          ) : (
            <>
              {!isLoading ? (
                <Box mt={3} ml={2} mr={2} mb={3}>
                  <MessageCard type="error">
                    <Typography>
                      We were unable to retrieve the list of banks. Please try again later.
                    </Typography>
                  </MessageCard>
                </Box>
              ) : null}
            </>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default SelectedBanksToLinkBankList;
