import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getErpId, getUser } from "@APP/redux";
import { SCREEN_PATHS } from "@APP/navigation";
import {
  BANK_CONSENT_EXPIRED_ERROR_CODE,
  REQUEST_LIMIT_REACHED_ERROR_CODE,
  UNLINK_MX_BANK_ACCOUNT_ERROR_CODE,
} from "@APP/services/api";
import { ErrorCode } from "@APP/types";
import { capitalize, getErrorMessageByErrorCode } from "@APP/utils";
import { fetchUserData, hideLoader, showLoader, useAppDispatch } from "@APP/redux";
import { TabsName } from "@APP/constants";
import { REPLACEABLE_SENTENCE_FOR_CURRENT_ACCOUNTING_PACKAGE } from "@APP/constants";

import { useAlert } from "..";

const HANDLED_ERROR_CODES: ErrorCode[] = [
  1007, 1230, 1231, 1232, 1233, 1234, 1330, 1332, 1333, 1334, 1335, 3000, 3006, 4005, 5001, 5003,
  6301, 6108, 6109, 63011, 6110, 6111,
];
const CONSENT_REVOKED_ERROR_CODES: ErrorCode[] = [3000, 5001, 6108];

export interface HandleErrorOptions {
  errorType?: "using" | "linking";
  /**
   * List of the error codes that needs to be handled by
   * error handler
   */
  errorCodes?: number[];
  /**
   * List of the error codes that needs to be excluded from
   * error handler
   */
  excludedErrorCodes?: number[];
}

/**
 * A common utility for handling server errors with specific error codes.
 * @returns `handleErrorCodes` function that returns `isHandled` boolean value.
 * A boolean value indicates whether the error was successfully handled by the function.
 */
const useHandleErrorCodes = () => {
  const alert = useAlert();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const erpId = useSelector(getErpId);
  const { t } = useTranslation();

  const user = useSelector(getUser);

  const updateUserData = async () => {
    try {
      dispatch(showLoader());
      await dispatch(fetchUserData());
    } catch (error) {
      history.push(SCREEN_PATHS.APP_ERROR);
    }
    dispatch(hideLoader());
  };

  /**
   * Error handler function.
   * @returns boolean - indicates whether the error was successfully handled by the function.
   */
  const handleErrorCodes = useCallback(
    (
      errorCode: ErrorCode,
      options: HandleErrorOptions = {
        errorType: "linking",
      },
      onClose?: () => void,
    ) => {
      const { errorType } = options;
      const errorCodes =
        options.errorCodes ??
        HANDLED_ERROR_CODES.filter((code) => !options.excludedErrorCodes?.includes(code));

      const errorCodeForHandling = errorCodes.find((code) => code === errorCode);

      if (errorCodeForHandling) {
        const errorMessage = getErrorMessageByErrorCode(errorCode, errorType).replaceAll(
          REPLACEABLE_SENTENCE_FOR_CURRENT_ACCOUNTING_PACKAGE,
          capitalize(erpId!),
        );

        if ([BANK_CONSENT_EXPIRED_ERROR_CODE].includes(errorCodeForHandling as ErrorCode)) {
          alert.open(t("Errors.ErrorCodes.3006.Title"), errorMessage, [
            {
              text: "Re consent",
              onClick: () =>
                history.push(`${SCREEN_PATHS.SETTINGS}?tab=${TabsName.CONSENT_EXPIRED}`),
            },
            {
              text: "Cancel",
              onClick: onClose,
            },
          ]);

          return true;
        }

        if (CONSENT_REVOKED_ERROR_CODES.includes(errorCodeForHandling as ErrorCode)) {
          alert.open(t("Errors.ErrorCodes.5001.Title"), errorMessage, [
            {
              text: "Okay",
              onClick: async () => {
                await updateUserData();
                if (onClose) onClose();
              },
            },
          ]);

          return true;
        }

        if (REQUEST_LIMIT_REACHED_ERROR_CODE === errorCodeForHandling) {
          alert.open(t("Errors.ErrorCodes.6109.Title"), errorMessage, [
            {
              text: "Okay",
              onClick: onClose,
            },
          ]);

          return true;
        }

        if (UNLINK_MX_BANK_ACCOUNT_ERROR_CODE === errorCodeForHandling) {
          alert.open(t("Errors.Common.Alerts.AlertTitles.Failure"), errorMessage);

          return true;
        }

        alert.open(t("Errors.Common.Alerts.AlertTitles.Failure"), errorMessage, [
          {
            text: "Okay",
            onClick: onClose,
          },
        ]);

        return true;
      }

      return false;
    },
    [user],
  );

  return handleErrorCodes;
};

export default useHandleErrorCodes;
