import React from "react";
import { Card, CardContent, CardHeader } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { InvoiceType, Payable, Receivable } from "@APP/types";

import TableInvoices from "./TableInvoices";
import ListWithError from "./ListWithError";

const useStyles = makeStyles(() => ({
  cardContainerContent: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
}));

type Props = {
  title: string;
  titleTestId?: string;
  subTitle: string;
  subTitleTestId?: string;
  data: Array<Receivable | Payable> | null;
  headerTableTitles: Array<{ title: string; testId?: string }>;
  type: InvoiceType;
  onTryAgain: () => void;
  emptyListMessage: string;
};

const InvoiceTableInformation = ({
  title,
  titleTestId,
  subTitle,
  subTitleTestId,
  data,
  headerTableTitles,
  type,
  onTryAgain,
  emptyListMessage,
}: Props) => {
  const classes = useStyles();

  return (
    <Card
      className={classes.cardContainerContent}
      elevation={4}
      data-testid={`table-section-container-${type}`}>
      <CardHeader
        title={title}
        titleTypographyProps={{ "data-testid": titleTestId, component: "h5" }}
        subheader={subTitle}
        subheaderTypographyProps={{ "data-testid": subTitleTestId }}
      />
      <CardContent className={classes.cardContent}>
        {data ? (
          <TableInvoices
            data={data}
            dataType={type}
            headerTableTitles={headerTableTitles}
            emptyListMessage={emptyListMessage}
          />
        ) : (
          <ListWithError dataType={type} onTryAgain={onTryAgain} />
        )}
      </CardContent>
    </Card>
  );
};

export default InvoiceTableInformation;
