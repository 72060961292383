import { SyntheticEvent, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { Box, Button, List, ListItem, ListItemIcon, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneIphoneOutlined from "@mui/icons-material/PhoneIphoneOutlined";
import InfoIcon from "@mui/icons-material/InfoRounded";

import CONFIG from "@APP/config";
import { AppState, logout } from "@APP/redux";
import { AuthForm, AuthLayout, Page } from "@APP/components";
import { SCREEN_PATHS } from "@APP/navigation";

type InformationContent = {
  primaryText: string;
  secondaryText?: string | JSX.Element;
  icon?: JSX.Element;
  alignItems?: "center" | "flex-start";
};

export interface Props {
  auth: AppState["auth"];
  logout: ReturnType<typeof mapDispatchToProps>["logout"];
}

const useStyles = makeStyles((theme) => ({
  listItemIcon: {
    marginTop: 0,
    minWidth: 40,
  },
  listItemText: {
    margin: 0,
  },
  registrationWarning: {
    color: theme.palette.secondary.main,
  },
}));

const RegistrationInformation = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const informationContent: InformationContent[] = [
    {
      primaryText: "An Email Address",
      secondaryText: (
        <>
          We will use your email address as part of our verification process. It will also be shown
          to your customers in any outgoing communications.
          <br />
          <b>Please ensure you choose the email address you wish to display to your customers.</b>
        </>
      ),
      icon: <EmailOutlinedIcon color="primary" />,
      alignItems: "flex-start",
    },
    {
      primaryText: t("Registration.InfoPhoneNumber"),
      secondaryText: (
        <>
          We will use your mobile telephone number as part of our verification process. It will also
          be shown to your customers in any outgoing communications.
          <br />
          <b>
            Please ensure you use the mobile phone number you wish to display to your customers.
          </b>
        </>
      ),
      icon: <PhoneIphoneOutlined color="primary" />,
      alignItems: "flex-start",
    },
  ];

  return (
    <>
      <Box mb={0.5}>
        <Typography variant="h4">For account registration you will need:</Typography>
      </Box>
      <List dense>
        {informationContent.map(({ primaryText, secondaryText, icon, alignItems }) => (
          <ListItem key={primaryText} alignItems={alignItems} disableGutters>
            {icon && <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>}
            <Box mb={1}>
              <Box mb={1}>
                <Typography variant="subtitle2">{primaryText}</Typography>
              </Box>
              <Typography variant="body2">{secondaryText}</Typography>
            </Box>
          </ListItem>
        ))}
      </List>
    </>
  );
};

export const RegistrationInformationView = ({ auth }: Props) => {
  const history = useHistory();
  const classes = useStyles();
  useEffect(() => {
    if (auth.authenticated) logout();
  }, []);

  const handleOnContinue = (event: SyntheticEvent) => {
    event.stopPropagation();
    history.push(SCREEN_PATHS.REGISTRATION_TERMS_AND_CONDITIONS);
  };

  const renderMainContent = () => (
    <>
      {isMobile && CONFIG.PIS_PROVIDER === "Maverick" && (
        <Box pb={2} display="flex">
          <InfoIcon fontSize="medium" color="secondary" />
          <Typography className={classes.registrationWarning} variant="h4" align="center">
            Registration is easiest on your desktop. Go to <b>{CONFIG.API.ROOT_URL}/registration</b>{" "}
            on your desktop to register.
          </Typography>
        </Box>
      )}
      <AuthForm backwardPath={SCREEN_PATHS.LOGIN}>
        <Box mb={3}>
          <Box mb={2}>
            <RegistrationInformation />
          </Box>
          <Typography align="center" variant="h6" component="p">
            The process of registration takes around 5 minutes.
          </Typography>
        </Box>
        <Button
          color="primary"
          fullWidth
          size="large"
          variant="contained"
          type="button"
          onClick={handleOnContinue}
          data-testid="continue-button">
          Continue
        </Button>
      </AuthForm>
    </>
  );

  return (
    <Page title="Get Started" display="flex" height="100%" p={0}>
      <AuthLayout mainContent={renderMainContent()} />
    </Page>
  );
};

const mapStateToProps = ({ auth }: AppState) => ({ auth });

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>) => ({
  logout: () => dispatch(logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationInformationView);
