import { useMemo } from "react";
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import clsx from "clsx";

import { InstalmentDetailsResponse, InstalmentStatus, StandingOrderResponse } from "@APP/types";
import { INSTALMENT_STATUS_COLOR } from "@APP/constants";
import { formatCurrency, formatDisplayedDate } from "@APP/utils";

interface Props {
  instalmentDetails: InstalmentDetailsResponse;
  standingOrder?: StandingOrderResponse;
}

export const getInstalmentStatusLabel = (status: InstalmentStatus) => {
  switch (status) {
    case InstalmentStatus.Pending:
      return "Awaiting Approval";
    case InstalmentStatus.InitiationPending:
      return "Awaiting Approval";
    case InstalmentStatus.AwaitingAuthorisation:
      return "Awaiting Approval";
    case InstalmentStatus.AwaitingUpload:
      return "Awaiting Approval";
    case InstalmentStatus.Authorised:
      return "In Progress";
    case InstalmentStatus.Accepted:
      return "Accepted";
    case InstalmentStatus.Received:
      return "Received";
  }

  return "Cancelled";
};

const useStyles = makeStyles((theme) => ({
  tableCell: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    hyphens: "auto",
  },
  bodyTableCell: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    border: "none",
  },
}));

const InstalmentDetails = ({ instalmentDetails, standingOrder }: Props) => {
  const classes = useStyles();

  const reversedPayments = useMemo(() => {
    return instalmentDetails.installmentPayments.reverse();
  }, [instalmentDetails]);

  return (
    <Card elevation={12}>
      <CardHeader title="Instalment Details" data-testid="instalment-details-card-header" />
      <Divider />
      {standingOrder?.status && (
        <>
          <Box p={1} pl={2} display="flex" alignItems="center">
            <Box>
              <Typography variant="h5">Status:</Typography>
            </Box>
            <Box
              display="flex"
              alignItems="flex-end"
              color={INSTALMENT_STATUS_COLOR[standingOrder.status]}>
              <Box display="flex" mr={0.4} ml={0.4}>
                <FiberManualRecordIcon />
              </Box>
              <Typography variant="h6">{getInstalmentStatusLabel(standingOrder.status)}</Typography>
            </Box>
          </Box>
          <Divider />
        </>
      )}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCell}>
              <Typography variant="h6">Date</Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
              <Typography variant="h6">Amount</Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
              <Typography variant="h6">Paid</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reversedPayments.map((payment) => (
            <TableRow key={payment.dateTimeInstallment}>
              <TableCell className={clsx(classes.tableCell, classes.bodyTableCell)}>
                <Typography variant="body2">
                  {formatDisplayedDate(payment.dateTimeInstallment)}
                </Typography>
              </TableCell>
              <TableCell className={clsx(classes.tableCell, classes.bodyTableCell)}>
                <Typography variant="body2">
                  {formatCurrency(payment.amount.amount, { currency: payment.amount.currency })}
                </Typography>
              </TableCell>
              <TableCell className={clsx(classes.tableCell, classes.bodyTableCell)}>
                <Typography variant="body2">{payment.status === "Paid" ? "Yes" : ""}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
};

export default InstalmentDetails;
