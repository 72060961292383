import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { Box, Tab, Tabs, useMediaQuery, useTheme, lighten } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { setIdTab } from "@APP/utils";
import { Page, TabPanel } from "@APP/components";
import { TabsName, TabsInterface } from "@APP/constants";
import { SetupBankAccountsView } from "@APP/views";
import CONFIG from "@APP/config";

import AccountingPackage from "./AccountingPackage";
import Account from "./Account";
import TermsAndConditions from "./TermsAndConditions";
import UserDetails from "./UserDetails";
import CardPayments from "./CardPayments";
import OrganisationPage from "./Organisation/OrganisationPage";

const useStyles = makeStyles((theme) => ({
  tabsIndicator: {
    zIndex: -1,
    height: "100%",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: lighten(theme.palette.primary.main, 0.85),
  },
  tabItem: {
    borderRadius: theme.shape.borderRadius,
    minHeight: 40,
  },
  tabsRoot: {
    minHeight: 40,
  },
  tabsScroller: {
    overflowY: "hidden",
  },
}));

const SettingsView = () => {
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles();
  const isLgSizeScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const queryParams = queryString.parse(history.location.search ?? "") as {
    tab: string;
    code?: string;
    error?: string;
  };
  const { t } = useTranslation();

  const TABS: TabsInterface = {
    USER: {
      tabLabel: "User",
      tabsNames: [TabsName.USER],
      getComponent: () => <UserDetails />,
      tab: "USER",
    },
    ORGANISATION: {
      tabLabel: t("Settings.TabLabels.Organisation"),
      tabsNames: [TabsName.ORGANISATION],
      getComponent: () => <OrganisationPage />,
      tab: "ORGANISATION",
    },
    ACCOUNT: {
      tabLabel: "Account",
      tabsNames: [
        TabsName.ACCOUNT,
        TabsName.ACTIVATE_ACCOUNT,
        TabsName.DEACTIVATE_ACCOUNT,
        TabsName.ACCOUNT_SUCCESS,
      ],
      getComponent: () => <Account />,
      tab: "ACCOUNT",
    },
    CARD_PAYMENTS: {
      tabLabel: "Payments",
      tabsNames: [TabsName.CARD_PAYMENTS],
      getComponent: () => <CardPayments />,
      tab: "CARD_PAYMENTS",
    },

    BANK_ACCOUNTS: {
      tabLabel: t("Settings.TabLabels.BankAccounts"),
      tabsNames: [TabsName.BANK_ACCOUNTS, TabsName.CONSENT, TabsName.CONSENT_EXPIRED],
      getComponent: () => <SetupBankAccountsView />,
      tab: "BANK_ACCOUNTS",
    },
    ACCOUNTING_PACKAGE: {
      tabLabel: "Accounting Package",
      tabsNames: [TabsName.ACCOUNTING_PACKAGE, TabsName.SETUP_ACCOUNTING_PACKAGE],
      getComponent: () => <AccountingPackage />,
      tab: "ACCOUNTING_PACKAGE",
    },
    TERMS_OF_USE_AND_PRIVACY_POLICY: {
      tabLabel: "Terms of Use & Privacy Policy",
      tabsNames: [TabsName.TERMS_OF_USE_AND_PRIVACY_POLICY],
      getComponent: () => <TermsAndConditions />,
      tab: "TERMS_OF_USE_AND_PRIVACY_POLICY",
    },
  };

  const [selectedTabIndex, setSelectedTabIndex] = useState<null | number>(null);

  const handleChangeTab = (event: React.SyntheticEvent<{}>, newSelectedTabIndex: number) => {
    setSelectedTabIndex(newSelectedTabIndex);
  };

  const tabs = useMemo(() => CONFIG.FEATURES.AVAILABLE_SETTINGS_TABS.map((tab) => TABS[tab]), []);

  useEffect(() => {
    // In case if the app opened after linking an accounting package with the code or error parameters, navigate the user to the AP screen
    if (!queryParams.tab && selectedTabIndex === null && (queryParams.code || queryParams.error)) {
      return history.push(
        `${history.location.pathname}${history.location.search}&tab=${TabsName.SETUP_ACCOUNTING_PACKAGE}`,
      );
    }

    // Set default tab parameter in URL if missing
    if (!queryParams.tab && selectedTabIndex === null) {
      return history.replace({
        pathname: history.location.pathname,
        search: `tab=${TabsName.USER}`,
      });
    }

    if (!queryParams.tab && selectedTabIndex === null && (queryParams.code || queryParams.error)) {
      return history.push(
        `${history.location.pathname}${history.location.search}&tab=${TabsName.SETUP_ACCOUNTING_PACKAGE}`,
      );
    }

    const indexSelectedTabName = tabs.findIndex(({ tabsNames }) =>
      tabsNames.some((name) => name === queryParams.tab),
    );

    setSelectedTabIndex(indexSelectedTabName === -1 ? 0 : indexSelectedTabName);
  }, [queryParams.tab]);

  useEffect(() => {
    /**
     * To avoid misalignment tab after refreshing page
     */

    window.dispatchEvent(new Event("resize"));

    const indexSelectedTabName = tabs.findIndex(({ tabsNames }) =>
      tabsNames.some((name) => name === queryParams?.tab),
    );

    if (selectedTabIndex === null || selectedTabIndex === indexSelectedTabName) return;

    history.push({
      pathname: history.location.pathname,
      search: `tab=${tabs[selectedTabIndex].tabsNames[0]}`,
    });
  }, [selectedTabIndex]);

  return (
    <Page title="Settings" display="flex" flexDirection="column" height="100%">
      {selectedTabIndex !== null && (
        <>
          <Tabs
            centered={isLgSizeScreen ? undefined : true}
            value={selectedTabIndex}
            textColor="primary"
            onChange={handleChangeTab}
            classes={{ root: classes.tabsRoot, scroller: classes.tabsScroller }}
            TabIndicatorProps={{ className: classes.tabsIndicator }}
            variant={isLgSizeScreen ? "scrollable" : undefined}
            scrollButtons={false}>
            {tabs.map(({ tabLabel }, index) => (
              <Tab
                key={tabLabel}
                label={tabLabel}
                {...setIdTab(index)}
                className={classes.tabItem}
              />
            ))}
          </Tabs>
          <Box display="flex" flexDirection="column" flexGrow={1}>
            {tabs.map(({ getComponent, tabLabel }, index) => (
              <TabPanel value={selectedTabIndex} index={index} key={tabLabel}>
                {getComponent()}
              </TabPanel>
            ))}
          </Box>
        </>
      )}
    </Page>
  );
};

export default SettingsView;
