import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { FooterActionsButtons, Page, ResultNotification, ScreenHeader } from "@APP/components";
import { SCREEN_PATHS } from "@APP/navigation";

const InvoiceFailure = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { state } = useLocation<{
    errorMessage: string;
  }>();

  return (
    <Page title="View your Invoice">
      <ScreenHeader title="View your Invoice" />
      <ResultNotification type="error" title={t("Errors.Common.Alerts.AlertTitles.Failure")}>
        {state?.errorMessage || t("Errors.InvoiceCreation.Messages.ProcessInvoice")}
      </ResultNotification>
      <FooterActionsButtons
        backButtonText="Back to Customer Invoices"
        handleBackButton={() => history.push(SCREEN_PATHS.RECEIVABLES_LIST)}
        continueButtonText="View Invoice details"
        handleContinue={() => history.push(SCREEN_PATHS.RECEIVABLES_CONFIRM)}
      />
    </Page>
  );
};

export default InvoiceFailure;
