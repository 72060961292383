import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Link,
  Typography,
  lighten,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { green, orange, red } from "@mui/material/colors";

import { getCardPaymentApp } from "@APP/redux";
import { Page } from "@APP/components";
import { CardPaymentApplicationStatus } from "@APP/types";
import CONFIG from "@APP/config";
import { SCREEN_PATHS } from "@APP/navigation";
import { TabsName } from "@APP/constants";

const useStyles = makeStyles((theme) => ({
  label: {
    padding: theme.spacing(1, 3),
    borderRadius: theme.shape.borderRadius,
    fontWeight: "bold",
  },
  cardContainer: {
    "&.MuiCardContent-root": {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  cardActions: {
    padding: theme.spacing(2),
  },
  buttonContainer: {
    justifyContent: "center",
    width: "100%",
    "& > *": {
      marginBottom: theme.spacing(2),
    },
    "& > *:last-child": {
      marginBottom: 0,
    },
  },
}));

const CardPayments = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles();
  const isPhoneOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const cardPaymentApp = useSelector(getCardPaymentApp);

  const handleContinueApplicationClick = () => {
    history.push({
      pathname: SCREEN_PATHS.SETUP_PAYMENT_APPLICATION,
      search: `fromPath=${TabsName.CARD_PAYMENTS}`,
    });
  };

  const pendingOrNewInfoText =
    "Your application to make and receive payments is in progress. Please complete application to start using the application.";

  const statusesAndText = {
    New: {
      label: "In Progress",
      colour: orange[600],
      infoText: pendingOrNewInfoText,
    },
    Approved: {
      label: "Approved",
      colour: green[700],
      infoText: "You are setup to accept and make payments.",
    },
    Pending: {
      label: "In Progress",
      colour: orange[600],
      infoText: pendingOrNewInfoText,
    },
    Underwriting: {
      label: "Underwriting",
      colour: orange[600],
      infoText:
        "Your application to make and receive payments has been completed and is currently being reviewed by Maverick. The application must be approved to start using the application.",
    },
    Cancelled: {
      label: "Cancelled",
      colour: red[600],
      infoText:
        "Your application has been cancelled, please contact Maverick to resume your application.",
    },
    Declined: {
      label: "Declined",
      colour: red[600],
      infoText:
        "Your application has been declined, please contact Maverick to discuss your application.",
    },
  };

  const content = useMemo(
    () => (cardPaymentApp && statusesAndText[cardPaymentApp?.status]) || null,
    [cardPaymentApp],
  );

  return (
    <Page
      title="Payment Application"
      style={{ justifyContent: isPhoneOrTablet ? "flex-start" : "center" }}>
      <Grid container justifyContent="center" spacing={5}>
        <Grid item xs={12} md={10} lg={8}>
          <Card elevation={4}>
            <CardHeader
              title="Payments"
              subheader="We use Maverick, our regulated partner to process payments."
            />
            <Divider />
            <CardContent sx={{ marginTop: isPhoneOrTablet ? 1 : 3 }}>
              <Box display="flex" justifyContent="space-around">
                {cardPaymentApp && content ? (
                  <Box
                    display="flex"
                    justifyContent="space-evenly"
                    alignItems="center"
                    flexDirection={isPhoneOrTablet ? "column" : "row"}
                    textAlign="center"
                    columnGap={4}>
                    <Typography
                      className={classes.label}
                      variant="h4"
                      sx={{
                        color: content?.colour,
                        backgroundColor: content?.colour && lighten(content?.colour, 0.92),
                        mb: isPhoneOrTablet ? 2 : 0,
                      }}>
                      {content?.label}
                    </Typography>
                    <Box maxWidth={isPhoneOrTablet ? "100" : "45%"}>
                      <Typography variant="body2">{content?.infoText}</Typography>
                    </Box>
                  </Box>
                ) : (
                  <Typography variant="body2">{t("CardPayments.useApplicationNote")}</Typography>
                )}
              </Box>
            </CardContent>
            <CardActions className={classes.cardActions}>
              <Box className={classes.buttonContainer} display="flex">
                <Box className={classes.buttonContainer} textAlign="center">
                  {(!cardPaymentApp ||
                    cardPaymentApp.status === CardPaymentApplicationStatus.New ||
                    cardPaymentApp.status === CardPaymentApplicationStatus.Pending) && (
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      onClick={handleContinueApplicationClick}>
                      Complete Application
                    </Button>
                  )}
                  {CONFIG.URLS.PAYMENT_PROVIDER.CONTACT_US_URL && (
                    <Button
                      variant="outlined"
                      fullWidth
                      href={CONFIG.URLS.PAYMENT_PROVIDER.CONTACT_US_URL}
                      target="_blank"
                      rel="noopener noreferrer">
                      Maverick Website
                    </Button>
                  )}
                  {CONFIG.URLS.PAYMENT_PROVIDER.TERMS_AND_CONDITIONS_URL && (
                    <Link
                      variant="h6"
                      href={CONFIG.URLS.PAYMENT_PROVIDER.TERMS_AND_CONDITIONS_URL}
                      data-testid="third-party-terms-link"
                      target="_blank"
                      rel="noopener noreferrer">
                      Terms and Conditions
                    </Link>
                  )}
                </Box>
              </Box>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Page>
  );
};

export default CardPayments;
