import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Typography, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";

import { SCREEN_PATHS } from "@APP/navigation";
import { AppLoader, Page } from "@APP/components";
import { API } from "@APP/services";
import { useAlert } from "@APP/hooks";

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    marginTop: theme.spacing(-4),
  },
  icon: {
    fontSize: 120,
  },
}));

const EmailVerificationView = (props: RouteComponentProps<{ email: string }>) => {
  const { email } = props.match.params;
  const classes = useStyles();
  const alert = useAlert();
  const { t } = useTranslation();
  const [isVerified, setIsVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const verify = async () => {
    try {
      await API.verifyEmail(email);
      setIsVerified(true);
    } catch (error) {
      alert.open(
        t("Errors.Common.Alerts.AlertTitles.Error"),
        t("Errors.Common.Alerts.Generic.Message"),
      );
      setIsVerified(false);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    verify();
  }, []);

  return isLoading ? (
    <AppLoader />
  ) : (
    <Page
      title="Verify Email"
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      textAlign="center">
      <Box className={classes.contentContainer} textAlign="center">
        {isVerified ? (
          <CheckIcon className={classes.icon} color="secondary" />
        ) : (
          <ErrorIcon className={classes.icon} color="primary" />
        )}
        <Box mt={3} mb={3}>
          <Typography align="center" variant="h3" gutterBottom>
            {isVerified ? "Thank you!" : t("Errors.Common.Alerts.AlertTitles.Failure")}
          </Typography>
          <Typography
            color={isVerified ? "secondary" : "primary"}
            variant="h5"
            component="p"
            gutterBottom>
            {email}
          </Typography>
        </Box>
        <Box mb={4}>
          <Typography>
            {isVerified
              ? "Your email address has been successfully verified."
              : t("Errors.Registration.Messages.VerifyEmail")}
          </Typography>
        </Box>
        <Button
          href={isVerified ? SCREEN_PATHS.APP_ROOT : SCREEN_PATHS.LOGIN}
          fullWidth
          variant="contained"
          color={isVerified ? "secondary" : "primary"}
          size="large">
          Okay
        </Button>
      </Box>
    </Page>
  );
};

export default EmailVerificationView;
