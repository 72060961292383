import { ComponentType } from "react";
import { RouteProps } from "react-router-dom";

import {
  InvoiceLineItemsView,
  CashflowForecastView,
  CompanyTypeView,
  ConfirmInvoice,
  ConfirmMakePayment,
  CreateCustomer,
  DashboardView,
  DeliveryFailureView,
  DeliverySuccessView,
  EmailRegistrationView,
  EmailVerificationView,
  FailureMakePayment,
  ForgotPasswordView,
  GeneralInvoiceDetails,
  InstalmentDetailsView,
  LoginView,
  LogoutView,
  MakePaymentDetails,
  NoLinkedBankAccountsView,
  NonCreatedRTPsList,
  NotFoundView,
  PasswordRegistrationView,
  PayableConfirmView,
  PayableDetailsView,
  PayableFailure,
  PayableSelectAccountView,
  PayablesListView,
  PayableSuccess,
  PhoneRegistrationView,
  ReceivableBreakdownView,
  ReceivablesConfirmDetails,
  ReceivablesDeliveryAndContactDetails,
  ReceivablesListView,
  RegistrationComplete,
  RegistrationInformationView,
  RegistrationSuccessView,
  ResetPasswordView,
  RTPDetailsView,
  RTPsListView,
  SelectCustomer,
  SettingsView,
  SetupBankConsentView,
  SetupEmailVerificationView,
  SuccessfulMakePayment,
  TermsAndConditionsView,
  UserErrorView,
  SetupBankAccountsView,
  OtherOrgTypesView,
  RegisteredCompanyView,
  SetupCardPaymentsView,
  SoleTraderTypesView,
  InvoiceFailure,
  InvoiceSuccess,
  CustomerDetails,
  CreateClearingLedgerView,
  SetupLedger,
  SetupAccountingPackage,
  NoLinkedAccountingPackageView,
} from "@APP/views";
import CONFIG from "@APP/config";
import { BankAccountExtended } from "@APP/types";
import { ErpId, Provider } from "@APP/constants";
import { DashboardLayout, Page, PageLayout } from "@APP/components";
import { getAvailableFeaturesBasedOnERP } from "@APP/utils";

import { SCREEN_PATHS } from "./paths";
import {
  cardPaymentAppApprovedProtector,
  authRequiredProtector,
  beneficiaryFilledFieldsProtector,
  cardPaymentsProtector,
  clearingLedgerProtector,
  emailVerifiedProtector,
  erpOrganizationFilled,
  failureReceivablesListEmptyProtector,
  haveResultOnPaidInvoiceProtector,
  profileCompletionAndLinkedBankAccountRequiredProtector,
  profileCompletionRequiredProtector,
  receivablesAndPayablesListProtector,
  receivablesDeliverySummaryProtector,
  receivablesEnteringDeliveryDetailsProtector,
  registrationInitRequiredProtector,
  RouteProtector,
  rtpEnteringInstalmentDetailsProtector,
  selectedPayerBankAccountProtector,
  setupLedgerProtector,
  successReceivablesListEmptyProtector,
  userInfoEmptyProtector,
  userSubscriptionProtector,
} from "./protectors";

export interface RouteConfig {
  /**
   * Route path.
   * Must be `absolute` for regular routes and `relative` for modal routes.
   */
  path?: string | string[];
  /**
   * Whether to render path matches the `location.pathname` exactly.
   */
  exact?: boolean;
  /**
   * Component to render.
   */
  component?: ComponentType<any>;
  /**
   * "RouteProtector" function(s) responsible for determining if a route is available or not.
   */
  protector?: RouteProtector | RouteProtector[];
  /**
   * JSX to render whether the path matches the location or not.
   */
  children?: RouteProps["children"];
  /**
   * A collection of child routes.
   * Being passed down to the route's `component` as a property.
   * Must be handled on a component level, e.g. by rendering an additional router.
   */
  routes?: RouteConfig[];
  /**
   * Location to redirect. The new location will override the current location in the history stack.
   */
  redirect?: string;
  /**
   * Modal routes are relative: they appear on top of their parent routes.
   * It means that the parent route can't have `{ exact: true }` in order to support relative paths.
   */
  modal?: boolean;
}

const ROUTES: { [key: string]: RouteConfig[] } = {
  DASHBOARD: [
    {
      path: SCREEN_PATHS.DASHBOARD,
      component: DashboardView,
      protector: [userInfoEmptyProtector, profileCompletionAndLinkedBankAccountRequiredProtector],
    },
  ],
  CUSTOMER_INVOICES: [
    {
      path: SCREEN_PATHS.RECEIVABLES_LIST,
      component: ReceivablesListView,
      protector: [
        userInfoEmptyProtector,
        cardPaymentsProtector,
        receivablesAndPayablesListProtector,
      ],
      exact: true,
    },
    {
      path: SCREEN_PATHS.RECEIVABLE_INSTALMENT_DETAILS,
      component: InstalmentDetailsView,
      protector: [
        userInfoEmptyProtector,
        rtpEnteringInstalmentDetailsProtector,
        cardPaymentsProtector,
      ],
      exact: true,
    },
    {
      path: SCREEN_PATHS.RECEIVABLES_SELECT_CUSTOMER,
      component: SelectCustomer,
      protector: [
        userInfoEmptyProtector,
        cardPaymentsProtector,
        receivablesAndPayablesListProtector,
      ],
      exact: true,
    },
    {
      path: `${SCREEN_PATHS.RECEIVABLES_CUSTOMER_DETAILS}/:customerId`,
      component: CustomerDetails,
      protector: [
        userInfoEmptyProtector,
        cardPaymentsProtector,
        receivablesAndPayablesListProtector,
      ],
      exact: true,
    },
    {
      path: SCREEN_PATHS.RECEIVABLES_CREATE_CUSTOMER,
      component: CreateCustomer,
      protector: [
        userInfoEmptyProtector,
        cardPaymentsProtector,
        receivablesAndPayablesListProtector,
      ],
      exact: true,
    },
    {
      path: SCREEN_PATHS.RECEIVABLES_DELIVERY_DETAILS,
      component: GeneralInvoiceDetails,
      protector: [
        userInfoEmptyProtector,
        cardPaymentsProtector,
        receivablesAndPayablesListProtector,
      ],
      exact: true,
    },
    {
      path: SCREEN_PATHS.RECEIVABLES_LINE_ITEMS,
      component: InvoiceLineItemsView,
      protector: [cardPaymentsProtector],
      exact: true,
    },
    {
      path: SCREEN_PATHS.RECEIVABLES_CONFIRM,
      component: ConfirmInvoice,
      protector: [
        userInfoEmptyProtector,
        cardPaymentsProtector,
        receivablesAndPayablesListProtector,
      ],
      exact: true,
    },
    {
      path: SCREEN_PATHS.RECEIVABLE_FAILURE,
      component: InvoiceFailure,
      protector: [
        userInfoEmptyProtector,
        cardPaymentsProtector,
        receivablesAndPayablesListProtector,
      ],
      exact: true,
    },
    {
      path: SCREEN_PATHS.RECEIVABLE_SUCCESS,
      component: InvoiceSuccess,
      protector: [
        userInfoEmptyProtector,
        cardPaymentsProtector,
        receivablesAndPayablesListProtector,
      ],
      exact: true,
    },
    {
      path: SCREEN_PATHS.RECEIVABLE_DETAILS,
      component: ConfirmInvoice,
      protector: [
        userInfoEmptyProtector,
        cardPaymentsProtector,
        receivablesAndPayablesListProtector,
      ],
      exact: true,
    },
    {
      path: SCREEN_PATHS.RECEIVABLE,
      component: ReceivableBreakdownView,
      protector: [
        userInfoEmptyProtector,
        profileCompletionRequiredProtector,
        cardPaymentsProtector,
      ],
      exact: true,
    },
  ],
  PAYMENT_REQUEST: [
    {
      path: SCREEN_PATHS.PAYMENT_REQUESTS_LIST,
      component: RTPsListView,
      protector: [userInfoEmptyProtector, profileCompletionAndLinkedBankAccountRequiredProtector],
      exact: true,
    },
    {
      path: SCREEN_PATHS.PAYMENT_REQUESTS_DELIVERY_DETAILS,
      component: ReceivablesDeliveryAndContactDetails,
      protector: [userInfoEmptyProtector, receivablesEnteringDeliveryDetailsProtector],
      exact: true,
    },
    {
      path: SCREEN_PATHS.PAYMENT_REQUESTS_SUMMARY,
      component: ReceivablesConfirmDetails,
      protector: [userInfoEmptyProtector, receivablesDeliverySummaryProtector],
      exact: true,
    },
    {
      path: SCREEN_PATHS.PAYMENT_REQUESTS_SUCCESS,
      component: DeliverySuccessView,
      protector: [userInfoEmptyProtector, successReceivablesListEmptyProtector],
      exact: true,
    },
    {
      path: SCREEN_PATHS.PAYMENT_REQUESTS_FAILURE,
      component: DeliveryFailureView,
      protector: [userInfoEmptyProtector, failureReceivablesListEmptyProtector],
      exact: true,
    },
    {
      path: SCREEN_PATHS.FAILED_PAYMENT_REQUESTS_LIST,
      component: NonCreatedRTPsList,
      protector: [userInfoEmptyProtector, failureReceivablesListEmptyProtector],
      exact: true,
    },
    {
      path: SCREEN_PATHS.PAYMENT_REQUESTS_FAILED,
      component: ReceivableBreakdownView,
      protector: [userInfoEmptyProtector, profileCompletionRequiredProtector],
      exact: true,
    },
    {
      path: SCREEN_PATHS.PAYMENT_REQUEST,
      component: RTPDetailsView,
      protector: [userInfoEmptyProtector, profileCompletionAndLinkedBankAccountRequiredProtector],
      exact: true,
    },
  ],
  SUPPLIER_INVOICES: [
    {
      path: SCREEN_PATHS.PAYABLES_LIST,
      component: PayablesListView,
      protector: [
        userInfoEmptyProtector,
        cardPaymentsProtector,
        receivablesAndPayablesListProtector,
      ],
      exact: true,
    },
    {
      path: SCREEN_PATHS.PAYABLE_SELECT_ACCOUNT,
      component: PayableSelectAccountView,
      protector: [userInfoEmptyProtector, cardPaymentsProtector, beneficiaryFilledFieldsProtector],
      exact: true,
    },
    {
      path: SCREEN_PATHS.PAYABLE_CONFIRM,
      component: PayableConfirmView,
      protector: [userInfoEmptyProtector, cardPaymentsProtector, selectedPayerBankAccountProtector],
      exact: true,
    },
    {
      path: `${SCREEN_PATHS.PAYABLE_FAILURE}/:error?`,
      component: PayableFailure,
      protector: cardPaymentsProtector,
      exact: true,
    },
    {
      path: SCREEN_PATHS.PAYABLE_SUCCESS,
      component: PayableSuccess,
      protector: [userInfoEmptyProtector, cardPaymentsProtector, haveResultOnPaidInvoiceProtector],
      exact: true,
    },
    {
      path: SCREEN_PATHS.PAYABLE,
      component: PayableDetailsView,
      protector: [
        userInfoEmptyProtector,
        cardPaymentsProtector,
        receivablesAndPayablesListProtector,
      ],
      exact: true,
    },
  ],
  MAKE_PAYMENT: [
    {
      path: SCREEN_PATHS.MAKE_PAYMENT_SUCCESS,
      component: SuccessfulMakePayment,
      protector: [userInfoEmptyProtector, profileCompletionAndLinkedBankAccountRequiredProtector],
    },
    {
      path: `${SCREEN_PATHS.MAKE_PAYMENT_FAILURE}/:error?`,
      component: FailureMakePayment,
      protector: [userInfoEmptyProtector, profileCompletionAndLinkedBankAccountRequiredProtector],
      exact: true,
    },
    {
      path: SCREEN_PATHS.MAKE_PAYMENT_CONFIRM,
      component: ConfirmMakePayment,
      protector: [userInfoEmptyProtector, profileCompletionAndLinkedBankAccountRequiredProtector],
    },
    {
      path: SCREEN_PATHS.MAKE_PAYMENT,
      component: MakePaymentDetails,
      protector: [userInfoEmptyProtector, profileCompletionAndLinkedBankAccountRequiredProtector],
    },
  ],
  CASHFLOW_FORECAST: [
    {
      path: SCREEN_PATHS.CASHFLOW_FORECAST,
      component: CashflowForecastView,
      protector: [userInfoEmptyProtector, profileCompletionAndLinkedBankAccountRequiredProtector],
      exact: true,
    },
  ],
  SETTINGS: [
    {
      path: SCREEN_PATHS.SETTINGS,
      component: SettingsView,
      protector: [
        userInfoEmptyProtector,
        profileCompletionRequiredProtector,
        userSubscriptionProtector,
      ],
    },
  ],
  MAIN: [
    {
      path: SCREEN_PATHS.SETUP_EMAIL_VERIFICATION,
      component: SetupEmailVerificationView,
    },
    {
      path: SCREEN_PATHS.COMPANY_TYPE_VIEW,
      component: CompanyTypeView,
      protector: erpOrganizationFilled,
    },
    {
      path: SCREEN_PATHS.REGISTERED_COMPANY_VIEW,
      component: RegisteredCompanyView,
      protector: erpOrganizationFilled,
    },
    {
      path: SCREEN_PATHS.OTHER_ORG_TYPES_VIEW,
      component: OtherOrgTypesView,
      protector: erpOrganizationFilled,
    },
    {
      path: SCREEN_PATHS.SOLE_TRADER_TYPES_VIEW,
      component: SoleTraderTypesView,
      protector: erpOrganizationFilled,
    },
    {
      path: SCREEN_PATHS.SET_LEDGER_FOR_PAYMENTS_BOOKING,
      component: SetupLedger,
      protector: [
        userInfoEmptyProtector,
        profileCompletionAndLinkedBankAccountRequiredProtector,
        cardPaymentAppApprovedProtector,
        setupLedgerProtector,
      ],
    },
    {
      path: SCREEN_PATHS.REGISTRATION_COMPLETE,
      component: RegistrationComplete,
    },
    {
      path: SCREEN_PATHS.SETUP_ACCOUNTING_PACKAGE,
      component: () => (
        <SetupAccountingPackage externalErpRedirectState={SCREEN_PATHS.SETUP_ACCOUNTING_PACKAGE} />
      ),
    },
    {
      path: SCREEN_PATHS.SETUP_BANK_CONSENT,
      component: SetupBankConsentView,
    },
    {
      path: SCREEN_PATHS.APP_ERROR,
      component: UserErrorView,
    },
    {
      path: SCREEN_PATHS.NO_LINKED_ACCOUNTING_PACKAGE,
      component: NoLinkedAccountingPackageView,
    },
    {
      path: SCREEN_PATHS.NO_LINKED_BANK_ACCOUNTS,
      component: () => (
        <Page title="No Linked Bank Accounts">
          <NoLinkedBankAccountsView />
        </Page>
      ),
      protector: profileCompletionRequiredProtector,
    },
    {
      path: SCREEN_PATHS.SETUP_BANK_ACCOUNTS,
      component: () => (
        <Page title="Bank Accounts">
          <SetupBankAccountsView />
        </Page>
      ),
      protector: profileCompletionRequiredProtector,
    },
  ],
  NOT_AUTHORIZED_FUNCTIONALITY: [
    {
      path: SCREEN_PATHS.LOGIN,
      component: LoginView,
    },
    {
      path: SCREEN_PATHS.LOGOUT,
      component: LogoutView,
    },
    {
      path: SCREEN_PATHS.FORGOT_PASSWORD,
      component: ForgotPasswordView,
    },
    {
      path: SCREEN_PATHS.REGISTRATION_INFORMATION,
      component: RegistrationInformationView,
      exact: true,
    },
    {
      path: SCREEN_PATHS.REGISTRATION_TERMS_AND_CONDITIONS,
      component: TermsAndConditionsView,
      exact: true,
    },
    {
      path: SCREEN_PATHS.REGISTRATION_EMAIL,
      component: EmailRegistrationView,
      protector: registrationInitRequiredProtector,
    },
    {
      path: SCREEN_PATHS.REGISTRATION_PHONE,
      component: PhoneRegistrationView,
      protector: registrationInitRequiredProtector,
    },
    // {
    //   path: SCREEN_PATHS.REGISTRATION_PHONE_VERIFICATION,
    //   component: PhoneVerificationView,
    //   protector: registrationInitRequiredProtector,
    // },
    {
      path: SCREEN_PATHS.REGISTRATION_PASSWORD,
      component: PasswordRegistrationView,
      protector: registrationInitRequiredProtector,
    },
    {
      path: SCREEN_PATHS.REGISTRATION_SUCCESS,
      component: RegistrationSuccessView,
      protector: registrationInitRequiredProtector,
    },
    {
      path: "/",
      component: PageLayout,
      routes: [
        {
          path: SCREEN_PATHS.NOT_FOUND,
          component: NotFoundView,
        },
        {
          path: SCREEN_PATHS.EMAIL_VERIFICATION,
          component: EmailVerificationView,
          protector: emailVerifiedProtector,
        },
        {
          path: "/",
          redirect: SCREEN_PATHS.DASHBOARD,
          exact: true,
        },
        {
          path: SCREEN_PATHS.RESET_PASSWORD,
          component: ResetPasswordView,
        },
        {
          path: "*",
          redirect: SCREEN_PATHS.NOT_FOUND,
        },
      ],
    },
  ],
};

export const getRoutes = (bankAccounts: BankAccountExtended[], erp: ErpId | null) => {
  const routes: RouteConfig[] = [
    {
      path: SCREEN_PATHS.APP_ROOT,
      component: DashboardLayout,
      protector: authRequiredProtector,
      routes: [
        ...ROUTES.MAIN,
        {
          path: "*",
          redirect:
            !bankAccounts.length &&
            CONFIG.FEATURES.REGISTRATION.MOBILE_FLOW_ENABLED &&
            erp &&
            !CONFIG.FEATURES.MULTI_CHANNEL
              ? SCREEN_PATHS.NO_LINKED_BANK_ACCOUNTS
              : CONFIG.NAVIGATION.MAIN_PAGE,
        },
      ],
    },
    ...ROUTES.NOT_AUTHORIZED_FUNCTIONALITY,
  ];

  if (Provider.isMaverick) {
    routes[0].routes = [
      {
        path: SCREEN_PATHS.SETUP_PAYMENT_APPLICATION,
        component: SetupCardPaymentsView,
        protector: [
          userInfoEmptyProtector,
          profileCompletionAndLinkedBankAccountRequiredProtector,
          cardPaymentsProtector,
        ],
      },
      {
        path: SCREEN_PATHS.SETUP_CLEARING_LEDGER,
        component: () => (
          <Page title="Clearing ledger in your Accounting Package">
            <CreateClearingLedgerView isRegistrationFlow />
          </Page>
        ),
        protector: [
          userInfoEmptyProtector,
          profileCompletionAndLinkedBankAccountRequiredProtector,
          cardPaymentAppApprovedProtector,
          clearingLedgerProtector,
        ],
      },
      ...routes[0].routes!,
    ];
  }

  getAvailableFeaturesBasedOnERP(erp!).forEach((featureRoute) => {
    if (routes[0].routes && ROUTES[featureRoute]) {
      routes[0].routes = [...ROUTES[featureRoute], ...routes[0].routes!];
    }
  });

  return routes;
};
