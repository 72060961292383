import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Card, CardContent, CardHeader, Divider, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { addDays } from "date-fns";

import { Page } from "@APP/components";
import { ErpId } from "@APP/constants";
import { ForecastChart } from "@APP/views/cashflowForecast/ForecastChart";
import { InvoiceType, Payable, Receivable, RTP, SortBy, SortType } from "@APP/types";
import {
  getForecastData,
  getForecastId,
  getUser,
  hideLoader,
  setDefaultForecastState,
  setForecastLoading,
  showLoader,
  useAppDispatch,
} from "@APP/redux";
import { API } from "@APP/services";
import { useHandleErrorCodes, useCashflowForecasting } from "@APP/hooks";
import CONFIG from "@APP/config";

import PaymentRequestsChart from "./PaymentRequestsChart";
import InvoiceTableInformation from "./InvoiceTableInformation";
import BankAccounts from "./BankAccounts";

const useStyles = makeStyles((theme) => ({
  InvoicePaymentRequestsCardContent: {
    padding: "16px 10px",
    [theme.breakpoints.down("md")]: {
      padding: "16px 5px",
    },
  },
}));

const CASHFLOW_FEATURE_ENABLED = CONFIG.FEATURES.AVAILABLE_FEATURES.includes("CASHFLOW_FORECAST");
const SUPPLIER_INVOICES_FEATURE_ENABLED =
  CONFIG.FEATURES.AVAILABLE_FEATURES.includes("SUPPLIER_INVOICES");

const Dashboard = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const handleErrorCodes = useHandleErrorCodes();
  const { generateCashflowForecast, getCashflowForecast } = useCashflowForecasting();

  const [rtps, setRtps] = useState<RTP[] | null>([]);
  const [payables, setPayables] = useState<Payable[] | null>([]);
  const [receivables, setReceivables] = useState<Receivable[] | null>([]);

  const user = useSelector(getUser);
  const forecastId = useSelector(getForecastId);
  const forecastData = useSelector(getForecastData);

  useEffect(() => {
    (async () => {
      dispatch(showLoader());
      SUPPLIER_INVOICES_FEATURE_ENABLED && (await getAllInvoicesByType(InvoiceType.Payables));
      await getAllRtps();
      await getAllInvoicesByType(InvoiceType.Receivables);
      dispatch(hideLoader());
    })();
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setDefaultForecastState());
    };
  }, []);

  useEffect(() => {
    if (CASHFLOW_FEATURE_ENABLED) {
      generateCashflowForecast(addDays(new Date(), 6));
    }
  }, []);

  const onTryAgainGenerateForecast = async () => {
    if (forecastId) {
      try {
        dispatch(setForecastLoading(true));
        await getCashflowForecast(forecastId);
      } catch (error) {}
    } else {
      generateCashflowForecast(addDays(new Date(), 6));
    }
  };

  const getAllRtps = async (page = 0, entries = 50, prevData: Array<RTP> = []): Promise<void> => {
    try {
      const response = await API.getPaymentRequests(
        { page, entries },
        {},
        {
          sort_by: SortBy.dueDate,
          sort_type: SortType.asc,
        },
      );
      const data: Array<RTP> = [...prevData, ...response.data];

      if (response.links.next) {
        return getAllRtps(page + 1, entries, data);
      }

      setRtps(data);
    } catch (error) {
      const errorData = error?.response?.data;
      setRtps(null);
      handleErrorCodes(errorData?.errorCode);
    }
  };

  const getAllInvoicesByType = async (
    invoiceType: InvoiceType,
    page = 0,
    entries = 50,
    prevData: Array<Receivable | Payable> = [],
  ): Promise<void> => {
    try {
      const response = await API.getInvoices(
        user?.erp as ErpId,
        invoiceType,
        { page, entries },
        { sort_by: SortBy.dueDate, sort_type: SortType.asc },
      );
      const data: Array<Receivable | Payable> = [...prevData, ...response.data];

      if (response.links.next) {
        return getAllInvoicesByType(invoiceType, page + 1, entries, data);
      }

      invoiceType === InvoiceType.Payables
        ? setPayables(data as Payable[])
        : setReceivables(data as Receivable[]);
    } catch (error) {
      const errorData = error?.response?.data;
      invoiceType === InvoiceType.Payables ? setPayables(null) : setReceivables(null);
      handleErrorCodes(errorData?.errorCode);
    }
  };

  const getForecastErrorMessageForChart = () => {
    if (!forecastData || !forecastData.balances?.items?.length) {
      return "Cash Flow Forecast can’t be built as we were unable to get your data. Please try again.";
    }

    return undefined;
  };

  return (
    <Page title="Dashboard">
      <Box mb={3}>
        <BankAccounts />
      </Box>
      <Card elevation={4}>
        <CardHeader title="Open unpaid Invoices and Payment Requests" />
        <Divider />
        <CardContent className={classes.InvoicePaymentRequestsCardContent}>
          <Grid container spacing={3}>
            <Grid item lg={SUPPLIER_INVOICES_FEATURE_ENABLED ? 6 : 12} xs={12}>
              <InvoiceTableInformation
                title="Your Unpaid Invoices"
                titleTestId="invoices-table-head-title"
                subTitle={t("Dashboard.InvoiceTableInformation.Subtitle")}
                subTitleTestId="invoices-table-head-subtitle"
                data={receivables}
                headerTableTitles={[
                  { title: "Due date", testId: "due-date" },
                  { title: "Customer", testId: "customer" },
                  { title: "Unique Reference", testId: "reference" },
                  { title: "Amount", testId: "amount" },
                ]}
                type={InvoiceType.Receivables}
                emptyListMessage="You have no unpaid Invoices. Click here to create a new invoice which will also be saved in your accounting package."
                onTryAgain={() => getAllInvoicesByType(InvoiceType.Receivables)}
              />
            </Grid>
            {SUPPLIER_INVOICES_FEATURE_ENABLED && (
              <Grid item lg={6} xs={12}>
                <InvoiceTableInformation
                  title="Your Supplier Invoices"
                  titleTestId="supplier-table-head-title"
                  subTitle="Invoices due for payment."
                  subTitleTestId="supplier-table-head-subtitle"
                  data={payables}
                  headerTableTitles={[
                    { title: "Due date", testId: "due-date" },
                    { title: "Supplier", testId: "supplier" },
                    { title: "Amount", testId: "amount" },
                  ]}
                  type={InvoiceType.Payables}
                  onTryAgain={() => getAllInvoicesByType(InvoiceType.Payables)}
                  emptyListMessage="There are no unpaid Supplier Invoices."
                />
              </Grid>
            )}
            <Grid item lg={12} xs={12}>
              <PaymentRequestsChart rtps={rtps} onTryAgain={getAllRtps} />
            </Grid>
            {CASHFLOW_FEATURE_ENABLED && (
              <Grid item xs={12}>
                <ForecastChart
                  errorMessage={getForecastErrorMessageForChart()}
                  showNavigateToForecast
                  onTryAgain={onTryAgainGenerateForecast}
                />
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Page>
  );
};

export default Dashboard;
