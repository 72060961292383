import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAlert, useHandleErrorCodes } from "@APP/hooks";
import {
  fetchUserData,
  getErpId,
  getUserOrganisation,
  hideLoader,
  showLoader,
  useAppDispatch,
} from "@APP/redux";
import { SCREEN_PATHS } from "@APP/navigation";
import { API, AppLocalStorage } from "@APP/services";
import { ErpId } from "@APP/constants";
import { getErrorMessageByErrorCode } from "@APP/utils";
import { ErrorCode } from "@APP/types";

const ORG_COMPANY_VALIDATION_ERROR_CODES: ErrorCode[] = [6017, 6006, 6103, 6106, 1007, 1021];
const BANK_ACCOUNT_USING_ERROR_CODES: ErrorCode[] = [1232, 1233];

interface OrganisationDetailsOptions {
  errorCodesExcludedFromHandling?: number[];
}

const useOrganisationDetails = () => {
  const alert = useAlert();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const erpId = useSelector(getErpId);
  const organisation = useSelector(getUserOrganisation);
  const handleErrorCodes = useHandleErrorCodes();
  const { t } = useTranslation();

  const getOrganisationDetails = async (
    { errorCodesExcludedFromHandling }: OrganisationDetailsOptions = {
      errorCodesExcludedFromHandling: [],
    },
  ) => {
    try {
      dispatch(showLoader());
      const currentErp = erpId || (await API.getCurrentERP()).currentErp;

      if (currentErp !== ErpId.INTERNAL) {
        await API.linkERPOrganisation(currentErp);
        const { token } = await API.refreshToken();
        AppLocalStorage.setItem("auth.token", token);
      }

      await dispatch(fetchUserData());
    } catch (error) {
      const errorData = error.response?.data;

      if (ORG_COMPANY_VALIDATION_ERROR_CODES.includes(errorData?.errorCode)) {
        await dispatch(fetchUserData());

        return alert.open(
          t("Errors.Common.Alerts.AlertTitles.Failure"),
          getErrorMessageByErrorCode(errorData?.errorCode),
          [{ text: "Okay" }],
        );
      }

      const isHandled = handleErrorCodes(errorData?.errorCode, {
        excludedErrorCodes: errorCodesExcludedFromHandling,
      });

      if (isHandled) return;

      history.push(SCREEN_PATHS.APP_ERROR);
    } finally {
      dispatch(hideLoader());
    }
  };

  /**
   * Returns `true` or `false` dependetly needs to update
   * organisation details
   */
  const updateOrganisationDetails = async () => {
    try {
      const currentErp = erpId || (await API.getCurrentERP()).currentErp;

      if (currentErp !== ErpId.INTERNAL) {
        const { orgType } = await API.linkERPOrganisation(currentErp);

        if (orgType !== organisation?.orgType) {
          const { token } = await API.refreshToken();
          AppLocalStorage.setItem("auth.token", token);

          await dispatch(fetchUserData());
        }
      }
    } catch (error) {
      const errorCode = error.response?.data?.errorCode;

      if (BANK_ACCOUNT_USING_ERROR_CODES.includes(errorCode)) {
        const errorMessage = getErrorMessageByErrorCode(errorCode, "using");
        alert.open(t("Errors.Common.Alerts.AlertTitles.Failure"), errorMessage, [
          {
            text: "Okay",
          },
        ]);

        return true;
      }
    }

    return false;
  };

  return { getOrganisationDetails, updateOrganisationDetails };
};

export default useOrganisationDetails;
