import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  SvgIcon,
  Typography,
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import SendIcon from "@mui/icons-material/Send";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import DoneIcon from "@mui/icons-material/Done";
import TimerIcon from "@mui/icons-material/Timer";
import { isBefore } from "date-fns";
import EmailIcon from "@mui/icons-material/MailOutline";
import SmsIcon from "@mui/icons-material/SmsOutlined";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { ClassNameMap } from "@mui/styles/withStyles";

import { useAlert, useFeatureGateway } from "@APP/hooks";
import { API } from "@APP/services";
import { formatErrorMessage } from "@APP/utils";
import { RTP, RTPDeliveryChannel, RTPStatus } from "@APP/types";
import {
  Provider,
  RTP_DELIVERY_CHANNEL_LABEL,
  RTP_STATUS_COLOR,
  RTP_STATUS_LABEL,
} from "@APP/constants";
import { hideLoader, showLoader, useAppDispatch } from "@APP/redux";
import { RTPMessageType } from "@APP/services/RTP";
import { PrimaryTooltip } from "@APP/components/common/IconWithTooltip/IconWithTooltip";

type Props = {
  rtp: RTP;
  setRTP: React.Dispatch<React.SetStateAction<RTP | undefined>>;
  classes: ClassNameMap;
};

const RTP_DELIVERY_CHANNEL_ICON: { [key in RTPDeliveryChannel]: typeof SvgIcon } = {
  Email: EmailIcon,
  Sms: SmsIcon,
  OwnSms: SmsIcon,
  FaceToFace: SmartphoneIcon,
  WhatsApp: WhatsAppIcon,
};

const RTP_PAYABLE_STATUSES = [RTPStatus.RequestMade, RTPStatus.InProgress, RTPStatus.Created];
const RTP_RESENDABLE_STATUSES = [RTPStatus.RequestMade, RTPStatus.Created];
const RTP_NOT_RESENDABLE_DELIVERY_CHANNELS = [
  RTPDeliveryChannel.OwnSms,
  RTPDeliveryChannel.WhatsApp,
];

const NOT_RESENDABLE_MESSAGES = {
  [RTPDeliveryChannel.OwnSms]:
    "This payment request was sent via SMS and can only be resent from the mobile app.",
  [RTPDeliveryChannel.WhatsApp]:
    "This payment request was sent via WhatsApp and can only be resent from the mobile app.",
};

const NOT_REMINDABLE_MESSAGES = {
  [RTPDeliveryChannel.OwnSms]:
    "This payment request was sent via SMS and reminders can only be sent from the mobile app.",
  [RTPDeliveryChannel.WhatsApp]:
    "This payment request was sent via WhatsApp and reminders can only be sent from the mobile app.",
};

const StatusAndActions = ({ rtp, classes, setRTP }: Props) => {
  const dispatch = useAppDispatch();
  const alert = useAlert();
  const { t } = useTranslation();
  const gateway = useFeatureGateway();

  const isPayable = RTP_PAYABLE_STATUSES.includes(rtp.status);
  const isResendable = RTP_RESENDABLE_STATUSES.includes(rtp.status);
  const isCancelable = rtp.status === RTPStatus.Scheduled || rtp.status === RTPStatus.Created;
  const isOverdue = isBefore(new Date(rtp.receivable.dueDateTime), new Date());
  const isDeliveryChannelResendable = !RTP_NOT_RESENDABLE_DELIVERY_CHANNELS.includes(rtp.channel);

  const DeliveryChannelIcon = rtp
    ? RTP_DELIVERY_CHANNEL_ICON[rtp.channel]
    : RTP_DELIVERY_CHANNEL_ICON["Email"];

  const handleResend = async () => {
    dispatch(showLoader());
    try {
      const paymentСreationAllowed = await gateway.createPayment({
        checkClearingLedger: Provider.isMaverick,
      });

      if (!paymentСreationAllowed) {
        return;
      }

      await API.resendRTP(rtp.id);
      alert.open("Success", "Your payment request was successfully resent.", [{ text: "Okay" }]);
    } catch (error) {
      alert.open(
        t("Errors.Common.Alerts.AlertTitles.Failure"),
        formatErrorMessage(t("Errors.RTPDetails.Alerts.ResendFail.Message"), error),
        [{ text: "Okay" }],
      );
    } finally {
      dispatch(hideLoader());
    }
  };

  const handleSendOverdueReminder = async () => {
    dispatch(showLoader());
    try {
      await API.resendRTP(rtp.id, RTPMessageType.OVERDUE_RTP_MESSAGE);
      alert.open("Success", "Your overdue reminder was successfully sent.", [{ text: "Okay" }]);
    } catch (error) {
      alert.open(
        t("Errors.Common.Alerts.AlertTitles.Failure"),
        formatErrorMessage(t("Errors.RTPDetails.Alerts.OverdueFail.Message"), error),
        [{ text: "Okay" }],
      );
    }
    dispatch(hideLoader());
  };

  const handleCancellation = () => {
    const handleCancelRTP = async () => {
      try {
        dispatch(showLoader());
        await API.updateRTPStatusToCancelled(rtp!.id);
        setRTP((prevState) => ({ ...prevState, status: RTPStatus.Cancelled } as RTP));
      } catch (error) {
        alert.open(
          t("Errors.Common.Alerts.AlertTitles.Failure"),
          formatErrorMessage(t("Errors.RTPDetails.Alerts.CancelRTP.Message")),
          [{ text: "Okay" }],
        );
      }
      dispatch(hideLoader());
    };

    alert.open(
      "Cancel Payment Request",
      "You will not be able to amend the status following confirmation.",
      [{ text: "Cancel" }, { text: "Okay", onClick: handleCancelRTP }],
    );
  };

  const handlePaid = () => {
    const handleMarkAsPaid = async () => {
      try {
        dispatch(showLoader());
        await API.updateRTPStatusToPaid(rtp!.id);
        setRTP((prevState) => ({ ...prevState, status: RTPStatus.PaymentReceived } as RTP));
      } catch (error) {
        alert.open(
          t("Errors.Common.Alerts.AlertTitles.Failure"),
          formatErrorMessage(t("Errors.RTPDetails.Alerts.UpdateRTP.Message")),
          [{ text: "Okay" }],
        );
      }
      dispatch(hideLoader());
    };

    alert.open(
      "Change status to Paid",
      "You will not be able to amend the status following confirmation.",
      [{ text: "Cancel" }, { text: "Okay", onClick: handleMarkAsPaid }],
    );
  };

  return (
    <Card className={classes.rtpContainer} elevation={12}>
      <CardHeader title="Status and actions" data-testid="status-and-actions-card-header" />
      <Divider />
      <CardContent>
        <Box textAlign="center">
          <DeliveryChannelIcon className={classes.deliveryChannelIcon} color="primary" />
        </Box>
        <Box textAlign="center" mb={1} data-testid="delivery-method-container">
          <Typography variant="h5" display="inline">
            Delivery method:{"  "}
          </Typography>
          <Typography variant="h5" display="inline" color="primary">
            {rtp && RTP_DELIVERY_CHANNEL_LABEL[rtp.channel]}
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          color={rtp ? RTP_STATUS_COLOR[rtp!.status] : RTP_STATUS_COLOR["Created"]}
          data-testid="status-container"
          mb={2}>
          <Typography variant="h5" color="textPrimary">
            Status:{" "}
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            data-testid="status-value">
            <FiberManualRecordIcon className={classes.statusIcon} />
            <Typography variant="h5">{rtp && RTP_STATUS_LABEL[rtp.status]}</Typography>
          </Box>
        </Box>
        <Divider />
        <Box position="relative" mt={3} className={classes.actionsContent}>
          {rtp.channel !== RTPDeliveryChannel.FaceToFace ? (
            <Box mt={1} mb={1}>
              {isDeliveryChannelResendable ? (
                <Button
                  color="primary"
                  fullWidth
                  variant="outlined"
                  startIcon={<SendIcon />}
                  disabled={!isResendable}
                  data-testid="resend-button"
                  onClick={handleResend}>
                  Resend
                </Button>
              ) : (
                <PrimaryTooltip
                  title={
                    NOT_RESENDABLE_MESSAGES[
                      rtp.channel as RTPDeliveryChannel.OwnSms | RTPDeliveryChannel.WhatsApp
                    ]
                  }
                  enterTouchDelay={0}
                  arrow>
                  <span>
                    <Button
                      color="primary"
                      fullWidth
                      variant="outlined"
                      startIcon={<SendIcon />}
                      disabled
                      data-testid="resend-button">
                      Resend
                    </Button>
                  </span>
                </PrimaryTooltip>
              )}
            </Box>
          ) : null}
          <Box mt={1} mb={1}>
            {isCancelable && (
              <Button
                color="primary"
                fullWidth
                variant="outlined"
                startIcon={<CancelScheduleSendIcon />}
                data-testid="cancel-button"
                onClick={handleCancellation}>
                Cancel
              </Button>
            )}
          </Box>
          <Box mt={1} mb={1}>
            <Button
              color="primary"
              fullWidth
              variant="outlined"
              startIcon={<DoneIcon />}
              disabled={!isPayable}
              data-testid="mark-as-paid-button"
              onClick={handlePaid}>
              Mark as Paid
            </Button>
          </Box>
          {rtp.channel !== RTPDeliveryChannel.FaceToFace ? (
            <Box mt={1}>
              {isDeliveryChannelResendable ? (
                <Button
                  color="primary"
                  fullWidth
                  variant="outlined"
                  startIcon={<TimerIcon />}
                  disabled={!(isOverdue && isResendable)}
                  data-testid="send-reminder-button"
                  onClick={handleSendOverdueReminder}>
                  Send Reminder
                </Button>
              ) : (
                <PrimaryTooltip
                  title={
                    NOT_REMINDABLE_MESSAGES[
                      rtp.channel as RTPDeliveryChannel.OwnSms | RTPDeliveryChannel.WhatsApp
                    ]
                  }
                  enterTouchDelay={0}
                  arrow>
                  <span>
                    <Button
                      color="primary"
                      fullWidth
                      variant="outlined"
                      startIcon={<TimerIcon />}
                      disabled
                      data-testid="send-reminder-button">
                      Send Reminder
                    </Button>
                  </span>
                </PrimaryTooltip>
              )}
            </Box>
          ) : null}
        </Box>
      </CardContent>
    </Card>
  );
};

export default StatusAndActions;
