import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  FooterActionsButtons,
  MoneyhubFooter,
  Page,
  ScreenHeader,
  ScreenHeaderSubtitle,
} from "@APP/components";
import {
  getMakePayment,
  hideLoader,
  setMakePaymentInitiation,
  showLoader,
  useAppDispatch,
} from "@APP/redux";
import { SCREEN_PATHS } from "@APP/navigation";
import { ImmediatePaymentDetails, PaymentTypes, SchemesNames } from "@APP/types/paybles";
import { setupASingleImmediatePayment } from "@APP/services/api";
import { useHandleErrorCodes, useOrganisationDetails } from "@APP/hooks";

const useStyles = makeStyles((theme) => ({
  tableCell: {
    border: "none",
    padding: theme.spacing(1, 0, 0),
  },
  smallCell: {
    width: 220,
  },
}));

const ConfirmMakePayment = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const handleErrorCodes = useHandleErrorCodes();
  const { updateOrganisationDetails } = useOrganisationDetails();

  const {
    beneficiaryName,
    BICCode,
    bankAccountNumber,
    sortCodeAccountNumberOrIBAN,
    bankAccountSortCode,
    currency,
    IBAN,
    reference,
    amount,
    selectedPayerAccount,
  } = useSelector(getMakePayment);

  const redirectToBack = () => history.push(SCREEN_PATHS.MAKE_PAYMENT);

  const getMakePaymentBodyRequest = () => ({
    amount: {
      amount,
      currency,
    },
    creditAccount: {
      schemeName: sortCodeAccountNumberOrIBAN,
      identification: getIdentification(),
      name: beneficiaryName,
    },
    additionalData: {
      userId: selectedPayerAccount?.additionalData?.userId,
      payerAccountId: selectedPayerAccount?.accountId,
    },
    paymentType: PaymentTypes.IMMEDIATE,
    debtorAccount: {
      schemeName: selectedPayerAccount?.account.schemeName || SchemesNames.SORT_CODE_ACCOUNT_NUMBER,
      identification: selectedPayerAccount?.account.identification || "",
    },
    remittanceInformation: {
      reference: reference || null,
    },
  });

  const getIdentification = () => {
    if (sortCodeAccountNumberOrIBAN === SchemesNames.SORT_CODE_ACCOUNT_NUMBER)
      return bankAccountSortCode.replaceAll("-", "") + bankAccountNumber;
    else return IBAN;
  };

  const makePayment = async () => {
    dispatch(showLoader());

    const isOrgUpdated = await updateOrganisationDetails();

    if (isOrgUpdated) {
      return dispatch(hideLoader());
    }

    try {
      const bankId = selectedPayerAccount?.bankId.toLowerCase() || "";
      const immediatePaymentDetails: ImmediatePaymentDetails = getMakePaymentBodyRequest();

      const paymentInitiation = await setupASingleImmediatePayment(
        bankId,
        immediatePaymentDetails,
        SCREEN_PATHS.MAKE_PAYMENT_SUCCESS,
      );
      dispatch(setMakePaymentInitiation(paymentInitiation));

      if (paymentInitiation.redirectUrl) {
        window.location.href = paymentInitiation.redirectUrl;
      }
    } catch (e) {
      const errorCode = e.response?.data?.errorCode;

      const isHandled = handleErrorCodes(errorCode, { errorType: "using" });

      if (!isHandled) {
        history.push(SCREEN_PATHS.MAKE_PAYMENT_FAILURE);
      }
    } finally {
      dispatch(hideLoader());
    }
  };

  return (
    <Page title="Make Payment">
      <ScreenHeader title="Make Payment" />
      <ScreenHeaderSubtitle
        step="Confirm Payment"
        subtitle="Please check that the details below are correct prior to paying."
      />
      <Card>
        <CardHeader title="Payment details" />
        <Divider />
        <CardContent>
          <Table>
            <caption className="visuallyHidden">Payment details table</caption>
            <TableBody>
              <TableRow>
                <TableCell className={clsx(classes.tableCell, classes.smallCell)}>
                  <Typography variant="h6" data-testid="supplier-name-field-label">
                    Beneficiary Name
                  </Typography>
                </TableCell>
                <TableCell className={classes.tableCell}>{beneficiaryName}</TableCell>
              </TableRow>
              {sortCodeAccountNumberOrIBAN === SchemesNames.SORT_CODE_ACCOUNT_NUMBER ? (
                <BankAccountRows
                  bankAccountNumber={bankAccountNumber}
                  bankAccountSortCode={bankAccountSortCode}
                />
              ) : (
                <IBANRows IBAN={IBAN} BICCode={BICCode} />
              )}
              <TableRow>
                <TableCell className={clsx(classes.tableCell, classes.smallCell)}>
                  <Typography variant="h6" data-testid="unique-reference-field-label">
                    Amount
                  </Typography>
                </TableCell>
                <TableCell className={classes.tableCell}>{amount}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={clsx(classes.tableCell, classes.smallCell)}>
                  <Typography variant="h6" data-testid="amount-field-label">
                    My Reference
                  </Typography>
                </TableCell>
                <TableCell className={classes.tableCell}>{reference || "-"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={clsx(classes.tableCell, classes.smallCell)}>
                  <Typography variant="h6" data-testid="payer-account-field-label">
                    Payer Account
                  </Typography>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {selectedPayerAccount?.account.identification}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
        <Divider />
      </Card>
      <FooterActionsButtons
        backButtonText="Back to Payment details"
        handleBackButton={redirectToBack}
        handleContinue={makePayment}
        continueButtonText="Pay"
        continueButtonDataTestId="continue-button"
        typeButtonContinue="submit"
      />
      <Box mt={4} mb={4}>
        <MoneyhubFooter />
      </Box>
    </Page>
  );
};

type BankAccountRowsProps = {
  bankAccountSortCode: string;
  bankAccountNumber: string;
};

const BankAccountRows = ({ bankAccountSortCode, bankAccountNumber }: BankAccountRowsProps) => {
  const classes = useStyles();

  return (
    <>
      <TableRow>
        <TableCell className={clsx(classes.tableCell, classes.smallCell)}>
          <Typography variant="h6" data-testid="sort-code-field-label">
            Bank Account Sort Code
          </Typography>
        </TableCell>
        <TableCell className={classes.tableCell}>{bankAccountSortCode}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={clsx(classes.tableCell, classes.smallCell)}>
          <Typography variant="h6" data-testid="bank-account-number-field-label">
            Bank Account Number
          </Typography>
        </TableCell>
        <TableCell className={classes.tableCell}>{bankAccountNumber}</TableCell>
      </TableRow>
    </>
  );
};

type IBANRowsProps = {
  BICCode: string;
  IBAN: string;
};

const IBANRows = ({ BICCode, IBAN }: IBANRowsProps) => {
  const classes = useStyles();

  return (
    <>
      <TableRow>
        <TableCell className={clsx(classes.tableCell, classes.smallCell)}>
          <Typography variant="h6" data-testid="sort-code-field-label">
            BIC Code
          </Typography>
        </TableCell>
        <TableCell className={classes.tableCell}>{BICCode}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={clsx(classes.tableCell, classes.smallCell)}>
          <Typography variant="h6" data-testid="bank-account-number-field-label">
            IBAN
          </Typography>
        </TableCell>
        <TableCell className={classes.tableCell}>{IBAN}</TableCell>
      </TableRow>
    </>
  );
};

export default ConfirmMakePayment;
