import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import numeral from "numeral";

import {
  FooterActionsButtons,
  Page,
  ScreenHeader,
  ScreenHeaderSubtitle,
  InvoiceSummary,
} from "@APP/components";
import { formatErrorMessage } from "@APP/utils";
import { ErpId } from "@APP/constants";
import { useAlert } from "@APP/hooks";
import { getUser, hideLoader, showLoader, useAppDispatch } from "@APP/redux";
import { API } from "@APP/services";
import { InvoiceType, Receivable } from "@APP/types";
import { SCREEN_PATHS } from "@APP/navigation";

const ReceivableBreakdownView = () => {
  const alert = useAlert();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  numeral.defaultFormat("0,0.00");
  const [selectedReceivable, setSelectedReceivable] = useState<null | Receivable>(null);
  const { requestId }: { requestId: string } = useParams();

  const user = useSelector(getUser);

  // const VAT = numeral(totalAmountTaxInclusiveValue).subtract(totalAmountTaxExclusiveValue).format();

  useEffect(() => {
    (async () => {
      try {
        dispatch(showLoader());
        // Fetch receivable from the server as the version in redux won't have full contact information
        setSelectedReceivable(
          (await API.getInvoice(
            user?.erp as ErpId,
            InvoiceType.Receivables,
            requestId,
          )) as Receivable,
        );
        // }
      } catch (error) {
        alert.open(t("Errors.Common.Alerts.AlertTitles.Error"), formatErrorMessage(error));
      } finally {
        dispatch(hideLoader());
      }
    })();
  }, []);

  useEffect(() => {
    if (selectedReceivable) dispatch(hideLoader());
  }, [selectedReceivable]);

  const handleBackNavigation = () => history.goBack();

  return (
    <Page title="Create Payment Request">
      <ScreenHeader
        title={
          location.pathname.includes(SCREEN_PATHS.FAILED_PAYMENT_REQUESTS_LIST)
            ? "Payment Requests"
            : "Check the Invoice"
        }
      />
      <ScreenHeaderSubtitle
        subtitle={
          location.pathname.includes(SCREEN_PATHS.FAILED_PAYMENT_REQUESTS_LIST)
            ? t("Errors.RTPCreation.Messages.InvoiceIssue")
            : undefined
        }
      />

      <InvoiceSummary
        customerContact={selectedReceivable?.customerContact}
        reference={selectedReceivable?.reference}
        invoiceIssueDateTime={selectedReceivable?.dateTimeIssued}
        invoiceDueDateTime={selectedReceivable?.dueDateTime}
        lineItems={selectedReceivable?.lineItems}
        totalAmountTaxExclusive={selectedReceivable?.totalAmountTaxExclusive?.amount}
        totalAmountTaxInclusive={selectedReceivable?.totalAmountTaxInclusive?.amount}
        currency={selectedReceivable?.totalAmountTaxExclusive?.currency}
        taxCategories={selectedReceivable?.taxCategories}
      />

      <FooterActionsButtons backButtonText="Back" handleBackButton={handleBackNavigation} />
    </Page>
  );
};

export default ReceivableBreakdownView;
