import {
  useEffect,
  useRef,
  useState,
  ComponentType,
  useMemo,
  ChangeEvent,
  useCallback,
} from "react";
import ReactPhoneInput, { CountryData, PhoneInputProps } from "react-phone-input-material-ui";
import { TextFieldProps, useMediaQuery, useTheme, InputAdornment } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import "react-phone-input-material-ui/lib/style.css";
import { getCountryCallingCode, CountryCode, AsYouType } from "libphonenumber-js";

import CONFIG from "@APP/config";
import { CommonTextField } from "@APP/components";

import "./styles.css";

interface Props {
  /**
   * Used as help function for updating value on blur
   * in most cases `setFieldValue` from formik
   */
  onValueChange?: (value: string) => void;
  /**
   * dataTestId is used for testing purposes
   */
  dataTestId?: string;
  /**
   * countyCode is used for setting country code to phone input
   */
  countryCode?: CountryCode;
  /**
   * countyCode is used for updating country code
   */
  setCountryCode?: (value: CountryCode) => void;
  /**
   * readOnly is used for setting phone input to readOnly, disabling the country selection and adjusting the divider position
   */
  readOnly?: boolean;
  /**
   * containerClassName is used for applying styles to container PhoneField
   */
  containerClassName?: string;
}

const useStyles = makeStyles((theme) => ({
  countryList: {
    width: "100%",
    height: 200,
    backgroundColor: theme.palette.background.paper,
    ...theme.typography.body1,
  },
  startAdornment: {
    borderRight: `1px solid ${theme.palette.divider}`,
    color: "inherit",
  },
}));

const PhoneField = (props: Props & TextFieldProps & Pick<PhoneInputProps, "value">) => {
  const classes = useStyles();
  const theme = useTheme();
  const isLessThenSmSize = useMediaQuery(theme.breakpoints.down("md"));

  const {
    onValueChange,
    onBlur,
    name,
    value,
    error,
    helperText,
    dataTestId,
    countryCode = CONFIG.INPUTS.DEFAULT_PHONE_COUNTRY_CODE,
    setCountryCode,
    readOnly = false,
    containerClassName,
    label,
    ...restProps
  } = props;

  const [flagOffset, setFlagOffset] = useState(0);
  const [inputHeight, setInputHeight] = useState(0);
  const [cursorPos, setCursorPos] = useState(0);

  const tempRef = useRef<HTMLDivElement | undefined>();

  // Due to a bug in react-phone-input-material-ui, the reference to the phone input element must be obtained by directly looking up the corresponding DOM node.
  const getPhoneInputRef = useCallback(() => {
    let input: HTMLInputElement | undefined;

    tempRef.current?.childNodes.forEach((node) => {
      if (node.nodeName === "INPUT") {
        input = node as HTMLInputElement;
      }
    });

    return input;
  }, [tempRef.current]);

  useEffect(() => {
    // 'keydown' allows to identify correct cursor cursorPos before 'react-phone-input-material-ui' changes it when internally handles 'onChange'
    getPhoneInputRef()?.addEventListener("keydown", async (e: any) => {
      await setCursorPos(e.target.selectionEnd);
    });
  }, []);

  const getPhoneWithoutCountryCode = (phone: string) => {
    return phone.replace(new RegExp(getCountryCallingCode(countryCode)), "");
  };

  const getFormattedValue = (phone: string | null | undefined) => {
    if (!phone) return `+${getCountryCallingCode(countryCode)}`;

    const asYouType = new AsYouType({ defaultCountry: countryCode });

    return asYouType.input(phone);
  };

  const formattedValue = useMemo(() => {
    return getFormattedValue(value);
  }, [value]);

  const onChangeHandler = (
    unformattedNewValue: string,
    config: CountryData,
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const code = (config?.countryCode?.toUpperCase() as CountryCode) || "GB";
    setCountryCode?.(code);

    // prevents the dial code from being automatically inserted when the user selects a country from the drop-down list
    if (event.type === "click") {
      return onValueChange && onValueChange(`+${getCountryCallingCode(code)}`);
    }

    const formattedNewValue = getFormattedValue(`+${unformattedNewValue}`);
    const unformattedPreviousValue = event.target.value.replaceAll("+", "").replaceAll(" ", "");

    // <Start>: Cursor handling logic (overrides `react-phone-input`'s default behavior).
    if (cursorPos === event.target.value.length) {
      // 1) Keep the cursor cursorPos at the end of the input after formatting the value.
      setCursorPos(formattedNewValue.length);
    } else if (unformattedPreviousValue.length > unformattedNewValue.length) {
      // 2) When deleting a character.
      if (event.target.value.length === formattedNewValue.length) {
        // when an empty space was removed
        setCursorPos(cursorPos - 1);
      } else {
        // move the cursor by the number deleted characters.
        setCursorPos(cursorPos - (event.target.value.length - formattedNewValue.length));
      }
    } else {
      // 3) When adding a character.
      if (event.target.value.length === formattedNewValue.length) {
        // when an empty space was added
        setCursorPos(cursorPos + 1);
      } else {
        // move the cursor by the number added characters.
        setCursorPos(cursorPos + (formattedNewValue.length - event.target.value.length));
      }
    }
    // </End>: Cursor handling logic.

    const phoneWithoutCountryCode = getPhoneWithoutCountryCode(unformattedNewValue);

    // Allow GB users to enter a phone number in national format that starts with 0 (removes the country code from the field)
    if (phoneWithoutCountryCode.startsWith("0") && code === "GB") {
      return onValueChange && onValueChange(phoneWithoutCountryCode);
    }

    if (unformattedNewValue.startsWith(getCountryCallingCode(code))) {
      return onValueChange && onValueChange(`+${unformattedNewValue}`);
    }

    if (unformattedNewValue === "") {
      return onValueChange && onValueChange(`+${getCountryCallingCode(code)}`);
    }
  };

  useEffect(() => {
    const inputRef = getPhoneInputRef();

    if (inputRef === document.activeElement) {
      inputRef?.setSelectionRange(cursorPos, cursorPos);
    }
  }, [formattedValue]);

  useEffect(() => {
    /**
     * To place flag perfectly in the middle of the input
     * make calculation based on current height of input
     * 14px - half of the height of the flag container from ./styles.css
     */
    setFlagOffset(
      tempRef.current!.parentElement!.offsetTop - 14 + tempRef.current!.clientHeight / 2,
    );
    setInputHeight(tempRef.current!.clientHeight);
  }, [isLessThenSmSize]);

  return (
    <>
      <ReactPhoneInput
        component={CommonTextField as ComponentType}
        value={formattedValue}
        onChange={onChangeHandler}
        onBlur={onBlur}
        country={countryCode.toLowerCase()}
        containerClass={containerClassName}
        enableLongNumbers
        onlyCountries={CONFIG.INPUTS.AVAILABLE_COUNTRIES_PHONE_CODES}
        disableDropdown={
          readOnly ||
          (!!CONFIG.INPUTS.AVAILABLE_COUNTRIES_PHONE_CODES &&
            CONFIG.INPUTS.AVAILABLE_COUNTRIES_PHONE_CODES.length === 1)
        }
        disableCountryCode
        disableCountryGuess
        inputProps={{
          ref: tempRef,
          InputProps: {
            disabled: false!,
            readOnly: readOnly,
            startAdornment: (
              <InputAdornment
                position="start"
                className={classes.startAdornment}
                style={{
                  marginLeft: theme.spacing(readOnly ? 4 : 5),
                  paddingRight: theme.spacing(readOnly ? 1 : 1.5),
                  minHeight: inputHeight - 14,
                }}
              />
            ),
          },
          type: "tel",
          name: "phone",
          label: label || "Mobile Telephone Number",
          placeholder: "Mobile Telephone Number",
          variant: "outlined",
          required: false,
          margin: "normal",
          fullWidth: true,
          error: error,
          helperText: helperText,
          value: formattedValue,
          "data-testid":
            dataTestId ||
            (error ? helperText && (helperText as string).replace(/ /g, "-").toLowerCase() : null),
          ...restProps,
        }}
        buttonStyle={{
          paddingTop: flagOffset,
          cursor: readOnly ? "default" : "pointer",
        }}
        dropdownClass={classes.countryList}
      />
    </>
  );
};

export default PhoneField;
