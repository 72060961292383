import { createActionCreator } from "deox";

import { CompanyType } from "@APP/constants";
import { GetCompanyByKeywordType, GetDirectorsByRegistrationNumberType } from "@APP/types";

export const setOtherOrgTypesFirstName = createActionCreator(
  "@REGISTRATION_COMPANY/SET_SOLE_TRADER_FIRST_NAME",
  (resolve) => (firstName: string) => resolve(firstName),
);

export const setOtherOrgTypesLastName = createActionCreator(
  "@REGISTRATION_COMPANY/SET_SOLE_TRADER_LAST_NAME",
  (resolve) => (lastName: string) => resolve(lastName),
);

export const setOtherOrgTypesBusinessName = createActionCreator(
  "@REGISTRATION_COMPANY/SET_SOLE_TRADER_BUSINESS_NAME",
  (resolve) => (businessName: string) => resolve(businessName),
);

export const setSelectedRegisteredCompany = createActionCreator(
  "@REGISTRATION_COMPANY/SET_SELECTED_REGISTERED_COMPANY",
  (resolve) => (registeredCompany: GetCompanyByKeywordType | null) => resolve(registeredCompany),
);

export const setSelectedRegisteredDirector = createActionCreator(
  "@REGISTRATION_COMPANY/SET_SELECTED_REGISTERED_DIRECTOR",
  (resolve) => (director: null | GetDirectorsByRegistrationNumberType) => resolve(director),
);

export const setRegisteredCompanySearchingValue = createActionCreator(
  "@REGISTRATION_COMPANY/SET_REGISTERED_COMPANY_SEARCHING_VALUE",
  (resolve) => (value: string) => resolve(value),
);

export const setSelectedCompanyType = createActionCreator(
  "@REGISTRATION_COMPANY/SET_SELECTED_COMPANY_TYPE",
  (resolve) => (companyType: CompanyType) => resolve(companyType),
);
