import { AppState } from "@APP/redux";

export const getUser = (state: AppState) => state.auth.user;
export const getBankAccounts = (state: AppState) => state.auth.user?.bankAccounts;
export const getErpId = (state: AppState) => state.auth.user?.erp;
export const getUserOrganisation = (state: AppState) => state.auth.user?.org;
export const getReconsentBanks = (state: AppState) => state.auth.reconsentBanks;
export const getSubscription = (state: AppState) => state.auth.user?.subscription;
export const getCardPaymentApp = (state: AppState) => state.auth.user?.cardPaymentApp;
export const getBankLedgers = (state: AppState) => state.auth.bankLedgers;
export const getClearingLedger = (state: AppState) => state.auth.clearingLedger;
