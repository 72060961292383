import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Card, CardContent, Divider, Typography } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { SubscriptionOperations, TabsName } from "@APP/constants";
import { SCREEN_PATHS } from "@APP/navigation";
import { fetchUserSubscription, hideLoader, showLoader, getSubscription } from "@APP/redux";
import { API } from "@APP/services";
import { useAlert } from "@APP/hooks";
import { formatCurrency, getErrorMessageByErrorCode } from "@APP/utils";
import { NoteBox } from "@APP/components";
import {
  ErrorCode,
  SubscriptionFeatureTypes,
  SubscriptionPlan,
  SubscriptionStatuses,
} from "@APP/types";
import CONFIG from "@APP/config";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
  },
  confirmButton: {
    marginBottom: theme.spacing(2),
  },
}));

const HANDLED_ERROR_CODES: ErrorCode[] = [1030, 1032, 1033, 1037];

const ActivateAccount = ({ subscriptionPlan }: { subscriptionPlan: SubscriptionPlan | null }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const alert = useAlert();
  const { t } = useTranslation();

  const subscription = useSelector(getSubscription);

  const isTrial = subscription?.status === SubscriptionStatuses.Trial;
  const isPlanFree = subscriptionPlan?.price.amount === "0.00";

  const onConfirm = useCallback(async () => {
    try {
      dispatch(showLoader());

      await API.subscribeToPlan(subscriptionPlan!.id);
      await dispatch(fetchUserSubscription());

      history.push(
        `${SCREEN_PATHS.SETTINGS}?tab=${TabsName.ACCOUNT_SUCCESS}&operation=${SubscriptionOperations.SUBSCRIBE}`,
      );
    } catch (error) {
      const errorData = error?.response?.data;

      if (HANDLED_ERROR_CODES.includes(errorData?.errorCode)) {
        return alert.open(
          t("Errors.Common.Alerts.AlertTitles.Failure"),
          getErrorMessageByErrorCode(errorData?.errorCode as ErrorCode),
          [
            {
              text: "Finish",
            },
          ],
        );
      } else {
        alert.open(
          t("Errors.Common.Alerts.AlertTitles.Error"),
          t("Errors.Common.Alerts.Generic.Message"),
          [
            {
              text: "Finish",
            },
          ],
        );
      }
    } finally {
      dispatch(hideLoader());
    }
  }, [subscription, subscriptionPlan?.id]);

  const noteBoxContent = () => {
    if (CONFIG.FEATURES.SUBSCRIPTIONS.TYPE === SubscriptionFeatureTypes.Flexible && isPlanFree) {
      return (
        <Typography variant="body2">
          You will not be charged to use our services, please confirm to activate your account.
        </Typography>
      );
    }

    if (isTrial) {
      return (
        <Typography variant="body2">
          Following your free period we will bill you{" "}
          {formatCurrency(subscriptionPlan?.price.amount, {
            currency: subscriptionPlan?.price.currency,
          })}{" "}
          plus vat in advance every {subscriptionPlan?.periodDays} days in order for you to continue
          to use our services. We’ll remind you as you near the end of the free period.
        </Typography>
      );
    }

    return (
      <Typography variant="body2">
        We will bill you{" "}
        {formatCurrency(subscriptionPlan?.price.amount, {
          currency: subscriptionPlan?.price.currency,
        })}{" "}
        plus vat in advance every {subscriptionPlan?.periodDays} days in order for you to continue
        to use our services.
      </Typography>
    );
  };

  return (
    <Card elevation={4} className={classes.root}>
      <Box textAlign="center" mb={3} mt={3}>
        <Typography variant="h3" color="primary">
          Activate Account
        </Typography>
      </Box>
      <CardContent>
        <NoteBox>{noteBoxContent()}</NoteBox>
      </CardContent>
      <Divider />
      <Box display="flex" flexDirection="column" margin={2}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          className={classes.confirmButton}
          onClick={onConfirm}>
          Confirm
        </Button>
        <Button
          variant="outlined"
          fullWidth
          onClick={() => history.push(`${SCREEN_PATHS.SETTINGS}?tab=${TabsName.ACCOUNT}`)}>
          Cancel
        </Button>
      </Box>
    </Card>
  );
};

export default ActivateAccount;
