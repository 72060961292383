import { useTranslation } from "react-i18next";
import { Link } from "@mui/material";

import CONFIG from "@APP/config";

const TermsAndConditionsLinks = () => {
  const { t } = useTranslation();

  return (
    <ul>
      <li>
        <Link
          variant="body2"
          href={CONFIG.URLS.TERMS_AND_CONDITIONS_URL}
          target="_blank"
          data-testid="customer-terms-link"
          rel="noopener noreferrer">
          {t("Registration.FullConditionLinkText")}
        </Link>
      </li>
    </ul>
  );
};

export default TermsAndConditionsLinks;
