import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";

import {
  FooterActionsButtons,
  InvoiceSummary,
  Page,
  ResultNotification,
  ScreenHeader,
} from "@APP/components";
import { SCREEN_PATHS } from "@APP/navigation";
import {
  getInvoice,
  setDefaultInvoiceState,
  setDefaultRtpDetailsToReceivables,
  setDeliveryEmails,
  updateTypedContractDetails,
  useAppDispatch,
} from "@APP/redux";
import CONFIG from "@APP/config";
import { formatCurrency } from "@APP/utils";
import { useAlert } from "@APP/hooks";
import { useTranslation } from "react-i18next";

const InvoiceSuccess = () => {
  const history = useHistory();
  const alert = useAlert();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const invoice = useSelector(getInvoice);

  const handleContinue = () => {
    if (!invoice.externalReceivable) return;

    dispatch(setDefaultInvoiceState());
    dispatch(updateTypedContractDetails([invoice.externalReceivable]));
    dispatch(setDefaultRtpDetailsToReceivables());
    dispatch(
      setDeliveryEmails({
        invoiceId: invoice.externalReceivable.entityDetails.externalId,
        email: invoice.externalReceivable.customerContact?.email ?? "",
      }),
    );

    if (
      CONFIG.INPUTS.MAXIMUM_TRANSACTION_AMOUNT <
      Number(invoice.externalReceivable.totalAmountTaxInclusive.amount)
    ) {
      return alert.open(
        t("Errors.RTPCreation.Alerts.ExceedsMax.Title"),
        t("Errors.RTPCreation.Alerts.ExceedsMax.Message", {
          MAX: formatCurrency(CONFIG.INPUTS.MAXIMUM_TRANSACTION_AMOUNT, {
            minimumFractionDigits: 0,
          }),
        }),
        [
          { text: "Close" },
          { text: "View Invoices", onClick: () => history.push(SCREEN_PATHS.RECEIVABLES_LIST) },
        ],
      );
    }

    history.push(SCREEN_PATHS.PAYMENT_REQUESTS_DELIVERY_DETAILS);
  };

  return (
    <Page title="View your Invoice">
      <ScreenHeader title="View your Invoice" />
      <ResultNotification type="success" title="Success">
        Your Invoice was successfully created, please check the details below:
      </ResultNotification>
      <Box mt={2}>
        <InvoiceSummary
          customerContact={invoice.externalReceivable?.customerContact}
          reference={invoice.externalReceivable?.reference}
          invoiceIssueDateTime={invoice.externalReceivable?.dateTimeIssued}
          invoiceDueDateTime={invoice.externalReceivable?.dueDateTime}
          lineItems={invoice.externalReceivable?.lineItems}
          totalAmountTaxExclusive={invoice.externalReceivable?.totalAmountTaxExclusive?.amount}
          totalAmountTaxInclusive={invoice.externalReceivable?.totalAmountTaxInclusive?.amount}
          currency={invoice.externalReceivable?.totalAmountTaxExclusive?.currency}
          taxCategories={invoice.externalReceivable?.taxCategories}
        />
      </Box>
      <FooterActionsButtons
        backButtonText="Back to Customer Invoices"
        handleBackButton={() => history.push(SCREEN_PATHS.RECEIVABLES_LIST)}
        continueButtonText="Create Payment Request"
        handleContinue={handleContinue}
      />
    </Page>
  );
};

export default InvoiceSuccess;
