import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Box, Button, Container, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import { MessageCard, Page } from "@APP/components";
import CONFIG from "@APP/config";

const UserErrorView = () => {
  const { t } = useTranslation();
  const { state } = useLocation<{
    errorMessage: string;
  }>();

  const onTryAgain = () => {
    document.location.reload();
    // to avoid immediate navigation on dashboard before reloading
    document.location.replace(`${document.location.origin}${CONFIG.NAVIGATION.MAIN_PAGE}`);
  };

  const contentErrorMessage = state?.errorMessage || t("Errors.Common.Messages.UserErrorPage");

  return (
    <Page title="Dashboard">
      <Container maxWidth="sm">
        <MessageCard type="error">
          <Typography variant="h4">Failure</Typography>
          <Typography>{contentErrorMessage}</Typography>
          <Box width="100%" mt={1} display="flex" justifyContent="flex-end">
            <Button component={RouterLink} to="/logout" color="primary">
              Log out
            </Button>
            <Button onClick={() => onTryAgain()} color="primary">
              Try Again
            </Button>
          </Box>
        </MessageCard>
      </Container>
    </Page>
  );
};

export default UserErrorView;
