import React, { useEffect } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CheckIcon from "@mui/icons-material/Check";

import { AuthForm, AuthLayout, Page } from "@APP/components";
import { SCREEN_PATHS } from "@APP/navigation";

const useStyles = makeStyles(() => ({
  icon: {
    fontSize: 94,
  },
}));

const RegistrationSuccessView = () => {
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        history.replace(SCREEN_PATHS.LOGIN);
      }
    };
  }, []);

  const renderMainContent = () => (
    <AuthForm title="Congratulations!">
      <Box textAlign="center" data-testid="success-message-container">
        <Box mb={4} mt={6}>
          <CheckIcon color="secondary" className={classes.icon} />
        </Box>
        <Box mb={4}>
          <Typography>You’ve created your profile,</Typography>
          <Typography>log in to complete your registration.</Typography>
        </Box>
        <Button
          fullWidth
          component={RouterLink}
          replace
          to={SCREEN_PATHS.LOGIN}
          color="secondary"
          variant="contained"
          size="large">
          Log in
        </Button>
      </Box>
    </AuthForm>
  );

  return (
    <Page title="Congratulations!" display="flex" height="100%" p={0}>
      <AuthLayout mainContent={renderMainContent()} />
    </Page>
  );
};

export default RegistrationSuccessView;
