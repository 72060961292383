import { SimplePaletteColorOptions, ThemeOptions, TypeText } from "@mui/material";

import palette from "./palette";
import shape from "./shape";

const components: Exclude<ThemeOptions["components"], undefined> = {
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: "none",
        fontWeight: "bold",
      },
    },
  },
  MuiCardHeader: {
    defaultProps: {
      titleTypographyProps: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        component: "h4",
      },
    },
  },
  MuiDrawer: {
    styleOverrides: {
      root: {
        "& > * .sidebarLogo": {
          width: "80%",
          height: "80%",
          objectFit: "contain",
        },
      },
    },
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        "&.MuiTextField-root": {
          borderRadius: shape?.borderRadius,
        },
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        "&.Mui-error": {
          color: (palette.error as SimplePaletteColorOptions)?.main,
        },
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        lineHeight: 1,
        height: "1em",
        "&.Mui-error": {
          color: (palette.error as SimplePaletteColorOptions)?.main,
        },
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        "&.lineItemButton > svg": {
          color: (palette.secondary as SimplePaletteColorOptions)?.main,
        },
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        color: palette.text?.primary,
        "&.Mui-focused": {
          color: palette.text?.primary,
        },
      },
    },
  },

  MuiLink: {
    styleOverrides: {
      root: {
        color: (palette.secondary as SimplePaletteColorOptions)?.main,
        textDecorationColor: (palette.secondary as SimplePaletteColorOptions)?.main,
        letterSpacing: 0.3,
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: (palette.primary as SimplePaletteColorOptions)?.main,
          borderWidth: 2,
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: (palette.primary as SimplePaletteColorOptions)?.main,
          borderWidth: 2,
        },
        "&.Mui-error .MuiOutlinedInput-notchedOutline": {
          borderColor: (palette.error as SimplePaletteColorOptions)?.main,
        },
      },
      notchedOutline: {
        borderColor: "#767676",
      },
      input: {
        padding: "14px 14px",
      },
      inputSizeSmall: {
        padding: "10px 14px",
      },
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      root: {
        "&.tableActionIcon": {
          fill: (palette.secondary as SimplePaletteColorOptions)?.main,
          fontSize: "1.6rem",
        },
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        textTransform: "none",
        color: palette.text?.primary,
        "&.Mui-selected": {
          backgroundColor: (palette.secondary as SimplePaletteColorOptions)?.main,
          color: "#fff",
        },
      },
    },
  },
  MuiTableHead: {
    styleOverrides: {
      root: {
        "& > .MuiTableRow-root": {
          "& > .MuiTableCell-root": {
            fontWeight: 700,
          },
        },
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        "& .MuiOutlinedInput-root.Mui-focused": {
          borderColor: (palette.secondary as SimplePaletteColorOptions)?.main,
        },
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        fontSize: "0.875rem",
        fontWeight: 400,
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      root: {
        "&.countOfReceivables": {
          color: (palette.secondary as SimplePaletteColorOptions)?.main,
        },
        "&.Mui-Error": {
          color: (palette.error as SimplePaletteColorOptions)?.main,
        },
        "&.styleOverdue": {
          color: (palette.text as TypeText).primary,
        },
      },
    },
  },
};

export default components;
