import { Box } from "@mui/material";

import OrganisationDetails from "./OrganisationDetails";
import ContactDetails from "./ContactDetails";

const OrganisationPage = () => {
  return (
    <>
      <OrganisationDetails />
      <Box mb={3} />
      <ContactDetails />
    </>
  );
};

export default OrganisationPage;
