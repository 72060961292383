import React from "react";
import { useHistory } from "react-router-dom";
import { Box, Button, Paper, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ArrowBackIosIcon from "@mui/icons-material/ChevronLeft";

import { SCREEN_PATHS } from "@APP/navigation";

interface Props {
  /**
   * Specifies navigation route for back button.
   * If empty, back button will not be rendered.
   */
  backwardPath?: SCREEN_PATHS;
  /**
   * Form title. If empty, the title will not be rendered.
   */
  title?: string;
  /**
   * Form subtitle. If empty, the subtitle will not be rendered.
   */
  subtitle?: string;
  children: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  formContainer: {
    width: "100%",
    maxWidth: 540,
    padding: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2),
    },
  },
  logo: {
    width: "100%",
    maxWidth: 70,
  },
}));

const AuthForm = ({ backwardPath, title = "", subtitle = "", children, ...props }: Props) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Paper className={classes.formContainer} elevation={12} {...props}>
      {(title || subtitle) && (
        <Box mb={1}>
          {title && (
            <Typography color="textPrimary" variant="h2">
              {title}
            </Typography>
          )}
          {subtitle && (
            <Typography color="textSecondary" gutterBottom variant="body2">
              {subtitle}
            </Typography>
          )}
        </Box>
      )}
      {children}
      {backwardPath && (
        <Box mt={2}>
          <Button
            color="primary"
            fullWidth
            size="large"
            variant="outlined"
            data-testid="auth-form-back-button"
            startIcon={<ArrowBackIosIcon />}
            onClick={() => history.push(backwardPath)}>
            Back
          </Button>
        </Box>
      )}
    </Paper>
  );
};

export default AuthForm;
