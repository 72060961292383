import {
  Avatar,
  Box,
  lighten,
  List,
  ListItem,
  ListItemButton,
  ListItemButtonProps,
  ListItemText,
} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";

import { BankAccountExtended, CustodianFeatures } from "@APP/types";
import { formatCurrency } from "@APP/utils";
import { IconWithTooltip } from "@APP/components";

const useStyles = makeStyles((theme) => ({
  outerContainer: {
    position: "relative",
    padding: 0,
  },
  accountContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    cursor: "pointer",
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      overflow: "auto",
    },
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      opacity: 0.6,
    },
  },
  selected: {
    backgroundColor: lighten(theme.palette.primary.main, 0.5),
    color: theme.palette.secondary.contrastText,
  },
  listItemButton: {
    "&:hover": { backgroundColor: theme.palette.background.paper },
  },
  bankInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  bankLogo: {
    height: 25,
    width: 25,
    marginRight: theme.spacing(1.5),
  },
  bankAccount: {
    display: "flex",
    justifySelf: "center",
  },
  balance: {
    display: "flex",
    marginRight: 30,
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      marginRight: 0,
    },
  },
  infoIcon: {
    position: "absolute",
    top: 15,
    right: 25,
  },
}));

type Props = {
  bankAccounts: BankAccountExtended[];
  selectedAccount: BankAccountExtended | null;
  onAccountClick: (account: BankAccountExtended) => void;
  unsupportedOutboundPaymentText: string;
} & ListItemButtonProps;

const PaymentAccountList = ({
  bankAccounts,
  selectedAccount,
  onAccountClick,
  unsupportedOutboundPaymentText,
  ...props
}: Props) => {
  const classes = useStyles();

  return (
    <List>
      {bankAccounts.map((bankAccount: BankAccountExtended) => (
        <ListItem
          className={classes.outerContainer}
          key={bankAccount.accountId}
          data-testid={`bank-account-${bankAccount.accountId}`}>
          <ListItemButton
            {...props}
            onClick={() => onAccountClick(bankAccount)}
            disabled={
              !bankAccount.bankInfo?.features?.includes(CustodianFeatures.SINGLE_IMMEDIATE_PAYMENT)
            }
            className={classes.listItemButton}>
            <Box
              boxShadow={2}
              width="100%"
              px={2}
              py={1}
              className={clsx(classes.accountContainer, {
                [classes.selected]: selectedAccount?.accountId === bankAccount.accountId,
              })}>
              <Box className={classes.bankInfo}>
                <Avatar
                  className={classes.bankLogo}
                  src={bankAccount.bankInfo?.logo}
                  alt={bankAccount.bankInfo?.fullName}
                />
                <ListItemText primary={bankAccount.bankInfo?.fullName} />
              </Box>
              <Box className={classes.bankAccount}>
                <ListItemText
                  primary={`${bankAccount.account.identification} / ${bankAccount.account.name}`}
                />
              </Box>
              <Box className={classes.balance}>
                <Box>
                  <ListItemText
                    primary={formatCurrency(bankAccount.balance.amount.amount, {
                      currency: bankAccount.balance?.amount.currency,
                    })}
                  />
                </Box>
              </Box>
            </Box>
          </ListItemButton>
          {!bankAccount.bankInfo?.features?.includes(
            CustodianFeatures.SINGLE_IMMEDIATE_PAYMENT,
          ) && (
            <Box className={classes.infoIcon}>
              <IconWithTooltip
                icon={<ErrorIcon color="primary" />}
                title={unsupportedOutboundPaymentText}
                placement="top"
                color="primary"
              />
            </Box>
          )}
        </ListItem>
      ))}
    </List>
  );
};

export default PaymentAccountList;
