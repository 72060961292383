import { ThemeOptions } from "@mui/material";

const typography: ThemeOptions["typography"] = {
  fontFamily: ["Nunito Sans"].join(","),
  h1: {
    fontWeight: 700,
    fontSize: "2.0rem",
    letterSpacing: 0.5,
  },
  h2: {
    fontWeight: 700,
    fontSize: "1.75rem",
    letterSpacing: 0.5,
  },
  h3: {
    fontWeight: 700,
    fontSize: "1.5rem",
  },
  h4: {
    fontWeight: 500,
    fontSize: "1.25rem",
  },
  h5: {
    fontWeight: 600,
    fontSize: "1rem",
  },
  h6: {
    fontWeight: 600,
    fontSize: "0.9rem",
  },
  subtitle1: {
    fontWeight: 500,
  },
  subtitle2: {
    fontWeight: 700,
  },
  overline: {
    fontWeight: 500,
  },
  button: {
    fontWeight: 600,
    letterSpacing: 0.4,
  },
  body1: {
    fontWeight: 400,
  },
  body2: {
    fontWeight: 400,
  },
};

export default typography;
