import { colors, ThemeOptions } from "@mui/material";

const palette: Exclude<ThemeOptions["palette"], undefined> = {
  background: {
    default: "#f5f6f8",
    paper: colors.common.white,
  },
  primary: {
    main: "#000",
  },
  secondary: {
    main: "#ec111a",
  },
  info: {
    main: "#8230df",
  },
  error: {
    main: "#ec111a",
  },
  action: {
    active: "#8230df",
  },
  success: {
    main: "#8230df",
  },
  text: {
    primary: "#333",
    secondary: colors.blueGrey[600],
  },
};

export default palette;
