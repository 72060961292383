import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { useFormik } from "formik";
import { Box, Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import SendIcon from "@mui/icons-material/Send";

import { SCREEN_PATHS } from "@APP/navigation";
import { emailValidationSchema } from "@APP/utils";
import { useAlert } from "@APP/hooks";
import { AuthForm, AuthLayout, CommonTextField, Page } from "@APP/components";
import { API } from "@APP/services";
import CONFIG from "@APP/config";

const useStyles = makeStyles(() => ({
  icon: {
    fontSize: 74,
  },
}));

const ForgotPasswordView = () => {
  const classes = useStyles();
  const alert = useAlert();
  const { t } = useTranslation();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const {
    errors,
    handleBlur,
    handleChange,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    touched,
    values,
    isValid,
    dirty,
  } = useFormik({
    initialValues: { email: "" },
    validationSchema: emailValidationSchema(t),
    onSubmit: async ({ email }: { email: string }) => {
      try {
        await API.requestPasswordReset(email);
        setIsSubmitted(true);
      } catch (error) {
        const errorCode = error?.response?.data?.errorCode;
        if (errorCode === 1017) {
          alert.open(
            t("Errors.Common.Alerts.AlertTitles.Failure"),
            t("Errors.ErrorCodes.1017", { SUPPORT_EMAIL: CONFIG.SUPPORT_EMAIL }),
            [{ text: "Okay" }],
          );
        } else {
          alert.open(
            t("Errors.Registration.Alerts.ResetPwNotSent.Title"),
            t("Errors.Registration.Alerts.ResetPwNotSent.Message", { EMAIL: values.email }),
            [{ text: "Okay" }],
          );
        }
      }
    },
  });

  const renderMainContent = () => (
    <AuthForm
      title={isSubmitted ? "Password reset email sent" : "Reset password"}
      subtitle={isSubmitted ? undefined : "You will receive an email to reset your password."}
      backwardPath={SCREEN_PATHS.LOGIN}>
      {isSubmitted ? (
        <Box textAlign="center" data-testid="success-message-container">
          <Box mb={2} mt={2}>
            <SendIcon className={classes.icon} color="secondary" />
          </Box>
          <Box mb={2}>
            <Typography>
              {`Password reset email has been sent to ${values.email}. Follow the instructions in the
              email to reset your password.`}
            </Typography>
          </Box>
          <Button
            fullWidth
            component={RouterLink}
            replace
            to={SCREEN_PATHS.LOGIN}
            color="secondary"
            variant="contained"
            size="large">
            Okay
          </Button>
        </Box>
      ) : (
        <form onSubmit={handleSubmit}>
          <CommonTextField
            disabled={isSubmitting}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
            label="Email Address"
            hiddenLabel
            placeholder="Email Address"
            fullWidth
            name="email"
            margin="normal"
            onBlur={handleBlur}
            onChange={handleChange}
            onValueChange={setFieldValue}
            type="email"
            value={values.email}
            inputProps={{ "data-testid": "email-input" }}
            autoComplete="email"
          />
          <Box mt={2}>
            <Button
              color="primary"
              disabled={!isValid || !dirty || isSubmitting}
              fullWidth
              size="large"
              type="submit"
              data-testid="submit-button"
              variant="contained">
              Reset my password
            </Button>
          </Box>
        </form>
      )}
    </AuthForm>
  );

  return (
    <Page title="Forgot Password" display="flex" height="100%" p={0}>
      <AuthLayout mainContent={renderMainContent()} />
    </Page>
  );
};

export default ForgotPasswordView;
