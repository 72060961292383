import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  FormHelperText,
  Link,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import CONFIG from "@APP/config";
import { CommonTextField, PhoneField } from "@APP/components";
import { useAlert, useHandleErrorCodes } from "@APP/hooks";
import { fetchUserData, getUser } from "@APP/redux";
import { API } from "@APP/services";
import { NO_PENDING_EMAIL_VERIFICATION_ERROR_CODE } from "@APP/services/api";

import { ContactDetailsValidationSchema } from "./ContactDetailsValidationSchema";
import { BusinessContactStatus } from "@APP/types";

const useStyles = makeStyles((theme) => ({
  subField: {
    marginTop: theme.spacing(1.8),
  },
  bottomButton: {
    flexDirection: "column",
    width: "100%",
  },
}));

const ContactDetails = () => {
  const classes = useStyles();
  const alert = useAlert();
  const theme = useTheme();
  const handleErrorCodes = useHandleErrorCodes();
  const isSmSizeScreen = useMediaQuery(theme.breakpoints.down("md"));
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isEditable, setIsEditable] = useState(false);
  const [emailStatus, setEmailStatus] = useState<BusinessContactStatus | null>(null);

  const getBusinessContactDetails = async () => {
    try {
      const businessContactEmail = await API.getBusinessContactEmail(user?.org?.id!);
      setEmailStatus(businessContactEmail);
    } catch (error) {
      const { errorCode } = error?.response?.data;
      if (errorCode === NO_PENDING_EMAIL_VERIFICATION_ERROR_CODE) return;
      alert.open(
        t("Errors.Common.Alerts.AlertTitles.Error"),
        t("Errors.Common.Alerts.Generic.Message"),
        [{ text: "Okay" }],
      );
    }
  };

  useEffect(() => {
    getBusinessContactDetails();
  }, []);

  const handleEditDetails = async () => {
    const { businessName, contactEmail, contactPhone } = values;
    if (businessName && contactEmail && contactPhone) {
      try {
        await API.updateContactDetails(
          user!.org!.id,
          businessName.trim(),
          contactEmail,
          contactPhone,
        );
        alert.open("Success", "Your contact details have been saved.", [{ text: "Okay" }]);
      } catch (error) {
        const { errorCode } = error?.response?.data;
        const isHandled = handleErrorCodes(errorCode);
        if (isHandled) return;
        alert.open(
          t("Errors.Common.Alerts.AlertTitles.Error"),
          t("Errors.Common.Alerts.Generic.Message"),
          [{ text: "Okay" }],
        );
      } finally {
        setIsEditable(false);
        getBusinessContactDetails();
        dispatch(fetchUserData());
      }
    }
  };

  const handleResendVerificationEmail = async () => {
    if (emailStatus) {
      try {
        await API.resendContactVerificationEmail(user!.org!.id);
        alert.open(
          "Success",
          `We’ve successfully sent you a verification email to ${emailStatus.email} inbox, please confirm your address to complete its update.`,
          [{ text: "Okay" }],
        );
      } catch {
        alert.open(
          t("Errors.Common.Alerts.AlertTitles.Failure"),
          `Sorry, we were unable to send you a verification email to ${emailStatus.email} inbox. Please try again later.`,
        );
      }
    }
  };

  const {
    initialValues,
    values,
    errors,
    dirty,
    isValid,
    isSubmitting,
    submitCount,
    setFieldValue,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: {
      businessName: user?.org?.businessContact?.name ?? user?.org?.name,
      contactEmail: user?.org?.businessContact?.email ?? user?.org?.companyInfo.email,
      contactPhone: user?.org?.businessContact?.telephone ?? user?.org?.companyInfo.telephone,
    },
    validationSchema: ContactDetailsValidationSchema(t, CONFIG.INPUTS.DEFAULT_PHONE_COUNTRY_CODE),
    onSubmit: handleEditDetails,
  });

  return (
    <Card elevation={4}>
      <form onSubmit={handleSubmit}>
        <CardHeader
          title="Contact Details"
          subheader="View your contact details that are presented to your customers in payment requests."
          data-testid="contact-details-card-header"
        />
        <Divider />
        <CardContent>
          <Box
            display="grid"
            gridTemplateColumns={isSmSizeScreen ? "1fr" : "1fr 1fr"}
            columnGap={3}>
            <CommonTextField
              className={classes.subField}
              inputProps={{
                "data-testid": "business-name-input",
                disabled: !isEditable,
                readOnly: !isEditable,
              }}
              fullWidth
              label="Business Name"
              placeholder="Business Name"
              name="businessName"
              onChange={handleChange}
              value={values.businessName}
              error={!!errors.businessName}
              helperText={errors.businessName}
            />
            <Box position="relative">
              <CommonTextField
                className={classes.subField}
                inputProps={{
                  "data-testid": "contact-email-input",
                  disabled: !isEditable,
                  readOnly: !isEditable,
                }}
                fullWidth
                label="Email"
                placeholder="Email"
                name="contactEmail"
                onChange={handleChange}
                value={values.contactEmail}
                error={!!errors.contactEmail}
                helperText={errors.contactEmail}
              />
              <Box position={isSmSizeScreen ? "static" : "absolute"} aria-live="polite">
                {values.contactEmail !== initialValues.contactEmail && submitCount === 0 ? (
                  <FormHelperText>
                    We will send you a verification email to your inbox. You will need to confirm
                    your address to complete its update.
                  </FormHelperText>
                ) : emailStatus && !emailStatus?.verified ? (
                  <FormHelperText>
                    We’ve sent you a verification email to <b>{emailStatus.email}</b> inbox, please
                    confirm your address to complete its update.{" "}
                    <Link
                      sx={{ cursor: "pointer" }}
                      underline="always"
                      role="button"
                      tabIndex={0}
                      onClick={handleResendVerificationEmail}>
                      Resend email
                    </Link>
                  </FormHelperText>
                ) : null}
              </Box>
            </Box>
            <PhoneField
              className={classes.subField}
              inputProps={{ "data-testid": "phone-number-input", disabled: !isEditable }}
              readOnly={!isEditable}
              fullWidth
              label="Phone"
              placeholder="Phone"
              name="contactPhone"
              onValueChange={(value) => setFieldValue("contactPhone", value)}
              value={values.contactPhone}
              helperText={errors.contactPhone}
              error={!!errors.contactPhone}
              countryCode={CONFIG.INPUTS.DEFAULT_PHONE_COUNTRY_CODE}
            />
          </Box>
        </CardContent>
        <Divider />
        <CardActions>
          {isEditable ? (
            <Box className={classes.bottomButton}>
              <Button
                color="primary"
                variant="contained"
                data-testid="save-contact-details-button"
                fullWidth
                type="submit"
                disabled={!isValid || isSubmitting || !dirty}
                sx={{ mb: 1 }}>
                Save
              </Button>
              <Button
                color="primary"
                variant="outlined"
                data-testid="cancel-contact-details-button"
                fullWidth
                onClick={() => {
                  setIsEditable(false);
                  resetForm();
                }}>
                Cancel
              </Button>
            </Box>
          ) : (
            <Button
              color="primary"
              variant="contained"
              data-testid="edit-contact-details-button"
              fullWidth
              onClick={() => setIsEditable(true)}>
              Edit
            </Button>
          )}
        </CardActions>
      </form>
    </Card>
  );
};

export default ContactDetails;
