import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { CommonTextField, NoteBox } from "@APP/components";
import { OrgType, Provider, ErpId } from "@APP/constants";
import { useOrganisationDetails } from "@APP/hooks";
import { getUser } from "@APP/redux";

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: "auto",
    height: 170,
    width: 170,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
    fontSize: 24,
    textAlign: "center",

    [theme.breakpoints.down("md")]: {
      height: 120,
      width: 120,
      fontSize: 14,
    },
  },
}));

const OrganisationDetails = () => {
  const classes = useStyles();
  const organisationDetailsHook = useOrganisationDetails();
  const { t } = useTranslation();
  const user = useSelector(getUser);
  const theme = useTheme();
  const isSmSizeScreen = useMediaQuery(theme.breakpoints.down("md"));

  const isLimitedCompany =
    user?.org?.orgType === OrgType.LIMITED_COMPANY ||
    user?.org?.orgType === OrgType.LIMITED_LIABILITY_PARTNERSHIP;

  return (
    <Card elevation={4}>
      <CardHeader
        title={t("Settings.OrganisationDetails.CardTitle")}
        subheader={t("Settings.OrganisationDetails.CardSubheader")}
        data-testid="organisation-card-header"
      />
      <Divider />
      <CardContent>
        <Box mb={2}>
          {user?.erp !== ErpId.INTERNAL ? (
            <Box mb={2}>
              <NoteBox>
                {isLimitedCompany
                  ? t("Settings.RegisteredCompanyNote")
                  : t("Settings.NonRegisteredCompanyNote")}
              </NoteBox>
            </Box>
          ) : null}
          <Grid
            alignItems="center"
            container
            direction={isSmSizeScreen ? "column" : "row"}
            spacing={3}>
            <Grid item xs={12} md={4}>
              <Avatar className={classes.avatar}>{user?.org?.name.toUpperCase() || ""}</Avatar>
            </Grid>
            <Grid item xs={12} md={8}>
              <Box display="flex" flexDirection="column" gap={3}>
                <Typography variant="h6" data-testid="organisation-details-title">
                  {t("Settings.OrganisationDetails.OrgDetailsFieldTitle")}
                </Typography>
                <CommonTextField
                  inputProps={{
                    readOnly: true,
                    disabled: true,
                    "data-testid": "company-name-input",
                  }}
                  fullWidth
                  label="Company name"
                  placeholder="Company name"
                  name="companyName"
                  value={user?.org?.name}
                />
                {Provider.isMoneyhub && isLimitedCompany && (
                  <CommonTextField
                    inputProps={{
                      readOnly: true,
                      disabled: true,
                      "data-testid": "company-number-input",
                    }}
                    fullWidth
                    label="Company Number"
                    placeholder="Company Number"
                    name="companyNumber"
                    value={user?.org?.taxInfo.utrNumber}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box mb={2} mt={2}>
          <Typography variant="h6" data-testid="company-address-title">
            Company address
          </Typography>
          <Box
            display="grid"
            gridTemplateColumns={isSmSizeScreen ? "1fr" : "1fr 1fr"}
            columnGap={3}
            rowGap={2}
            marginTop={2}>
            <CommonTextField
              inputProps={{
                readOnly: true,
                disabled: true,
                "data-testid": "company-address-input",
              }}
              fullWidth
              name="companyAddress"
              label="Address"
              placeholder="Address"
              value={user?.org?.companyInfo.address?.lines[0]}
            />
            <CommonTextField
              inputProps={{
                readOnly: true,
                disabled: true,
                "data-testid": "company-address-line2-input",
              }}
              fullWidth
              label="Address Line 2"
              placeholder="Address Line 2"
              name="companyAddresLine2"
              value={user?.org?.companyInfo.address?.lines[1]}
            />
            <CommonTextField
              inputProps={{
                readOnly: true,
                disabled: true,
                "data-testid": "company-address-city-input",
              }}
              fullWidth
              label="City"
              placeholder="City"
              name="companyAddressCity"
              value={user?.org?.companyInfo.address?.city}
            />
            {Provider.isMoneyhub ? (
              <CommonTextField
                inputProps={{
                  readOnly: true,
                  disabled: true,
                  "data-testid": "company-address-country-input",
                }}
                fullWidth
                label="Country"
                placeholder="Country"
                name="companyAddressCountry"
                value={user?.org?.companyInfo.address?.country}
              />
            ) : (
              <CommonTextField
                inputProps={{
                  readOnly: true,
                  disabled: true,
                  "data-testid": "company-address-state-input",
                }}
                fullWidth
                label="State"
                placeholder="State"
                name="companyAddressState"
                value={user?.org?.companyInfo.address?.state}
              />
            )}
            <CommonTextField
              inputProps={{
                readOnly: true,
                disabled: true,
                "data-testid": "company-address-post-code-input",
              }}
              fullWidth
              label={t("Settings.OrganisationDetails.PostcodeFieldTitle")}
              placeholder={t("Settings.OrganisationDetails.PostcodeFieldTitle")}
              name="companyAddressPostCode"
              value={user?.org?.companyInfo.address?.postcode}
            />
          </Box>
        </Box>
      </CardContent>
      {user?.erp === ErpId.INTERNAL ? null : (
        <>
          <Divider />
          <CardActions>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              data-testid="get-organisation-details-button"
              onClick={() => organisationDetailsHook.getOrganisationDetails()}>
              {t("Settings.OrganisationDetails.ContinueButton")}
            </Button>
          </CardActions>
        </>
      )}
    </Card>
  );
};

export default OrganisationDetails;
