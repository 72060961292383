import { useTranslation } from "react-i18next";
import {
  DetailedHTMLProps,
  IframeHTMLAttributes,
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { Box, Button, CircularProgress, Typography } from "@mui/material";

import { API } from "@APP/services";
import CONFIG from "@APP/config";

const useStyles = makeStyles(() => ({
  iframe: {
    width: "100%",
    height: "100%",
    border: "none",
  },
  iframeHidden: {
    display: "none",
  },
}));

enum MX_WIDGET_EVENTS {
  Loaded = "mx/connect/loaded",
  EnterCredentials = "mx/connect/enterCredentials",
  InstitutionSearch = "mx/connect/institutionSearch",
  SelectedInstitution = "mx/connect/selectedInstitution",
  MemberConnected = "mx/connect/memberConnected",
  ConnectedPrimaryAction = "mx/connect/connected/primaryAction",
  MemberDeleted = "mx/connect/memberDeleted",
  CreateMemberError = "mx/connect/createMemberError",
  MemberStatusUpdate = "mx/connect/memberStatusUpdate",
  OAuthError = "mx/connect/oauthError",
  OAuthRequested = "mx/connect/oauthRequested",
  StepChange = "mx/connect/stepChange",
  SubmitMFA = "mx/connect/submitMFA",
  UpdateCredentials = "mx/connect/updateCredentials",
}

export enum MX_OAUTH_ERROR_REASONS {
  CANCELLED = "CANCELLED",
  DENIED = "DENIED",
  IMPEDED = "IMPEDED",
  PROVIDER_ERROR = "PROVIDER_ERROR",
  SERVER_ERROR = "SERVER_ERROR",
  SESSION_ERROR = "SESSION_ERROR",
}

interface Props
  extends DetailedHTMLProps<IframeHTMLAttributes<HTMLIFrameElement>, HTMLIFrameElement> {
  onError?: () => void;
  onOAuthError?: (errorReason: MX_OAUTH_ERROR_REASONS) => void;
  onLinkSuccess?: (bankName: string) => void;
}

const MxWidgetIframe = ({ onError, onOAuthError, onLinkSuccess, ...props }: Props) => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(true);
  const [mxWidgetUrl, setMxWidgetUrl] = useState<string | undefined>();
  const [isError, setIsError] = useState(false);

  const selectedInstitutionName = useRef("");
  const { t } = useTranslation();

  const handleIframeEvent = useCallback((event: MessageEvent) => {
    switch (event.data.type) {
      case MX_WIDGET_EVENTS.SelectedInstitution:
        selectedInstitutionName.current = event.data.metadata.name;
        break;
      case MX_WIDGET_EVENTS.MemberConnected:
        onLinkSuccess?.(selectedInstitutionName.current);
        break;
      case MX_WIDGET_EVENTS.CreateMemberError:
        onError?.();
        break;
      case MX_WIDGET_EVENTS.OAuthError:
        onOAuthError?.(event.data.metadata.error_reason);
        break;
    }
  }, []);

  const fetchMxWidgetUrl = async () => {
    try {
      setIsLoading(true);
      setIsError(false);

      const { url } = await API.getMxWidgetUrl();
      setMxWidgetUrl(url);
    } catch (error) {
      setMxWidgetUrl(undefined);
      setIsError(true);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchMxWidgetUrl();
  }, []);

  useEffect(() => {
    window.addEventListener("message", handleIframeEvent, false);

    return () => window.removeEventListener("message", handleIframeEvent);
  }, [handleIframeEvent]);

  return (
    <>
      {isLoading ? (
        <Box display="flex" width="100%" height="100%" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : null}
      {!isLoading && isError ? (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box mb={1}>
            <Typography align="center">
              {t("Errors.ErrorCodes.1230", { SUPPORT_EMAIL: CONFIG.SUPPORT_EMAIL })}
            </Typography>
          </Box>
          <Button variant="contained" color="primary" onClick={fetchMxWidgetUrl}>
            Try again
          </Button>
        </Box>
      ) : null}
      {mxWidgetUrl ? (
        <Box height={550} width="100%">
          <iframe
            {...props}
            src={mxWidgetUrl}
            onLoad={() => {
              setIsLoading(false);
            }}
            title="MX Widget"
            allowFullScreen
            className={clsx(classes.iframe, { [classes.iframeHidden]: isLoading })}
          />
        </Box>
      ) : null}
    </>
  );
};

export default MxWidgetIframe;
